import React, { useState } from 'react';
import { Row, Col, Card, Button, Table, Badge, Pagination, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

const DashboardOrders = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const indexOfLastOrder = currentPage * itemsPerPage;
    const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
    const currentOrders = props.LatestOrders.slice(indexOfFirstOrder, indexOfLastOrder);

    const totalPages = Math.ceil(props.LatestOrders.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <Col lg={12} sm={12}>
            <div style={{ overflow: 'auto', overflowX: 'scroll', marginTop: '50px', padding: '20px', backgroundColor: 'white' }}>
                <h4>Latest Orders</h4>
                <Form className='my-4'>
                    <Row>
                        <div className='d-flex justify-content-between text-center'>
                            <Col lg={2}>
                                <Form.Group controlId="formGridState">
                                    <Form.Select defaultValue="Choose...">
                                        <option>10</option>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>100</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col lg={2}>
                                <Form.Group className="" controlId="exampleForm.ControlInput1">
                                    <Form.Control type="text" placeholder="Search" />
                                </Form.Group>
                            </Col>
                        </div>

                    </Row>
                </Form>

                <Table style={{borderRadius: '7px', overflow: 'hidden'}} className='bg-white text-center'>
                    <thead>
                        <tr>
                            <th>Order#</th>
                            <th>Tables</th>
                            <th>Dishes</th>
                            <th>Total</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Served By</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentOrders.map((val, ind) => (
                            <tr key={ind}>
                                <td>{val.orders}</td>
                                <td>{val.tables}</td>
                                <td>
                                    <Button variant="danger">Invoice</Button>
                                </td>
                                <td>{val.total}</td>
                                <td>{val.date}</td>
                                <td>
                                    <Badge bg="success">{val.status}</Badge>
                                </td>
                                <td>{val.servedBy}</td>
                                <td>
                                    <Button variant="warning">
                                        <FontAwesomeIcon icon={faPen} />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Pagination className='m-auto'>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </div >
        </Col >
    );
};

export default DashboardOrders;
