import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import waiterStripeService from './waiterStripeService';

const initialState = {
    payment:{},
    isError: false,
    isSuccess: false,
    isSuccessVerify: false,
    isLoading: false,
    message: ""
}
 
  export const stripePayment = createAsyncThunk('waiter/stripePayment', async (data,thunkAPI) => {
    try {
        return await waiterStripeService.customerPayment(data);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
  })


  export const waiterStripeSlice = createSlice({
    name: "waiterStripe",
    initialState,
    reducers: {
      reset: (state) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = false
        state.message = ""
    }
    },
    extraReducers: (builder) => {
        builder.addCase(stripePayment.pending, (state) => {
        state.isLoading = true;
    }).addCase(stripePayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.payment = action.payload;
    }).addCase(stripePayment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
    })
    }
})
export const { reset } = waiterStripeSlice.actions;


export default waiterStripeSlice.reducer