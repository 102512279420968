import React, { useEffect } from "react";
import { Card, Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping, faUser } from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";

import ItemCard from "./ItemCard";

import {
  allOrder,
  getComingOrder,
  updateOrder
} from "../../../redux/features/cook/KitchenOrder/kitchenOrderAction";

import GeneralIndicator from "../../model/GeneralIndicator";

const Kitchen = () => {
  const dispatch = useDispatch();

  const { selectBranches } = useSelector((state) => state.branches);

  const { kitchenOrders, isLoading } = useSelector(
    (state) => state.kitchenOrder
  );
  useEffect(() => {
    dispatch(allOrder(selectBranches[0]));
  }, [selectBranches]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getComingOrder(selectBranches[0]));
    }, 5000);

    return () => clearInterval(interval);
  }, [selectBranches]);

  const handleAddDining = (item) => {
    const data = {
      _id: item?._id,
      status: "dining",
      branchId:selectBranches[0],
    };
    dispatch(updateOrder(data));
  };

  return (
    <>
      {isLoading ? <GeneralIndicator /> : null}
      <Row>
        {kitchenOrders?.order
          ?.slice()
          ?.reverse()
          ?.map((item, index) => (
            <ItemCard item={item} handleAddDining={handleAddDining}></ItemCard>
          ))}
      </Row>
      {kitchenOrders?.order?.length === 0 && <b style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Orders</b>}
    </>
  );
};

export default Kitchen;
