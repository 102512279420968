import React ,{useState}from 'react';
import { Navbar, Container, Card,Button,Form } from 'react-bootstrap';
import PingLogo from "./ping logo.png"
import logo from "./tittle logo.png"
import OtpInput from 'react-otp-input';
import { useHistory } from "react-router-dom";



function ForgotOtp() {
    const history = useHistory();

//navigate to ResetPassword
const navigateToLink = () => {
  history.push("/resetPassword");
};
// set the initial value of the otp state
const [otp, setOtp] = useState("");

    return <>
       <Navbar bg="light" variant="dark">
            <Container>
                <Navbar.Brand href="#">
                    <div className='d-flex'>
                        <img src={PingLogo} alt="logo" className='me-2' />
                        <img src={logo} alt="logo" />
                    </div>
                </Navbar.Brand>
            </Container>
        </Navbar>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh'
        }}
        >
            <Container>
                <Card style={{ width: '500px', padding: '50px', margin: '10px auto' }}>
                    <Card.Body>
                        <Card.Text className='text-center h2 fw-bold'>Forgot Password</Card.Text>
                        <Card.Text className='text-center h6 '>A 6 Digit OTP Code will be sent to email</Card.Text>
                      
                        <Form.Group controlId="name" style={{ marginBottom: '30px', maxWidth: '100vh', }}>
                                    <Form.Label className='mt-4 h5'><b>Enter OTP:</b></Form.Label>
                                    <div >
                                    <OtpInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      inputStyle={{
        width: '40px', // Adjust the width as per your requirements
        height: '40px', // Optional: You can also set the height
        fontSize: '16px', // Optional: You can set the font size
      }}
      renderSeparator={<span style={{ width: '25%'}}></span>}
      renderInput={(props) => <input  {...props} />}
    />
    </div>
                                </Form.Group>
      
      <div className='text-center'>
                                <Button type="submit"  onClick={navigateToLink} variant="warning" className='text-center' style={{ padding: '10px 140px' }}>
                                    Continue
                                </Button>
                            </div> 
                            <div className="text-center">
                     <p>Didn't get code?<button className="btn btn-link text-danger">Resend</button></p>
                </div>
                    </Card.Body>

                </Card>
            </Container>
        </div>
    </>
        ;
}

export default ForgotOtp;