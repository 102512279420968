import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import variantService from "./VariantService";

const initialState = {
  variant: [],
  totalPages: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};
// get Variant
export const allVariant = createAsyncThunk(
  "admin/allVariant",
  async ({branchId,pageNo}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await variantService.getAllVariant(user.accessToken, branchId,pageNo);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//add variant
export const addVariant = createAsyncThunk(
    "admin/addVariant",
    async ({ branchId, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await variantService.addVariant(user.accessToken, branchId, data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  //update variant
export const updateVariant = createAsyncThunk(
    "admin/updateVariant",
    async ({ branchId, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await variantService.updateVariant(user.accessToken,branchId,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  //update variant Status
  export const updateVariantStatus = createAsyncThunk(
    "admin/updateVariantStatus",
    async ({ branchId, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await variantService.updateVariantStatus(user.accessToken,branchId,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const allActiveVariant = createAsyncThunk(
    "admin/allActiveVariant",
    async (branchId, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await variantService.getActiveVariant(
          user.accessToken,
          branchId
        );
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const variantSlice = createSlice({
  name: "variant",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allVariant.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allVariant.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.variant = action.payload?.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(allVariant.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.variant = [];
      })
      .addCase(addVariant.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addVariant.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.variant = action.payload.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(addVariant.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateVariant.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVariant.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.variant = action.payload;
      })
      .addCase(updateVariant.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateVariantStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVariantStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.variant = action.payload;
      })
      .addCase(updateVariantStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(allActiveVariant.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allActiveVariant.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.variant = action.payload;
      })
      .addCase(allActiveVariant.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});
export const { reset } = variantSlice.actions;

export default variantSlice.reducer;
