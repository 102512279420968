import React from 'react'
import { Form, Modal, Button, Col, Row } from 'react-bootstrap';

function AddExpense(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Expense
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col lg={6} sm={6}>
                        <Form.Group controlId="formGridState">
                                <Form.Label className='fw-bold'>Category:</Form.Label>
                                    <Form.Select defaultValue="Choose...">
                                        <option>Salary</option>
                                        <option>Management</option>
                                        <option>Manager</option>
                                        <option>Staff Salary</option>
                                        <option>Rent</option>
                                        <option>Utility Bill</option>
                                        <option>Grocery</option>
                                        <option>Furniture</option>
                                        <option>Taxes</option>
                                        <option>Maintenance</option>
                                        <option>Restaurant Website</option>
                                    </Form.Select>
                                </Form.Group>
                        </Col>
                        <Col lg={6} sm={6}>
                            <Form.Group controlId="price" style={{ marginBottom: '30px' }}>
                                <Form.Label className='fw-bold'> Amount</Form.Label>
                                <Form.Control
                                    type="Price"
                                    name="Price"
                                    placeholder='100'
                                    // style={{ width: '100%', padding: '5px' }}
                                />
                            </Form.Group>
                        </Col>
                        </Row>
                        <Row>
                        <Col lg={12} sm={6}>
                            <Form.Group controlId="category" style={{ marginBottom: '30px' }}>
                                <Form.Label className='fw-bold'>Expense Details</Form.Label>
                                <Form.Control
                                    type="area"
                                    name="browser"
                                    placeholder='Tables, Chairs, Lights etc.'
                                    // style={{ width: '100%', padding: '5px' }}
                                />
                            </Form.Group>
                        </Col>
                        </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={props.onHide}>Add Expense </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddExpense;