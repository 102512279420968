import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Col, Row, Table, Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";

import {
  addOrder,
  reset,
  clearOrder,
} from "../../redux/features/customer/Order/orderSlice.js";
import {
  payStack,
  verifyPayStack,
  resetPayStack,
} from "../../redux/features/customer/Otp/otpSlice.js";

import ConfirmationModal from "./ConfirmationModal";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import GeneralIndicator from "../model/GeneralIndicator";

import { updateOrder } from "../../redux/features/customer/Order/orderSlice.js";

// Stripe
import { loadStripe } from "@stripe/stripe-js";

import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import StripeCard from "./StripeCard.js";

function Order(props) {
  const params = useParams();
  const branchId = params?.id !== undefined && params?.id;
  const dispatch = useDispatch();
  const { order, isError, isSuccessOrder, message } = useSelector(
    (state) => state.customerOrder
  );

  const { transactionVerifyPayStack, isLoading } = useSelector(
    (state) => state.otp
  );

  const [filterTable, setFilterTable] = useState([]);

  const [selectedButton, setSelectedButton] = useState("Eat-in");
  const [payButton, setpayButton] = useState(null);
  const [takeAwayButton, setTakeAwayButton] = useState(null);
  const [userName, setUserName] = useState("");
  const [table, setTable] = useState(null);
  const [guest, setGuest] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reference = searchParams.get("reference");

  const [stripeModal, setStripModal] = useState(false);
  const [customerId, setCustomerId] = useState("");

  const { paymentMethod } = useSelector((state) => state.paymentMethod);

  useEffect(() => {
    if (reference !== null) {
      dispatch(verifyPayStack(reference));
      if (order.order !== undefined) {
        if (transactionVerifyPayStack?.status) {
          if (transactionVerifyPayStack?.data?.status == "success") {
            let data = {
              status: "processing",
              orderId: order?.order?._id,
              userId: order?.order?.customerId,
              branchId: props.branchId,
              totalAmount: order?.totalPayment,
              cashReceived: order?.totalPayment,
              cashChange: 0,
            };
            dispatch(addOrder(data));
            const newSearchParams = new URLSearchParams(location.search);
            newSearchParams.delete("reference");
            history.replace({ search: newSearchParams.toString() });
          } else if (transactionVerifyPayStack?.data?.status != "success") {
            const newSearchParams = new URLSearchParams(location.search);
            newSearchParams.delete("reference");
            history.replace({ search: newSearchParams.toString() });
            toast.error("Your Transaction is Failed");
          }
        }
      }
    }
  }, [reference, order]);

  // useEffect(() => {
  //   if(order.order!== undefined){

  // }
  // }, [transactionVerifyPayStack]);

  useEffect(() => {
    const processingOrders = order?.restaurantInformation?.processingOrders;
    const ttable = order?.restaurantInformation?.ttable;

    const updatedTtable = ttable?.filter((table) => {
      return !processingOrders?.some((order) => order.tableId === table._id);
    });

    setFilterTable(updatedTtable);
  }, [order]);

  const isSubmitDisabled = !userName || !guest || !table || !inputEmail;
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  let updateData = {
    orderId: order?.order?._id,
    userId: order?.order?.customerId,
    branchId: props.branchId,
    totalAmount: order?.totalPayment,
    cashReceived: order?.totalPayment,
    cashChange: 0,
    username: userName,
    tableId: table,
    guest: guest,
    email: inputEmail,
  };

  const handlePayButtonClick = async (buttonName) => {
    setpayButton(buttonName);
    if (buttonName === "payment") {
      setTimeout(async () => {
        let data = {
          email: inputEmail,
          amount: order?.totalPayment * 100,
          // subaccount: "ACCT_ra2fiuew6134giq",
          subaccount: paymentMethod?.paymentaccount?.account?.subaccountCode,
          callback_url: `${window.location.origin}/restaurant/${order?.restaurantInformation?.branchName}/${props.branchId}`,
        };
        if (paymentMethod?.paymentaccount?.type === "payStack") {
          dispatch(payStack(data));
        } else if (paymentMethod?.paymentaccount?.type === "stripe") {
          setStripModal(true);
        }
      }, 2000);
      dispatch(updateOrder(updateData));
    }
  };

  const handleTakeAwayButtonClick = (data) => {
    setTakeAwayButton(data);
  };

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccessOrder) {
      dispatch(resetPayStack());
      props.onHide();
      // localStorage.clear();
      localStorage.removeItem("orderData");
      window.location.reload();
      toast.success("Successfully Add Order");
      dispatch(reset());
    }
    dispatch(reset());
  }, [isError, isSuccessOrder, message, dispatch]);

  const handleConfirmOrder = () => {
    let data = {
      status: payButton == "Cash" ? "processing" : "processing",
      orderId: order?.order?._id,
      userId: order?.order?.customerId,
      branchId: props.branchId,
      totalAmount: order?.totalPayment,
      cashReceived: payButton == "Cash" ? order?.totalPayment : 0,
      cashChange: payButton == "Cash" ? 0 : order?.totalPayment,
      username: userName,
      tableId: table,
      guest: guest,
      email: inputEmail,
    };
    dispatch(addOrder(data));
    setShowModal(false); // Close the modal after confirming.
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleShowModal();
  };
  return (
    <>
      {isLoading && <GeneralIndicator />}
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form method="post" onSubmit={onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Order Now
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant={selectedButton === "Eat-in" ? "danger" : "warning"}
                style={{ width: "100px", marginRight: "10px" }}
                className={`rounded-pill ${
                  selectedButton === "Eat-in" ? "text-white" : ""
                }`}
                onClick={() => handleButtonClick("Eat-in")}
              >
                Eat-in
              </Button>
              {/* <Button
                            variant={selectedButton === 'Takeaway' ? 'danger' : 'warning'}
                            style={{ width: '100px' }}
                            className={`rounded-pill ${selectedButton === 'Takeaway' ? 'text-white' : ''}`}
                            onClick={() => handleButtonClick('Takeaway')}

                        >
                            Takeaway
                        </Button> */}
            </div>

            <Card.Text className="my-3">
              <Form.Label className="fw-bold">Name*:</Form.Label>
              <Form.Control
                type="text"
                name="text"
                placeholder="First and Last Name *"
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </Card.Text>

            <Card.Text className="my-3">
              <Form.Label className="fw-bold">Email*:</Form.Label>
              <Form.Control
                type="text"
                name="text"
                placeholder="Email *"
                onChange={(e) => setInputEmail(e.target.value)}
              />
            </Card.Text>
            <Card.Text className="my-3">
              <Row>
                <Col lg={6}>
                  <Form.Label className="fw-bold">Table No*:</Form.Label>
                  <Form.Select
                    aria-label="select"
                    required
                    onChange={(e) => setTable(e.target.value)}
                  >
                    <option selected disabled>
                      Select Table
                    </option>
                    {filterTable?.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.orderTable}
                      </option>
                    ))}
                    ;
                  </Form.Select>
                </Col>
                <Col lg={6}>
                  <Form.Label className="fw-bold">Guest*:</Form.Label>
                  <Form.Control
                    type="number"
                    name="text"
                    placeholder="No of guest *"
                    onChange={(e) => setGuest(e.target.value)}
                  />
                </Col>
              </Row>
            </Card.Text>
            {/* <Card.Text>
                        <Form.Check
                            type={'checkbox'}
                            label={`No Table`} />
                    </Card.Text> */}
            {/* <Card.Text className='my-3'>
                        <Row>
                            <Col lg={6}>
                            <Form.Label className='fw-bold'>Phone Number:</Form.Label>
                        <Form.Control
                            type="text"
                            name="text"
                            placeholder='Phone No *'
                            required/>
                            </Col>
                            <Col lg={6}>        
                        <Form.Label className='fw-bold'>Email(Optional):</Form.Label>
                        <Form.Control
                            type="text"
                            name="text"
                            placeholder='Email'
                            required/>
                            </Col>
                        </Row>
                    </Card.Text> */}
            {selectedButton === "Takeaway" ? (
              <div>
                <div className="d-flex justify-content-center align-items-center ">
                  {/* <Button
                                    variant={ 'warning'}
                                    style={{ width: '100px', marginRight: '10px' }}
                                    className={`rounded-pill ${takeAwayButton === 'Card' ? 'text-white' : ''}`}
                                    onClick={() => handleTakeAwayButtonClick('Card')}
                                >
                                    Card
                                </Button> */}
                  <Button
                    variant={"warning"}
                    style={{ width: "100px" }}
                    className={`rounded-pill ${
                      takeAwayButton === "Cash" ? "text-white" : ""
                    }`}
                    onClick={() => handleTakeAwayButtonClick("Cash")}
                    type="submit"
                  >
                    Cash
                  </Button>
                </div>
              </div>
            ) : (
              ""
            )}
            {stripeModal === true ? (
              <StripeCard
                branchId={props.branchId}
                name={userName}
                inputEmail={inputEmail}
                updateData={updateData}
                totalPayment={order?.totalPayment * 100}
                username={userName}
                table={table}
                guest={guest}
                email={inputEmail}
              />
            ) : (
              ""
            )}
            {(selectedButton === "Eat-in") & (stripeModal === false) ? (
              <div>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    variant={"warning"}
                    style={{
                      // width: "100px",
                      marginRight: "10px",
                    }}
                    className={`rounded-pill ${
                      payButton === "payment" ? "text-white" : ""
                    }`}
                    onClick={() => handlePayButtonClick("payment")}
                    disabled={isSubmitDisabled}
                  >
                    Payment (Test)
                  </Button>
                  <Button
                    variant={payButton === "payLater" ? "danger" : "warning"}
                    style={{ width: "100px", marginRight: "10px" }}
                    className={`rounded-pill ${
                      payButton === "payLater" ? "text-white" : ""
                    }`}
                    onClick={() => handlePayButtonClick("payLater")}
                    disabled={isSubmitDisabled}
                  >
                    Pay Later
                  </Button>
                  {/* <Button
                                    variant={'warning'}
                                    style={{ width: '100px' }}
                                    className={`rounded-pill ${payButton === 'Cash' ? 'text-white' : ''}`}
                                    onClick={() => handlePayButtonClick('Cash')}
                                    type="submit"

                                >
                                   Cash
                                </Button> */}
                </div>
              </div>
            ) : (
              ""
            )}
            {payButton === "payLater" &&
            selectedButton === "Eat-in" &&
            userName &&
            guest &&
            table &&
            inputEmail ? (
              <Button
                type="submit"
                variant="warning"
                style={{ width: "100%" }}
                className="my-3"
              >
                Place Order
              </Button>
            ) : (
              ""
            )}
          </Modal.Body>
        </Form>
      </Modal>
      <ConfirmationModal
        show={showModal}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmOrder}
      />
    </>
  );
}

export default Order;
