import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import ping from "../ping logo.png";

import { Container, Form, Nav, Navbar, Stack } from "react-bootstrap";

import Addcard from "./Icons/profile-cart1.png";
import ProfileLogo from "./Images/Colleagues/Colleagues-6.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  allBranches,
  setSelectRestaurantLogo,
  setSelectedBranch,
} from "../redux/features/admin/Branches/branchesAction";
import { AuthContext } from "../AuthContext";
import GeneralIndicator from "./model/GeneralIndicator";

function NavScrollExample() {
  const { currentUser } = useContext(AuthContext);

  const dispatch = useDispatch();
  const {
    branches,
    isLoading,
    isError,
    isSuccess,
    message,
    totalPage,
    selectBranches,
    selectRestaurantLogo,
  } = useSelector((state) => state.branches);

  useEffect(() => {
    if (
      selectBranches.length === 0 &&
      currentUser?.userType?.title != "superAdmin"
    ) {
      if (branches.length > 0) {
        dispatch(setSelectRestaurantLogo(branches[0]?.logo));
        dispatch(setSelectedBranch(branches[0]?._id));
      }
    }
  }, [branches, selectBranches]);

  useEffect(() => {
    if (currentUser?.userType?.title != "superAdmin") {
      dispatch(
        setSelectRestaurantLogo(
          branches.find((i) => i._id === selectBranches[0])?.logo
        )
      );
    }
  }, [selectBranches]);

  return (
    <Navbar bg="white" expand="lg">
      {branches.length <= 0 && currentUser?.userType?.title != "superAdmin" ? (
        <GeneralIndicator />
      ) : null}
      <Container fluid>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px", width: "100%" }}
            navbarScroll
          >
            <Stack
              direction="horizontal"
              gap={4}
              style={{ maxHeight: "100px", width: "100%" }}
            >
              <div className="bg-light border me-auto rounded-3">
                {/* <Form.Control
                  type="search"
                  placeholder="Search for Order, Items and Category"
                  className="me-2 wh-50"
                  aria-label="Search"
                /> */}
              </div>
              {currentUser?.userType?.title != "superAdmin" ? (
                <>
                  <p style={{ margin: 0 }}>Branches:</p>
                  <Form.Group>
                    <Form.Select
                      aria-label="select"
                      required
                      value={selectBranches[0]}
                      onChange={(e) =>
                        dispatch(setSelectedBranch(e.target.value))
                      }
                    >
                      {branches?.map((item) => (
                        <option key={item._id} value={item?._id}>
                          {item?.address?.fullAddress}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>{" "}
                </>
              ) : (
                ""
              )}
              {/* <Link> */}
              <img
                className="rounded-5 me-4"
                style={{ width: "45px" }}
                src={
                  selectRestaurantLogo
                    ? `${process.env.REACT_APP_BASE_URL_FILES}/${selectRestaurantLogo}`
                    : ping
                }
                alt="Logo"
              />
              {/* </Link> */}
            </Stack>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;
