import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const addVariant = async (token, branchId, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/admin/variant/add?branchId=${branchId}&page=${data?.pageNo}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Added Variant");
    return response.data;
  }
};

const getAllVariant = async (token, branchId,pageNo) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/variant?branchId=${branchId}&page=${pageNo}`,
      config
    );
    if (response.data.body) {
      return response.data;
    }
  };

  const updateVariant = async (token,branchId,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/admin/variant/${data._id}?branchId=${branchId}&page=${data?.pageNo}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Successfully Updated Variant");
      return response.data.body;
    }
  };

  const updateVariantStatus = async (token,branchId,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/admin/variant/status/${data._id}?branchId=${branchId}&page=${data?.pageNo}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Successfully Updated Variant Status");
      return response.data.body;
    }
  };
  const getActiveVariant = async (token,branchId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/variant/active?branchId=${branchId}`,
      config
    );
    if (response.data.body) {
      return response.data.body;
    }
  };
const variantService = {
    addVariant,
    getAllVariant,
    updateVariant,
    updateVariantStatus,
    getActiveVariant
};

export default variantService;
