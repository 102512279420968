import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Modal,
  Navbar,
  Card,
  Button,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import CustomerInformation from "./CustomerInformation";
import { singleOrder } from "../../../redux/features/waiter/waiterOrderAction";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import StripeCard from "../../Customer/StripeCard";
import { updateOrder } from "../../../redux/features/customer/Order/orderSlice";
import { payStack } from "../../../redux/features/customer/Otp/otpSlice";

function StripePayment() {
  const dispatch = useDispatch();
  const [payButton, setPayButton] = useState(null);

  const { singleOrderData } = useSelector((state) => state.waiterOrder);
  const { paymentMethod } = useSelector((state) => state.waiterPaymentMethod);



  const params = useParams();
  const orderId = params?.id != undefined && params?.id;

  useEffect(() => {
    dispatch(singleOrder(orderId));
  }, []);

  const [showModal, setShowModal] = useState(false);

  let updateData = {
    orderId: singleOrderData?._id,
    userId: singleOrderData?.customerId,
    cashReceived: singleOrderData?.totalAmount,
  };

  const handlePayButtonClick = async (buttonName) => {
    setPayButton(buttonName);
    if (buttonName === "payment") {
      setTimeout(async () => {
        let data = {
          email: singleOrderData.customer.inputEmail,
          amount: singleOrderData?.totalPayment * 100,
          // subaccount: "ACCT_ra2fiuew6134giq",
          subaccount: paymentMethod?.paymentaccount?.account?.subaccountCode,
          callback_url: `${window.location.origin}/payment/${singleOrderData?._id}`,
        };
        if (paymentMethod?.paymentaccount?.type === "payStack") {
          dispatch(payStack(data));
        } else if (paymentMethod?.paymentaccount?.type === "stripe") {
          setShowModal(true);
        }
      }, 2000);
      dispatch(updateOrder(updateData));
      window.location.reload();
    }
  };

  return (
    <>
      <Navbar bg="light" variant="dark">
        <Container className="d-flex justify-content-center text-center">
          <div>
            <h2>{singleOrderData?.restaurant?.title}</h2>
          </div>
        </Container>
      </Navbar>
      <div
        style={{
          // display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          height: "85vh",
        }}
      >
        <Container fluid style={{ backgroundColor: "#ffffff" }}>
          <div className="d-flex justify-content-center text-center mt-5">
            <Col lg={4} sm={12}>
              <Card className="my-3 p-2">
                <Card.Body>
                  <div className="d-flex justify-content-between text-center fw-bolder h5">
                    <Card.Text>
                      <FontAwesomeIcon icon={faBagShopping} /> Table #.
                      {singleOrderData?.ttable?.orderTable}
                    </Card.Text>
                    {/* <Card.Text>Name:</Card.Text> */}
                  </div>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Order Item</Accordion.Header>
                      <Accordion.Body>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Item Name</th>
                                <th>Quantity</th>
                                <th>Item Price</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {singleOrderData?.orderitem?.map(
                                (item, index) => (
                                  <tr>
                                    <td>{item?.menucategoeies?.title}</td>
                                    <td>{item?.quantity}</td>
                                    <td>{item?.itemPrice}</td>
                                    <td>{item?.quantity * item?.itemPrice}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                        <Card>
                          <Row>
                            <Col lg={6}>
                              <b>Total Amount :</b>
                            </Col>
                            <Col lg={6} className="text-danger text-right">
                              <b>{singleOrderData?.totalAmount}</b>
                            </Col>
                          </Row>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <hr />
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12 ">
                        <Button
                          variant="warning"
                          className="me-3"
                          onClick={() => handlePayButtonClick("payment")}
                          disabled={singleOrderData?.cashReceived > 0}
                        >
                          {singleOrderData?.cashReceived > 0
                            ? "Paid"
                            : "Pay Now"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Container>
      </div>
      {/* <CustomerInformation
        onHide={() => setShowModal(false)}
        show={showModal}
      ></CustomerInformation> */}

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold ">Customer Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StripeCard
            branchId={singleOrderData?.branchId}
            name={singleOrderData?.customer?.userName}
            inputEmail={singleOrderData?.customer?.email}
            totalPayment={singleOrderData?.totalAmount * 100}
            username={singleOrderData?.customer?.userName}
            table={singleOrderData?.tableId}
            guest={singleOrderData?.guest}
            email={singleOrderData?.customer?.email}
            order={singleOrderData}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StripePayment;
