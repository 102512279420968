import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";

const Invoice = React.forwardRef((props, ref) => {
  return (
    <>
      <div ref={ref}>
        <Container>
          <Col lg={5}>
            <Col lg={2}>
              {/* <div className="mx-5 mt-5">
                <p className="mb-0">
                  <b>
                    {
                      props.orderInformation?.restaurantInformation?.restaurants
                        ?.title
                    }
                  </b>
                </p>
                <p className="mb-0">
                  {
                    props.orderInformation?.restaurantInformation?.addresses
                      ?.fullAddress
                  }
                </p>
                <p className="mb-0">
                  Tel: {props.orderInformation?.restaurantInformation?.mobile}
                </p>
              </div> */}
              <p>
                <b>-----------INVOICE------------</b>
              </p>
              <h6>
                {/* Invoice# {Math.floor(Math.random() * 1000 + 1)} T# Parcel 1 */}
                {props?.orderInformation?._id}
              </h6>
              <h6>Date: {props.currentDateTime}</h6>
            </Col>

            <Col lg={3}>
              <table className="table" style={{ width: "30px" }}>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Q</th>
                    <th>Price</th>
                    <th>Total </th>
                  </tr>
                </thead>
                <tbody>
                  {props.orderInformation?.orderItemList?.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td className="fw-bold">
                          {item?.menuCategories?.title}
                        </td>
                        <td>{item?.quantity}</td>
                        <td>{item?.itemPrice}</td>
                        <td>{item?.total}</td>
                      </tr>
                      <tr>
                        <td colSpan="4">
                          {item?.variantOption?.length === 0 ? (
                            ""
                          ) : (
                            <>{item?.menuCategories?.title}-Variant Option</>
                          )}
                        </td>
                      </tr>
                      {item?.variantOption?.map((variant, variantIndex) => (
                        <tr key={variantIndex}>
                          <td>
                            {variant?.title?.charAt(0)?.toUpperCase() +
                              variant?.title?.slice(1)}
                          </td>
                          <td>{variant?.quantity}</td>
                          <td></td>
                          <td></td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                  {/* <hr style={{ borderStyle: 'dashed', width: '300%', margin: '10px 0px', color: 'black' }} /> */}
                  {/* <tr>
                    <td>
                      <b>Discount Type:</b>{" "}
                    </td>
                    <td> </td>
                    <td></td>
                    <td>
                      {props.discountValue == 0
                        ? "flat/percentage"
                        : props.discountType}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Discount:</b>{" "}
                    </td>
                    <td> </td>
                    <td></td>
                    <td>{props.discountValue}</td>
                  </tr> */}
                  <tr>
                    <td>
                      <b>Sub:</b>{" "}
                    </td>
                    <td> </td>
                    <td></td>
                    <td>{props.orderInformation?.totalPayment}</td>
                  </tr>
                  {/* <tr>
                    <td>
                      <b>Tax:</b>{" "}
                    </td>
                    <td> 0%</td>
                    <td></td>
                    <td>Rs0</td>
                  </tr> */}
                  <tr>
                    <td>
                      <b>Total</b>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <b>{props.totalAmount}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Cash:</td>
                    <td>
                      <b>{props.cashReceived}</b>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>Balance:</td>
                    <td>
                      <b>{props.cashChange}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <p className="mb-0">PLEASURE SERVING YOU !!!</p>
            <div className="mt-2">
              <p className="mb-0">Software By: PingMyFood</p>
              <p className="mb-0">+92-307-7898122</p>
            </div>
          </Col>
        </Container>
      </div>
    </>
  );
});

export default Invoice;
