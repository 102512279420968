import axios from 'axios';
import { toast } from "react-toastify";
const BASE_URL =process.env.REACT_APP_BASE_URL;

const addBank = async (token,data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
  const response = await axios.post(`${BASE_URL}/admin/bank/add?branchId=${data.branchId}`,data,config);
  if (response.data.body) {
    toast.success("Successfully Added PayStack");
      return response.data;
  }
}

const updateBank = async (token, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/admin/payStack/update/${data.id}?branchId=${data.branchId}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Successfully Update PayStack");
      return response.data;
    }
  };

const getBank = async (token,branchId) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
  const response = await axios.get(`${BASE_URL}/admin/bank?branchId=${branchId}`,config);
  if (response.data.body) {
      return response.data;
  }
}

const createStripe = async (token,data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
  const response = await axios.post(`${BASE_URL}/admin/stripe/add?branchId=${data.branchId}`,data,config);
  if (response.data.body) {
    toast.success("Successfully Added Stripe");
      return response.data;
  }
}

const updateStripe = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/admin/stripe/update/${data.id}?branchId=${data.branchId}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Update Stripe");
    return response.data;
  }
};

const verifyStripe = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/admin/stripe/verify`,
    data,
    config
  );
  if (response.data.body) {
    return response.data;
  }
};
const getStripeAccountInformation = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/stripe?accountId=${data.accountId}`,
    config
  );
  if (response.data.body) {
    return response.data;
  }
};


const bankService = {
    addBank,
    getBank,
    updateBank,
    createStripe,
    updateStripe,
    verifyStripe,
    getStripeAccountInformation
}


export default bankService;