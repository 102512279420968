import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Colleague_3 from "../../Images/Colleagues/Colleagues-3.jpg";

const Profile = () => {
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    number: '',
    bio: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfile((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return ( <div style={{margin: '60px 20px 200px 20px', padding:'0px '}}>
    <Form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '70px 50px', backgroundColor: '#FFFFFE', padding: '5px 20px 80px 20px' }}><div className='text-center'>
            <img src={Colleague_3} alt="Colleague_1" className='rounded-circle mt-3'/>
          </div>
      <Form.Group controlId="name" style={{ marginBottom: '30px', marginTop:'30px' }}>
        <Form.Label>Full Name:</Form.Label>
        <Form.Control
          type="text"
          name="name"
          placeholder='Johana Doe'
          value={profile.name}
          onChange={handleInputChange}
          style={{ width: '100%', padding: '5px' }}
        />
      </Form.Group>

      <Form.Group controlId="text" style={{ marginBottom: '30px' }}>
        <Form.Label>Role:</Form.Label>
        <Form.Control
          type="text"
          name="role"
          placeholder='Waiter'
          value={profile.text}
          onChange={handleInputChange}
          style={{ width: '100%', padding: '5px' }}
        />
      </Form.Group>
    <Form.Group controlId="text" style={{ marginBottom: '30px' }}>
        <Form.Label>Phone Number:</Form.Label>
        <Form.Control
          type="number"
          name="number"
          placeholder='+(555) 555-1234.'
          value={profile.number}
          onChange={handleInputChange}
          style={{ width: '100%', padding: '5px' }}
        />
      </Form.Group>

      <Form.Group controlId="bio" style={{ marginBottom: '30px' }}>
        <Form.Label>Email Address:</Form.Label>
        <Form.Control
          type="email"
          name="email"
          placeholder='Johana@gmail.com'
          value={profile.bio}
          onChange={handleInputChange}
          style={{ width: '100%', padding: '5px' }}
        />
      </Form.Group>

      <Button type="submit" variant="warning" style={{ padding: '8px 16px', float: 'right' }}>
        Save Changes
      </Button>
    </Form>
    </div>
  );
};

export default Profile;
