import React, { useState, useContext, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Button, Row, Col, Toast } from "react-bootstrap";

import Sidebar from "./components/Sidebar/Sidebar";
import Header from "./components/Header.js";

import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ForgotOtp from "./ForgotOtp";
import SignUp from "./SignUp.js";
import ResetPassword from "./ResetPassword";
import RouteConfig from "./route/RouteConfig";
import { ToastContainer, toast } from "react-toastify";
import { AuthContext } from "./AuthContext";
import "react-toastify/dist/ReactToastify.css";

import Menu from "./components/Customer/Menu";
import StripePayment from "./components/Waiter/StripePayment/StripePayment.js";

import { useDispatch, useSelector } from "react-redux";
import { allBranches } from "./redux/features/admin/Branches/branchesAction";

// Third Party
// import {
//   getFirebaseToken,
//   onForegroundMessage,
//   requestForToken,
// } from "./config/firebase.js";
import axios from "axios";

import { isBrowser, isMobile } from "react-device-detect";

// Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

function App() {
  const stripePromise = loadStripe(
    "pk_test_51NssvZBlJ7hSTSfiF9pD2j6skfbF1h9XHzUkNWEHUgd3kiAAsg0XY8zsx5qS242WkaHZWObL7mC4Bu5SjqQwgaxR003iKeNGNU"
  ); // Replace with your Stripe publishable key

  const [isAuthenticated, setIsAuthenticated] = useState(false); // Set the initial value based on authentication status
  const { currentUser } = useContext(AuthContext);
  const { pathname } = window.location;
  const RestaurantPath = pathname.startsWith("/restaurant");
  const PaymentPath = pathname.startsWith("/payment");

  // Function to handle logout
  // const handleLogout = () => {
  //   // Perform logout logic and update the authentication status accordingly
  //   setIsAuthenticated(false);
  // };

  const styles = {
    contentDiv: {
      display: "flex",
      backgroundColor: "#f0f2f8",
    },
    contentMargin: {
      width: "100%",
    },
    outerLine: {
      margin: "30px",
    },
  };
  // Set the initial value of role state
  const [userRole, setUserRole] = useState(null);

  // Simulating login and setting the user role

  useEffect(() => {
    if (currentUser) {
      setUserRole(currentUser?.userType?.title);
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      setUserRole(null);
    }
  }, [currentUser]);

  // Simulating logout and resetting the user role
  const handleLogout = () => {
    setUserRole(null);
  };

  // const [showNotificationBanner, setShowNotificationBanner] = useState(
  //   Notification.permission === "default"
  // );

  // useEffect(() => {
  //   onForegroundMessage()
  //     .then((payload) => {
  //       console.log("Received foreground message: ", payload);
  //       const {
  //         notification: { title, body },
  //       } = payload;
  //       toast(<ToastifyNotification title={title} body={body} />);
  //     })
  //     .catch((err) =>
  //       console.log(
  //         "An error occurred while retrieving foreground message. ",
  //         err
  //       )
  //     );
  // }, []);
  // const handleGetFirebaseToken = () => {
  //   getFirebaseToken()
  //     .then((firebaseToken) => {
  //       console.log("Firebase token: ", firebaseToken);
  //       if (firebaseToken) {
  //         setShowNotificationBanner(false);
  //       }
  //     })
  //     .catch((err) =>
  //       console.error("An error occured while retrieving firebase token. ", err)
  //     );
  // };

  const [deviceType, setDeviceType] = useState("web");
  const [deviceId, setDeviceId] = useState("web");

  // useEffect(() => {
  //   setDeviceType(isBrowser ? "web" : isMobile ? "mobile" : null);
  //   getFirebaseToken()
  //     .then((firebaseToken) => {
  //       setDeviceId(firebaseToken);
  //       if (firebaseToken) {
  //         setShowNotificationBanner(false);
  //       }
  //     })
  //     .catch((err) =>
  //       console.error("An error occured while retrieving firebase token. ", err)
  //     );
  // }, []);

  // useEffect(() => {
  //   const handlePermission = async () => {
  //     try {
  //       const permission = await Notification.requestPermission();
  //       if (permission === 'granted') {
  //         const firebaseToken = await getFirebaseToken();
  //         setDeviceId(firebaseToken);
  //         setShowNotificationBanner(false);
  //       } else {
  //         console.warn('User denied permission for notifications');
  //       }
  //     } catch (error) {
  //       console.error('Error handling permission:', error);
  //     }
  //   };

  //   // Check if browser is Safari
  //   if (navigator.userAgent.includes("Safari") && !navigator.userAgent.includes("Chrome")) {
  //     handlePermission();
  //   } else {
  //     // For browsers other than Safari
  //     getFirebaseToken()
  //       .then((firebaseToken) => {
  //         setDeviceId(firebaseToken);
  //         if (firebaseToken) {
  //           setShowNotificationBanner(false);
  //         }
  //       })
  //       .catch((err) =>
  //         console.error("An error occurred while retrieving firebase token. ", err)
  //       );
  //   }
  // }, []);

  // useEffect(() => {
  //   const addDevice = async () => {
  //     try {
  //       const user = JSON.parse(localStorage.getItem("currentUser"));
  //       const config = {
  //         headers: {
  //           Authorization: `Bearer ${user?.accessToken}`,
  //         },
  //       };

  //       const response = await axios.post(
  //         `${process.env.REACT_APP_BASE_URL}/auth/deviceAdd`,
  //         {
  //           device_id: deviceId,
  //           device_type: deviceType,
  //         },
  //         config
  //       );

  //       console.log("Device added:", response.data); // Logging the response data (handle as needed)
  //     } catch (error) {
  //       console.error("Error adding device:", error);
  //       // Handle error (e.g., show error message to the user)
  //     }
  //   };

  //   // Call addDevice function when deviceId changes
  //   if (deviceId) {
  //     addDevice();
  //   }
  // }, [deviceId]); // Ensure all dependencies are included in the dependency array

  const ToastifyNotification = ({ title, body }) => (
    <div className="push-notification">
      <h2 className="push-notification-title">{title}</h2>
      <p className="push-notification-text">{body}</p>
    </div>
  );

  return (
    <>
      <Elements stripe={stripePromise}>
        <Router>
          <Route exact path="/customer/menu" component={Menu}></Route>
          <Route
            exact
            path="/restaurant/:branchName/:id"
            component={Menu}
          ></Route>
          <Route exact path="/payment/:id" component={StripePayment}></Route>
          <ToastContainer />

          <div style={styles.contentDiv}>
            {!RestaurantPath && !PaymentPath && isAuthenticated && (
              <Sidebar role={userRole} onLogout={handleLogout} />
            )}

            <div style={styles.contentMargin}>
              {!RestaurantPath && !PaymentPath && isAuthenticated && <Header />}

              <div style={styles.outerLine}>
                {!userRole ? (
                  <Route exact path="/" component={Login}>
                    <Login
                      setIsAuthenticated={setIsAuthenticated}
                      deviceType={deviceType}
                      deviceId={deviceId}
                    />
                  </Route>
                ) : (
                  <RouteConfig role={userRole} />
                )}
                <Route
                  exact
                  path="/forgotPassword"
                  component={ForgotPassword}
                ></Route>
                <Route
                  exact
                  path="/forgotPassword/otp"
                  component={ForgotOtp}
                ></Route>
                <Route
                  exact
                  path="/resetPassword"
                  component={ResetPassword}
                ></Route>
                <Route exact path="/signUp" component={SignUp}></Route>
              </div>
            </div>
          </div>
        </Router>
      </Elements>
    </>
  );
}

export default App;
