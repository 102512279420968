import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const getAllUserType = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/superAdmin/userType/all`,
    config
  );
  if (response.data.body) {
    return response.data;
  }
}

const addUserType = async (token,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${BASE_URL}/superAdmin/userType/add`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Successfully Added UserType");
      return response.data;
    }
  };

  const updateUserType = async (token, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/superAdmin/userType/${data.id}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Successfully Update UserType");
      return response.data.body;
    }
  };

  const updateUserTypeStatus = async (token, data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/superAdmin/userType/status/${data._id}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Successfully Update UserType Status");
      return response.data.body;
    }
  };
  

const userTypeService = {
    getAllUserType,
    addUserType,
    updateUserType,
    updateUserTypeStatus
  };
  
  export default userTypeService;
  