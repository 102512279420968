import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderService from "./orderService";

const initialState = {
  orders: [],
  order: {},
  isError: false,
  isSuccess: false,
  isSuccessOrder: false,
  isLoading: false,
  message: "",
};

//get customer order
export const getOrder = createAsyncThunk(
  "customer/getOrder",
  async (branchId, thunkAPI) => {
    try {
      const order = JSON.parse(localStorage.getItem("orderData"));
      return await orderService.getCustomerOrder(branchId, order);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add OrderItem
export const addOrderItem = createAsyncThunk(
  "customer/addOrderItem",
  async (data, thunkAPI) => {
    try {
      return await orderService.addOrderItem(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// clear Order
export const clearOrder = createAsyncThunk(
  "customer/clearOrder",
  async (data, thunkAPI) => {
    try {
      return await orderService.clearOrder(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addOrder = createAsyncThunk(
  "customer/addOrder",
  async (data, thunkAPI) => {
    try {
      return await orderService.addOrder(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateOrder = createAsyncThunk(
  "customer/updateOrder",
  async (data, thunkAPI) => {
    try {
      return await orderService.addOrder(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const customerOrderSlice = createSlice({
  name: "customerOrder",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.isSuccessOrder = false;
      state.order = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.order = action.payload;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.order = [];
      })
      .addCase(addOrderItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrderItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.order = action.payload;
      })
      .addCase(addOrderItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(clearOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(clearOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.order = action.payload;
      })
      .addCase(clearOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessOrder = true;
        state.orders = action.payload;
      })
      .addCase(addOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccessOrder = false;
        state.message = action.payload;
      })
      .addCase(updateOrder.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orders = action.payload;
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccessOrder = false;
        state.message = action.payload;
      });
  },
});
export const { reset } = customerOrderSlice.actions;

export default customerOrderSlice.reducer;
