import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import branchesService from "./branchesService";

const initialState = {
  branches: [],
  selectBranches: [],
  selectRestaurantLogo: "",
  totalPage: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//get all categories
export const allBranches = createAsyncThunk(
  "admin/allbranches",
  async (pageNo, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await branchesService.getAllBranches(user.accessToken, pageNo);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update staff
export const updateBranches = createAsyncThunk(
  "admin/updateBranches",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await branchesService.updateBranch(user.accessToken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addBranches = createAsyncThunk(
  "admin/addBranches",
  async ({ data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await branchesService.addBranch(user.accessToken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const branchUpdate = createAsyncThunk(
  "admin/branchUpdate",
  async ({ id, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await branchesService.branchUpdate(user.accessToken, id, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const branchesSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
    setSelectedBranch: (state, action) => {
      state.selectBranches = [action.payload];
    },
    setSelectRestaurantLogo: (state, action) => {
      state.selectRestaurantLogo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allBranches.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allBranches.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.branches = action.payload.body;
        state.totalPage = 1;
      })
      .addCase(allBranches.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.branches = [];
      })
      .addCase(updateBranches.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBranches.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.branches = action.payload;
      })
      .addCase(updateBranches.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(addBranches.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBranches.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.branches = action.payload.body;
      })
      .addCase(addBranches.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(branchUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(branchUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.branches = action.payload.body;
      })
      .addCase(branchUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { reset, setSelectedBranch, setSelectRestaurantLogo } =
  branchesSlice.actions;

export default branchesSlice.reducer;
