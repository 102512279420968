import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderService from "./orderService";

const initialState = {
  orders: [],
  order: {},
  orderStatus:{},
  totalPages: null,
  isError: false,
  isSuccess: false,
  isSuccessOrder: false,
  isLoading: false,
  message: "",
};

//add order
export const addOrder = createAsyncThunk(
  "admin/addOrder",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await orderService.addOrder(user.accessToken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//get order status
export const getOrderStatus = createAsyncThunk(
  "admin/getOrderStatus",
  async ({ branchId, tableId }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await orderService.getOrderStatus(
        user.accessToken,
        branchId,
        tableId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// OrderItem
export const addOrderItem = createAsyncThunk(
  "admin/addOrderItem",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await orderService.addOrderItem(user.accessToken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// clear Order
export const clearOrder = createAsyncThunk(
  "admin/clearOrder",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await orderService.clearOrder(user.accessToken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// get all order
// export const allOrder = createAsyncThunk(
//   "admin/allOrder",
//   async (branchId, pageNo, thunkAPI) => {
//     try {
//       const user = JSON.parse(localStorage.getItem("currentUser"));
//       return await orderService.getAllOrderByBranchId(
//         user.accessToken,
//         branchId,
//         pageNo
//       );
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

// get all order By Branch Id
export const allOrderByBranchId = createAsyncThunk(
  "admin/allOrderByBranchId",
  async ({ branchId, pageNo }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await orderService.getAllOrderByBranchId(
        user.accessToken,
        branchId,
        pageNo
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDiningAndProcessOrder = createAsyncThunk(
  "admin/getDiningAndProcessOrder",
  async (branchId, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await orderService.getDiningAndProcessOrder(
        user.accessToken,
        branchId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.orders = action.payload;
      })
      .addCase(addOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getOrderStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrderStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.order = action.payload;
      })
      .addCase(getOrderStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.order = [];
      })
      .addCase(addOrderItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrderItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isSuccessOrder = true;
        state.order = action.payload;
      })
      .addCase(addOrderItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.isSuccessOrder = false;
        state.message = action.payload;
      })
      .addCase(clearOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(clearOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.order = action.payload;
      })
      .addCase(clearOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(allOrderByBranchId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allOrderByBranchId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orders = action.payload?.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(allOrderByBranchId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.orders = [];
      })
      .addCase(getDiningAndProcessOrder.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getDiningAndProcessOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orderStatus = action.payload;
      })
      .addCase(getDiningAndProcessOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.orderStatus = {};
      });
  },
});
export const { reset } = orderSlice.actions;

export default orderSlice.reducer;
