import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Col, Row, Dropdown,Spinner } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import {
  allActiveCategoryByBranchId,
  allActivecategories,
} from "../../../redux/features/admin/Categories/createAction";
import { allActiveVariantOption } from "../../../redux/features/admin/VariantOption/VariantOptionSlice";
import { allActiveVariant } from "../../../redux/features/admin/Variant/VariantSlice";
import { updateMenu } from "../../../redux/features/admin/Menu/menuSlice";
import { allActiveAllergy } from "../../../redux/features/admin/Allergy/allergySlice";


function MenuItemsModal(props) {
  const dispatch = useDispatch();
  const { selectBranches } = useSelector((state) => state.branches);
  const { categories } = useSelector((state) => state.category);
  const { variant } = useSelector((state) => state.variant);
  const { variantOption } = useSelector((state) => state.variantOption);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [recentlyClickedVariant, setRecentlyClickedVariant] = useState(null);
  const [isRecentlyClicked, setIsRecentlyClicked] = useState(false);
  const [selectedAllergy, setSelectedAllergy] = useState([]);

  const { allergy } = useSelector(
    (state) => state.allergy
  );
  const {isLoading} = useSelector(
    (state) => state.menu
  );
  const toTitleCase = (str) => {
    return str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleVariantSelection = (variantId) => {
    if (selectedVariants.includes(variantId)) {
      setSelectedVariants(selectedVariants.filter((id) => id !== variantId));
      props?.setVariantsOptionData(
        props?.variantOptionData?.filter(
          (option) => option.variantId !== variantId
        )
      );
    } else {
      setSelectedVariants([...selectedVariants, variantId]);
      const variantOptionsForVariant = variantOption.filter(
        (option) => option.variantId === variantId
      );
      const updatedOptions = variantOptionsForVariant.map((obj) => ({
        variantOption: {
          ...obj,
        },
        isSelected: false,
        variantId: obj.variantId,
        price: 0,
      }));
      props?.variantOptionData?.push(...updatedOptions);
    }
    setRecentlyClickedVariant(variantId);
    setIsRecentlyClicked(true);

    // Reset the recently clicked state after a short delay (e.g., 500 ms)
    setTimeout(() => {
      setIsRecentlyClicked(false);
      setRecentlyClickedVariant(null);
    }, 500);
  };

  const handleAllergySelection = (allergyId) => {
    if (selectedAllergy.includes(allergyId)) {
      setSelectedAllergy(selectedAllergy.filter((id) => id !== allergyId));
      props?.setAllergyData(
        props?.allergyData?.filter(
          (option) => option.allergyId !== allergyId
        )
      );
     
    } else {
      setSelectedAllergy([...selectedAllergy, allergyId]);
      const allergyArray = allergy.filter(
        (option) => option._id === allergyId
      );
      const updatedOptions = allergyArray.map((obj) => ({
        allergyId: obj._id,
      }));
      props?.allergyData?.push(...updatedOptions);
    }
    setRecentlyClickedVariant(allergyId);
    setIsRecentlyClicked(true);

    setTimeout(() => {
      setIsRecentlyClicked(false);
      setRecentlyClickedVariant(null);
    }, 500);
  };

  const handleSave = () => {
    const updateData = {
      _id: props?.data._id,
      title: props?.title,
      price: props?.price,
      description: props?.description,
      categories: props?.category,
      variantOptionData: props.variantOptionData,
      allergyData: props?.allergyData,
      pageNo: props.currentPage + 1,
    };
    dispatch(
      updateMenu({
        branchId: selectBranches[0],
        data: updateData,
        search: props?.searchQuery,
      })
    );
  };

  useEffect(() => {
    dispatch(allActiveCategoryByBranchId(selectBranches[0]));
    dispatch(allActiveVariantOption(selectBranches[0]));
    dispatch(allActiveVariant(selectBranches[0]));
    dispatch(allActiveAllergy(selectBranches[0]));
  }, [selectBranches]);

  useEffect(() => {
    if (
      props.variantOptionData.length != 0 &&
      props.data.menuvariants != undefined
    ) {
      const uniqueVariantTitles = [
        ...new Set(props.data.menuvariants?.filter((i) => i?.variant?.isActive)?.map((i) => i?.variant?._id)),
      ];
      setSelectedVariants(uniqueVariantTitles);
    }
  }, [props.data]);


  useEffect(() => {
    if (
      props.allergyData.length != 0 &&
      props.data.menuallergies != undefined
    ) {
      const uniqueAllergyTitles = [
        ...new Set(props.data.menuallergies?.filter((i) => i?.allergies?.isActive)?.map((i) => i?.allergies?._id)),
      ];
      setSelectedAllergy(uniqueAllergyTitles);
    }
  }, [props.data]);

  const handleVariantOption = (option) => {
    const foundIndex = props.variantOptionData?.findIndex(
      (element) => element.variantOption._id === option.variantOption._id
    );
    if (foundIndex !== -1) {
      props.setVariantsOptionData((prevData) => {
        const updatedData = [...prevData];

        if (updatedData[foundIndex].isSelected === false) {
          updatedData[foundIndex] = {
            ...updatedData[foundIndex],
            isSelected: true,
          };
        } else {
          updatedData[foundIndex] = {
            ...updatedData[foundIndex],
            isSelected: false,
          };
        }

        return updatedData;
      });
    }
  };

  const handlePriceChange = (option, event) => {
    const numericValue = parseFloat(event.target.value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      props.setVariantsOptionData((prevData) => {
        const updatedData = [...prevData];
        const foundIndex = updatedData.findIndex(
          (element) => element.variantOption._id === option.variantOption._id
        );
        if (foundIndex !== -1) {
          updatedData[foundIndex] = {
            ...updatedData[foundIndex],
            price: numericValue,
          };
        }

        return updatedData;
      });
    }
  };

  const handleCloseModal = () => {
    setSelectedVariants([]);
    setSelectedAllergy([]);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      onHide={handleCloseModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Menu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col lg={4} sm={6}>
              <Form.Group controlId="text" style={{ marginBottom: "30px" }}>
                <Form.Label className="fw-bold">Title:</Form.Label>
                <Form.Control
                  type="text"
                  name="text"
                  value={props?.title}
                  placeholder="Enter Title"
                  style={{ width: "100%", padding: "5px" }}
                  onChange={(e) => props?.setTitle(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={4} sm={6}>
              <Form.Group controlId="price" style={{ marginBottom: "30px" }}>
                <Form.Label className="fw-bold"> Price:</Form.Label>
                <Form.Control
                  type="number"
                  name="Price"
                  value={props?.price}
                  placeholder="Enter Price"
                  style={{ width: "100%", padding: "5px" }}
                  onChange={(e) => props?.setPrice(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={4} sm={6}>
              <Form.Group controlId="category" style={{ marginBottom: "30px" }}>
                <Form.Label className="fw-bold">Category</Form.Label>
                <Form.Select
                  aria-label="select"
                  required
                  value={props?.category}
                  onChange={(e) => props.setCategory(e.target.value)}
                >
                  <option selected disabled>
                    Select Category
                  </option>
                  {categories?.map((item) => (
                    <option key={item._id} value={item?._id}>
                      {item?.title}
                    </option>
                  ))}
                  ;
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={7} sm={6}>
              <Form.Group
                controlId="description"
                style={{ marginBottom: "30px" }}
              >
                <Form.Label className="fw-bold">Description:</Form.Label>
                <Form.Control
                  type="text"
                  name="Description"
                  value={props?.description}
                  placeholder="Enter Description"
                  style={{ width: "100%", padding: "5px" }}
                  onChange={(e) => props?.setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={5} sm={6}>
              <Form.Group controlId="allergy" style={{ marginBottom: "30px" }}>
                <Form.Label className="fw-bold">Allergy</Form.Label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-dark"
                    className="w-100 text-start"
                  >
                    { selectedAllergy.length === 0
                      ? "Select Allergy"
                      : selectedAllergy.length === 1
                      ? toTitleCase(
                        allergy.find(
                            (allergy) => allergy._id === selectedAllergy[0]
                          )?.title
                        )
                      : `${selectedAllergy.length} Allergy`}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    {allergy?.map((item) => (
                      <Dropdown.Item
                        key={item._id}
                        onClick={() => handleAllergySelection(item._id, item)}
                      >
                        <Form.Check
                          key={item._id}
                          type="checkbox"
                          id={`checkbox-${item._id}`}
                          label={toTitleCase(item.title)}
                          checked={
                            selectedAllergy.includes(item._id) ||
                            (item._id === recentlyClickedVariant &&
                              isRecentlyClicked)
                          }
                        />
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Col>
            <Col lg={5} sm={6}>
              <Form.Group controlId="variant" style={{ marginBottom: "30px" }}>
                <Form.Label className="fw-bold">Variant</Form.Label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-dark"
                    className="w-100 text-start"
                  >
                    {selectedVariants.length === 0
                      ? "Select Variant"
                      : selectedVariants.length === 1
                      ? toTitleCase(
                          variant.find(
                            (variant) => variant._id === selectedVariants[0]
                          )?.title
                        )
                      : `${selectedVariants.length} Variants`}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    {variant?.map((item) => (
                      <Dropdown.Item
                        key={item._id}
                        onClick={() => handleVariantSelection(item._id)}
                      >
                        <Form.Check
                          type="checkbox"
                          id={`checkbox-${item._id}`}
                          label={toTitleCase(item.title)}
                          // checked={selectedVariants.includes(item._id)}
                          checked={
                            selectedVariants.includes(item._id) ||
                            (item._id === recentlyClickedVariant &&
                              isRecentlyClicked)
                          }
                        />
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Col>
            {/* <Col lg={5} sm={6}>
              <Form.Group controlId="text" style={{ marginBottom: "30px" }}>
                <Form.Label className="fw-bold">Image:</Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  // placeholder='Whole BBQ Chicken, choice of Rice or Chips with Coleslaw.'
                  style={{ width: "100%", padding: "5px" }}
                />
              </Form.Group>
            </Col> */}
          </Row>
            <Row>
              {selectedVariants.length > 0 &&
                selectedVariants.map((selectedVariant) => {
                  const variants = variant?.find(
                    (v) => v._id === selectedVariant
                  );
                  const variantOptionsForVariant =
                    props.variantOptionData?.filter(
                      (option) => option.variantId === selectedVariant
                    );
                  return (
                    <Col
                      key={variants?._id}
                      lg={4}
                      style={{ marginBottom: "30px" }}
                    >
                      <Form.Group controlId={`variantOption-${variants?._id}`}>
                        <Form.Label className="fw-bold">
                          {toTitleCase(variants?.title)}
                        </Form.Label>
                        {variantOptionsForVariant?.map((option) => (
                          <>
                            <Form.Check
                              key={option?.variantOption?._id}
                              type="checkbox"
                              id={`checkbox-${option?.variantOption?._id}`}
                              label={toTitleCase(option?.variantOption?.title)}
                              checked={option?.isSelected}
                              onChange={() => handleVariantOption(option)}
                            />
                            <Form.Control
                              type="number"
                              name="Price"
                              value={option?.price}
                              placeholder="Enter Price"
                              style={{ width: "100%", padding: "5px" }}
                              onChange={(e) => handlePriceChange(option, e)}
                            />
                          </>
                        ))}
                      </Form.Group>
                    </Col>
                  );
                })}
            </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" disabled={isLoading}  onClick={handleSave}>
        {isLoading ? (
            <Spinner animation="border" size="sm"/>
          ) : (
            "Update Menu"
          )}
          {/* Update Menu{" "} */}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MenuItemsModal;
