import React from "react";
import Modal from "react-bootstrap/Modal";
import { Vortex } from "react-loader-spinner";

function GeneralIndicator() {
  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="transparent-modal"
    >
      <Modal.Body className="text-center ">
        <Vortex
          visible={true}
          height="80"
          width="80"
          ariaLabel="vortex-loading"
          wrapperStyle={{}}
          wrapperClass="vortex-wrapper"
          colors={[
            "#E94542",
            "#FEC605",
            "#E94542",
            "#FEC605",
            "#FEC605",
            "#E94542",
          ]}
        />
      </Modal.Body>
    </Modal>
  );
}

export default GeneralIndicator;
