import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;


const getAllorder = async (token,branchId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/cook/order?branchId=${branchId}`, config);
  if (response.data.body) {
    return response.data;
  }
};

const getComingOrder = async (token,branchId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/cook/order?branchId=${branchId}`, config);
  if (response.data.body) {
    return response.data;
  }
};
const updateOrder = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/cook/order/${data._id}?branchId=${data.branchId}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Add Order");

    return response.data.body;
  }
};


const kitchenOrderService = {
    getAllorder,
    getComingOrder,
    updateOrder
};

export default kitchenOrderService;
