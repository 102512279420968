import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

// import data from "./ColleagueData";

function TablesCards(props) {
    return <Row>
        <h3 className='fw-bold'>Floor 1</h3>
        {props.ReservationTables.map((val, ind) => {
            return <Col md={4} xl={2} key={ind}>
                <Card className='my-3 p-4 border-0'>
                    <div className="d-flex justify-content-end">
                        <img src={val.chairImg} alt={val.chairImg} className='' width={50} />
                        <Card.Text className='h2'>{val.tableNumber}</Card.Text>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-center mt-4">
                        <img src={val.peoplesImg} alt={val.peoplesImg} className='pt-2' width={25} height={30} />
                        <Card.Text className='text-center h6 fw-bolder mt-2 mx-2'>{val.people}</Card.Text>
                    </div>
                </Card>
            </Col>
        }
        )}
    </Row>
        ;
}

export default TablesCards;