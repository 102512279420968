import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

function ConfirmationModal({ show, handleClose, handleConfirm }) {
  return (
    <Modal size="sm" centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Your Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to confirm this order?</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          No
        </Button>
        <Button variant="warning" onClick={handleConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ConfirmationModal;
