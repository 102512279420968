import React, { useEffect, createContext, useState } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    const storedUser = localStorage.getItem("currentUser");
    return storedUser && storedUser != "undefined"
      ? JSON.parse(storedUser)
      : null;
  });

  useEffect(() => {
    if (currentUser !== undefined || currentUser !== null) {
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
    } else {
      setCurrentUser(null);
      localStorage.removeItem("currentUser");
      localStorage.clear();
    }
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
