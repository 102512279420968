import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const getAllStaff = async (token, branchId,pageNo,searchQuery) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/staff/list/branch?branchId=${branchId}&page=${pageNo}&search=${
      searchQuery ? searchQuery : ""
    }`,
    config
  );
  if (response.data.body) {
    return response.data;
  }
};

const addStaff = async (token, branchId, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/admin/staff/add?branchId=${branchId}&page=${data.pageNo}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Add Staff");
    return response.data;
  }
};
const updateStaff = async (token, data,branchId,search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/admin/staff/${data.id}?branchId=${branchId}&page=${data.pageNo}&search=${search ? search : ""}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Update Staff");
    return response.data.body;
  }
};

const updateStaffStatus = async (token,data,branchId,search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/admin/staff/status/${data?._id}?branchId=${branchId}&page=${data.pageNo}&search=${search ? search : ""}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Update Staff Status");
    return response.data.body;
  }
};

const getActiveStaff = async (token,branchId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/staff/active?branchId=${branchId}`, config);
  if (response.data.body) {
    return response.data.body;
  }
};
const staffService = {
  getAllStaff,
  addStaff,
  updateStaff,
  updateStaffStatus,
  getActiveStaff,
};

export default staffService;
