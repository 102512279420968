import axios from "axios";
import { toast } from "react-toastify";
import { setStatus, STATUSES } from "./createAction";

export const login = (email, password, props, history, setCurrentUser) => {
  return async function createAsyncThunk(dispatch) {
    dispatch(setStatus({ status: STATUSES.LOADING }));
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/login`,
        {
          email: email,
          password: password,
          device_id: props.deviceId,
          device_type: props.deviceType,
        }
      );
      console.log(res, "response");
      dispatch(
        setStatus({
          status: STATUSES.IDLE,
        })
      );
      props.setIsAuthenticated(true);
      setCurrentUser(res?.data?.user);
      if (res?.data?.user.userType.title === "cook") {
        history.push("/cook/kitchen");
      } else if (res?.data?.user.userType.title === "waiter") {
        history.push("/waiter/order");
      } else {
        history.push("/dashboard");
      }
    } catch (error) {
      console.log(error, "Error");
      dispatch(
        setStatus({
          status: STATUSES.ERROR,
          error: error.response?.data.message,
        })
      );
      toast.error(error.response?.data.message);
    }
  };
};
