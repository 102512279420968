import React from "react";
import { Container } from "react-bootstrap";

import { BookingCardsData } from "../../../JsonData";
import BookingCards from "./BookingCards";

function Booking() {
  return (
    <Container>
      <BookingCards title={"Booking"} BookingCardsData={BookingCardsData} />
    </Container>
  );
}

export default Booking;
