import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, Pagination, Form, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";


import { useDispatch, useSelector } from "react-redux";
import {
  allExpenseCategory,
  addExpenseCategory,
  updateExpenseCategory,
  updateExpenseCategoryStatus,
  reset
} from "../../../redux/features/admin/ExpenseCategory/ExpenseCategorySlice";
import ReactPaginate from "react-paginate";
import GeneralIndicator from "../../model/GeneralIndicator";


const ExpenseCategory = () => {
  const dispatch = useDispatch();
  
  const [data, setData] = useState([]);// Set the initial value based on table data 
  const [showModal, setShowModal] = useState(false);// Set the initial value based on Add modal show 
  const [searchQuery, setSearchQuery] = useState('');// Set the initial value based on Search for Table
  const [currentPage, setCurrentPage] = useState(0);// Set the initial value based on currentPage for Table
  const [itemsPerPage] = useState(10);// Set the initial value based on itemsPerPage for Table
  const [selectedItem, setSelectedItem] = useState(null);// Set the initial value based on selected modal add/edit 
  const [lastId, setLastId] = useState(0);// Set the initial value based on Id previous value
  const [inputTitle, setInputTitle] = useState('');// Set the initial value based on Id previous val
  const [inputAmount, setInputAmount] = useState('');// Set the initial value on inputAmout field 
  const [inputDescription, setInputDescription] = useState('');// Set the initial value on inputDescription field
  const [inputDate, setInputDate] = useState('');// Set the initial value on inputDate field

  const { selectBranches } = useSelector((state) => state.branches);
  const { expenseCategory,isError,isSuccess,message,totalPages,isLoading } = useSelector((state) => state.expenseCategory);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      setShowModal(false);
      setInputTitle('');
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);
  useEffect(() => {
    dispatch(allExpenseCategory({branchId: selectBranches[0]}));
    setCurrentPage(0);
  }, [selectBranches]);
   // Handle Edit Function is used for edit the data of expensesData
  const handleEdit = (item) => {
    setSelectedItem(item);
    setInputTitle(item?.title);
    setShowModal(true);
  };

  // Handle Save Function is used for Edit the data of expensesData
  const handleSave = (e) => {
    e.preventDefault();
    if (selectedItem) {
      // Update existing item
      let data = {
        _id: selectedItem._id,
        title: inputTitle,
        pageNo: currentPage + 1,
      };
      dispatch(updateExpenseCategory({ branchId: selectBranches[0], data: data }));
    } else {
      // Add new item
      let data = {
        title: inputTitle,
        pageNo: currentPage + 1,
      };
      dispatch(addExpenseCategory({ branchId: selectBranches[0], data: data }));
    }
  };

  const handleUpdateStatus = (item) => {
    const newStatus = item?.isActive == true ? false : true;
    const data = { isActive: newStatus, _id: item._id,pageNo: currentPage + 1};
    dispatch(updateExpenseCategoryStatus({branchId: selectBranches[0], data: data}));
  };

   // Handle delete Function is used for delete the data of expensesData
  const handleDelete = (item) => {
    const updatedData = data.filter((d) => d.id !== item.id);
    setData(updatedData);
  };

   
      // Handle Close Function is used for close the modal of add/edit expensesData
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setInputTitle('');
  };

  // Handle Search Function is used for search the data in table
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

    // handlePageChange Function is used for Change Page the data in table 

  const handlePageChange = (pageNumber) => {
    let pageNo = pageNumber?.selected + 1;
    setCurrentPage(pageNumber?.selected);
    dispatch(
      allExpenseCategory({ branchId: selectBranches[0], pageNo: pageNo })
    );
  };
 // logic for indexOfLastItem
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;// logic for indexOfFirstItem
    //logic for filtered data
  const filteredData = data.filter((item) =>
    item.Category.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div>
        {isLoading && <GeneralIndicator />}
      <h2 className="p-3 fw-bold">Expense Category</h2>
      <div className="bg-white">
        <div className="d-flex justify-content-between mb-3 p-4">
          <Form.Group>
            {/* <Form.Control
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
            /> */}
          </Form.Group>
          <Button variant="danger" onClick={() => setShowModal(true)}>
            Add Expense Category
          </Button>
        </div>
        <Table style={{borderRadius: '5px', overflow: 'hidden'}} className="text-center">
          <thead>
            <tr>
              <th>sr#</th>
              <th>Title</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {expenseCategory?.map((item,index) => (
              <tr key={index}>
                <td className="text-center fw-bold">{index+1}</td>
                <td>{item?.title}</td>
                <td>     {item?.isActive === true ? (
                    <Button
                      className="btn-sm"
                      variant="success"
                      onClick={() => handleUpdateStatus(item)}
                    >
                      {item?.isActive === true ? "Active" : "InActive"}
                    </Button>
                  ) : (
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={() => handleUpdateStatus(item)}
                    >
                      {item?.isActive === true ? "Active" : "InActive"}
                    </Button>
                  )}
                </td>
                <td>
                  <Button
                    variant="warning"
                    className="me-3"
                    onClick={() => handleEdit(item)}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                  {/* <Button
                    variant="danger"
                    onClick={() => handleDelete(item)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ReactPaginate
              nextLabel=">"
              pageRangeDisplayed={10}
              marginPagesDisplayed={2}
              previousLabel="<"
              pageClassName="page-item"
              onPageChange={handlePageChange}
              currentPage={currentPage}
              pageCount={totalPages}
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination mx-4 p-2"
              activeClassName="active"
              forcePage={currentPage}
              renderOnZeroPageCount={null}
            />

        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={filteredData.length}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />

        <Modal show={showModal} onHide={handleCloseModal} centered>
        <Form method='post' onSubmit={handleSave}>
          <Modal.Header closeButton>
            <Modal.Title  className='fw-bold '>
              {selectedItem ? 'Edit Item' : 'Add Expense Category'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
                <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Title:</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={inputTitle}
                    placeholder="Enter Expense Category Title"
                    onChange={(e) => setInputTitle(e.target.value)}
                  />
                </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer className='mt-4'>
            <Button variant="danger" onClick={handleCloseModal}>
              Close
            </Button>
            <Button type="submit" variant="warning" >
              {selectedItem ? 'Save Changes' : 'Add Expense'}
            </Button>
          </Modal.Footer>
          </Form >
        </Modal>
      </div>
    </div>
  );
};

export default ExpenseCategory;
