import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import ItemCard from "./ItemCard";

import {
  Container,
  Navbar,
  Col,
  Card,
  Row,
  Form,
  Nav,
  Button,
} from "react-bootstrap";

import RestaurantInformation from "./RestaurantInformation.js";
import Asun from "../../components/Images/Menu-Items/Asun.png";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import Cart from "./Cart.js";
import BackToTopButton from "./BackToTopButton.js";
import {
  getOrder,
  addOrderItem,
} from "../../redux/features/customer/Order/orderSlice.js";

import { getPaymentMethod } from "../../redux/features/customer/paymentMethod/paymentMethodSlice.js";
import { useSelector, useDispatch } from "react-redux";

import { NewOrderMenuData } from "../../JsonData.js";
import GeneralIndicator from "../model/GeneralIndicator";

function Menu() {
  const params = useParams();
  const branchId = params?.id != undefined && params?.id;
  const [activeTab, setActiveTab] = React.useState(0);
  const [modalShow, setModalShow] = React.useState(false);
  const [currency, setCurrency] = useState(false);
  const counter = useRef(0);

  const dispatch = useDispatch();
  const { order, isLoading } = useSelector((state) => state.customerOrder);
  const { paymentMethod } = useSelector((state) => state.paymentMethod);

  const [navbar, setNavbar] = useState(false);

  const changeNavbarSticky = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeNavbarSticky();
    // adding the event when scroll change navbar sticky
    window.addEventListener("scroll", changeNavbarSticky);
  });

  useEffect(() => {
    dispatch(getPaymentMethod(branchId));
  }, [branchId]);

  useEffect(() => {
    const existingOrderData = localStorage.getItem("orderData");

    if (existingOrderData) {
      const parsedOrderData = JSON.parse(existingOrderData);

      if (branchId !== parsedOrderData.branchId) {
        localStorage.removeItem("orderData");

        if (order && order.order && order.order._id && order.order.customerId) {
          const newOrderData = {
            orderId: order.order._id,
            userId: order.order.customerId,
            branchId: branchId,
          };
          localStorage.setItem("orderData", JSON.stringify(newOrderData));
        }
      }
    } else {
      if (order && order.order && order.order._id && order.order.customerId) {
        const newOrderData = {
          orderId: order.order._id,
          userId: order.order.customerId,
          branchId: branchId,
        };
        localStorage.setItem("orderData", JSON.stringify(newOrderData));
      }
    }

    if (counter.current === 0) {
      dispatch(getOrder(branchId));
      counter.current = 1;
    }
    setCurrency(order?.restaurantInformation?.countries?.currencySymbol);
  }, [order, branchId, counter]);

  const handleAddCard = (
    dataItem,
    color,
    variantOption,
    setSelectedOptions,
    setVariantOption,
    setOpen,
    setButtonText,
    setButtonColor,
    accordionSelectedOptions,
    setAccordionSelectedOptions
  ) => {
    const transformedArray = [].concat(
      ...Object.values(accordionSelectedOptions).map((array) =>
        array.map((option) => ({
          _id: option.variantOption._id,
          title: option.variantOption.title,
          price: option.price,
          quantity: 1,
        }))
      )
    );

    const variantOptionPrice = transformedArray.reduce(
      (total, option) => total + option.price,
      0
    );
    let data = {
      orderId: order?.order?._id,
      menuCategoriesId: dataItem._id,
      quantity: 1,
      itemPrice: dataItem.price + variantOptionPrice,
      userId: order?.order?.customerId,
      branchId: branchId,
      variantOption: transformedArray,
    };
    dispatch(addOrderItem(data));
    setOpen(false);
    setSelectedOptions([]);
    setVariantOption([]);
    setButtonText("plus"),
      setButtonColor("warning"),
      setAccordionSelectedOptions({});
    // if (!color) {
    // } else {
    //   let data = {
    //     orderId: order?.order?._id,
    //     menuCategoriesId: dataItem._id,
    //     type: "deleteCartItem",
    //     userId: order?.order?.customerId,
    //     branchId: branchId,
    //   };
    //   dispatch(addOrderItem(data));
    // }
  };

  // define an onClick function to bind the value on tab click
  const onTabClick = (e, index) => {
    setActiveTab(index);
    const element = document.getElementById(index);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const [showButton, setShowButton] = useState(false);

  const badgeSpanStyle = {
    position: "fixed",
    top: "10",

    right: "100", // Adjust the right value to control badge placement
    transform: "translate(-50%, -50%)",
    borderRadius: "50%",
    backgroundColor: "red", // Change the background color as needed
    padding: "5px 10px",
    fontSize: "13px",
  };

  const badgeStyle = {
    // marginLeft: "40%",

    color: "white",
    fontWeight: "bold",
    position: "fixed", // Added position:relative to the parent element
  };

  return (
    <>
      {isLoading && <GeneralIndicator />}
      <div style={{ backgroundColor: "#d8d7d5" }}>
        <Navbar
          style={{ height: "50px", backgroundColor: "#111827" }}
          expand="lg"
        >
          <Container fluid></Container>
        </Navbar>
        <RestaurantInformation order={order} />

        <Col lg={12}>
          <Container
            fluid
            className={navbar ? "p-0" : "px-4"}
            style={{
              position: navbar ? "fixed" : "relative",
              top: navbar ? 0 : "",
              zIndex: 999,
            }}
          >
            <Card className={navbar ? "pb-0" : "pb-0"}>
              {/* <p className='text-center' style={{ textAlign: 'center' }}>Rating: 3.5/5 -39 reviews</p> */}
              {/* <div className=" me-auto rounded-3">
                            <Form.Control
                                type="search"
                                placeholder="Search Items"
                                style={{ width: '300%' }}
                                aria-label="Search"
                            />
                        </div> */}

              <div style={{ fontFamily: "sans-serif", textAlign: "center" }}>
                <Navbar>
                  <Tabs
                    activeTab={activeTab}
                    onTabClick={onTabClick}
                    hideNavBtnsOnMobile={false}
                    hideNavBtns
                  >
                    {order?.restaurantInformation?.categories?.map((item) => (
                      <Tab key={item} eventKey={item}>
                        {item?.title}{" "}
                      </Tab>
                    ))}
                  </Tabs>
                </Navbar>
              </div>
            </Card>
          </Container>
        </Col>

        {order?.restaurantInformation?.categories?.map((item, index) => (
          <Container fluid className="px-4" id={index}>
            <Col lg={12}>
              <Card className="p-3 rounded-0">
                <div
                  className="mt-5 p-3"
                  style={{ backgroundColor: "#eeeeee" }}
                >
                  <h3 className="text-center" style={{ textAlign: "center" }}>
                    {item?.title}
                  </h3>
                </div>
                <Row>
                  {item?.menucategories?.map((menucategories) => (
                    <ItemCard
                      menucategories={menucategories}
                      currency={currency}
                      handleAddCard={handleAddCard}
                      color={order?.orderItemList?.some(
                        (item) =>
                          item.menuCategoriesId._id === menucategories._id
                      )}
                    ></ItemCard>
                  ))}
                </Row>
              </Card>
            </Col>
          </Container>
        ))}

        <BackToTopButton />
        <footer className="bg-dark text-white">
          <Navbar expand="lg" style={{ height: "50px" }}>
            <Container fluid></Container>
          </Navbar>
        </footer>
        <Navbar
          bg="black"
          expand="lg"
          className="text-center"
          style={{
            height: "50px",
            position: "fixed",
            bottom: 0,
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={badgeStyle}>
            <span style={badgeSpanStyle} className="badge">
              {order?.orderItemList?.length ? order?.orderItemList?.length : 0}
            </span>
            <FontAwesomeIcon
              style={{ fontSize: "24px", width: "30px", height: "30px" }}
              icon={faShoppingCart}
              onClick={() => setModalShow(true)}
            />{" "}
            Order Total-{currency}{" "}
            {order?.totalPayment ? order?.totalPayment : 0}
          </p>
        </Navbar>
        <Cart
          onHide={() => setModalShow(false)}
          show={modalShow}
          order={order}
          branchId={branchId}
          currency={currency}
        />
      </div>
    </>
  );
}

export default Menu;
