import React from "react";
import { Container } from "react-bootstrap";

import OrderTable from "./OrderTable";
import { OrdersTableData } from "../../../JsonData.js";
import { useSelector } from "react-redux";
import GeneralIndicator from "../../model/GeneralIndicator.js";

function Orders() {
  const { isLoading } = useSelector((state) => state.order);
  return (
    <Container className="bg-white mt-4">
      {isLoading && <GeneralIndicator />}
      <OrderTable title={"Orders"} ColleaguesData={OrdersTableData} />
    </Container>
  );
}

export default Orders;
