import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Col, Row, Table, Card } from "react-bootstrap";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";

import { verifyPayStack } from "../../../redux/features/waiter/PayStack/waiterPayStackAction";

import { useSelector, useDispatch } from "react-redux";
import { updateOrder } from "../../../redux/features/waiter/waiterOrderAction";
import {
  reset,
  addPaymentTransaction,
} from "../../../redux/features/waiter/PayStack/waiterPayStackAction";

function PaymentModal(props) {
  const { paystack, transactionVerifyPayStack } = useSelector(
    (state) => state.waiterPayStack
  );
  const { waiterOrders } = useSelector((state) => state.waiterOrder);
  const { selectBranches } = useSelector((state) => state.branches);

  const dispatch = useDispatch();

  const handleSendToComplete = () => {
    dispatch(verifyPayStack(paystack?.reference));
  };

  useEffect(() => {
    if (transactionVerifyPayStack?.status) {
      if (transactionVerifyPayStack?.data?.status == "success") {
        let data = {
          _id: props?.orderInformation?._id,
          status: "complete",
          branchId: selectBranches[0],
          cashReceived: props?.orderInformation?.totalAmount,
        };
        dispatch(updateOrder(data));
        let transactionData = {
          branchId: selectBranches[0],
          orderId: props?.orderInformation?._id,
          totalAmount: props?.orderInformation?.totalAmount,
          type: "online",
          paymentMethod: "paystack",
          transactionId: transactionVerifyPayStack?.id,
        };
        dispatch(addPaymentTransaction(transactionData));

        props.onHide();
        toast.success("Successfully Order Complete");
        dispatch(reset());
      } else if (transactionVerifyPayStack?.data?.status != "success") {
        toast.error("Your Transaction is Failed");
        dispatch(reset());
      }
    }
  }, [transactionVerifyPayStack]);

  return (
    <>
      <Modal {...props} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold ">Qr Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row id="qr-code-container">
            <div className="text-center">
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "30%" }}
                value={
                  paystack?.authorization_url ? paystack?.authorization_url : ""
                }
                viewBox={`0 0 50 50`}
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer className="mt-4">
          <Button variant="success" onClick={handleSendToComplete}>
            Complete Order
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PaymentModal;
