import React from 'react';
import { Navbar, Container, Card, Form, Button } from 'react-bootstrap';
import PingLogo from "./ping logo.png"
import logo from "./tittle logo.png"
import { useHistory } from "react-router-dom";


function ResetPassword() {
    const history = useHistory();

    //navigate to ResetPassword
    const navigateToLink = () => {
      history.push("/");
    };

    return <>
       <Navbar bg="light" variant="dark">
            <Container>
                <Navbar.Brand href="#">
                    <div className='d-flex'>
                        <img src={PingLogo} alt="logo" className='me-2' />
                        <img src={logo} alt="logo" />
                    </div>
                </Navbar.Brand>
            </Container>
        </Navbar>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh'
        }}
        >
            <Container>
                <Card style={{ width: '500px', padding: '50px', margin: '10px auto' }}>
                    <Card.Body>
                        <Card.Text className='text-center h2 fw-bold'>Forgot Password</Card.Text>
                        <Card.Text className='text-center h6 '>Create your new password</Card.Text>
                        <Form>
                        <Form.Group controlId="name" style={{ marginBottom: '30px', maxWidth: '100vh', }}>
                                    <Form.Label className='mt-2 h5'><b>New Password:</b></Form.Label>
                                    <Form.Control
                                         type="password"
                                        name="name"
                                        placeholder='Enter your new password'
                                        style={{ width: '100%', padding: '15px' }}
                                    />
                                    
                                </Form.Group>
                                <Form.Group controlId="name" style={{ marginBottom: '30px', maxWidth: '100vh', }}>
                                    <Form.Label className='mt-2 h5'><b>Confirm Password:</b></Form.Label>
                                    <Form.Control
                                         type="password"
                                        name="name"
                                        placeholder='Confirm your password'
                                        style={{ width: '100%', padding: '15px' }}
                                    />
                                    
                                </Form.Group>
                            <div className='text-center'>
                                <Button type="submit" variant="warning"  onClick={navigateToLink} style={{ width: '100%' }}>
                                    Reset Password
                                </Button>
                            </div>
                        </Form>

                    </Card.Body>

                </Card>
            </Container>
        </div>
    </>
        ;
}

export default ResetPassword;