import React, { useState, useEffect,useContext } from 'react';
import { Button, Modal, Table, Pagination, Form, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../../AuthContext';
import { getRestaurant } from '../../../redux/features/superAdmin/Restaurants/RestaurantDetailsSlice';
import {useSelector,useDispatch} from 'react-redux';

import { activeCountry } from '../../../redux/features/superAdmin/Country/createAction';
import { getSubscription } from '../../../redux/features/superAdmin/Subscription/subscriptionDetailsSlice';
import { updateRestaurant ,updateRestaurantStatus} from '../../../redux/features/superAdmin/Restaurants/RestaurantDetailsSlice';
import ReactPaginate from "react-paginate";

const Restaurant = () => {
  
  const history = useHistory();
  const dispatch = useDispatch();
  
  const {currentUser} = useContext(AuthContext);

  
  const restaurantList = useSelector((state) => state.restaurant.restaurantList);
  const totalPages = useSelector((state) => state.restaurant.totalPages);
  const {countries} = useSelector((state) => state.country);
  const subscription = useSelector((state) => state.subscription.subscriptions);
  const updateRestaurantList = useSelector((state) => state.restaurant.updateRestaurant);
  const updateStatus = useSelector((state) => state.restaurant.updateRestaurantStatus);

  const [dataId, setDataId] = useState([]);// Set the initial value based on table data 
  const [showModal, setShowModal] = useState(false);// Set the initial value based on Add modal show 
  const [searchQuery, setSearchQuery] = useState('');// Set the initial value based on Search for Table  
  const [currentPage, setCurrentPage] = useState(0);// Set the initial value based on currentPage for Table 
  const [itemsPerPage] = useState(10);// Set the initial value based on itemsPerPage for Table  
  const [selectedItem, setSelectedItem] = useState(null);// Set the initial value based on selected modal add/edit
  const [restaurantName, setRestaurantName] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [ownerPhone, setOwnerPhone] = useState('');
  const [country, setCountry] = useState(null);
  const [parcels, setParcels] = useState('');
  const [tables, setTables] = useState('');
  const [Subscription, setSubscription] = useState(null);
  const [Address, setAddress] = useState('');  

 // useEffect Use for to get Country and Subscription List Data
  useEffect(() => {
    dispatch(activeCountry(currentUser));
    dispatch(getSubscription(currentUser));
   }, []);

  useEffect(() => {
    dispatch(getRestaurant(currentUser));
    }, []);

  // Handle Edit Function is used for edit the data of retaurant
  const handleEdit = (item) => {
    setSelectedItem(item);
    setDataId(item?._id);
    setRestaurantName(item?.title);
    setOwnerName(item?.user?.username);
    setOwnerEmail(item?.user?.email);
    setOwnerPhone(item?.user?.mobile);
    setCountry(item?.country?._id);
    setParcels(item?.parcels);
    setTables(item?.table);
    setSubscription(item?.subscriptionplans?._id);
    setAddress(item?.addresses?.fullAddress);
    setShowModal(true);
  };
// Handle Save Function is used for Edit the data of retaurant
  const handleSave = () => {
    dispatch(updateRestaurant(dataId,currentUser,restaurantName,ownerName,ownerEmail,ownerPhone,country,parcels,tables,Subscription,Address,setShowModal,currentPage+1));
};

  // Handle delete Function is used for delete the data of retaurant

  const handleUpdateStatus = (item) => {
    const newStatus = item?.status === true ? false : true;
    dispatch(updateRestaurantStatus(item?._id,newStatus,currentUser,currentPage+1));

  };

   // Handle Close Function is used for close the modal of add/edit Restaurant
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };
// Handle Search Function is used for search the data in table
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  // handlePageChange Function is used for Change Page the data in table 
  const handlePageChange = (pageNumber) => {
    let pageNo= pageNumber.selected+1;
    setCurrentPage(pageNumber.selected);
    dispatch(getRestaurant(currentUser,pageNo));
  };
 // logic for indexOfLastItem
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;// logic for indexOfFirstItem
  //logic for filtered data
  // const filteredData = data.filter((item) =>
  //   item.Category.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  // const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div>
      <h2 className="p-3 fw-bold">Restaurants</h2>
      <div className="bg-white">
        <div className="d-flex justify-content-between mb-3 p-4">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Form.Group>
          <Button variant="danger" onClick={() =>    history.push("/superAdmin/restaurant/addrestaurant")}>
            Add Restaurant
          </Button>
        </div>
        <Table striped  hover className="text-center">
          <thead>
            <tr>
              <th>ID</th>
              <th>Restaurants</th>
              <th>Logo</th>
              <th>Branches</th>
              <th>Owner</th>
              <th>Phone</th>
              <th>Country</th>
              <th>Plan</th>
              <th>Sub.Expiry</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {restaurantList?.map((item,index) => (
              <tr key={index}>
                <td>{index+1}</td>
                <td>{item.title}</td>
                <td>{}</td>
                <td>{item?.branchesCount}</td>
                <td>{item?.user?.username}</td>
                <td>{item?.user?.mobile}</td>
                <td>{item?.country?.name}</td>
                <td>{item?.subscriptionplans?.title}</td>
                <td>{item?.subscriptionplans?.timeSpam}</td>
                {/* <td> <Button
          variant="secondary"
          onClick={() => handleUpdateStatus(item)}
        >
          {item.status === true ? 'Active' : 'InActive'}
        </Button> </td> */}
<td>
        {item.status === true ? 
        <Button
          className='btn-sm'
          variant="success"
          onClick={() => handleUpdateStatus(item)}
        >
          {item.status === true ? 'Active' : 'InActive'}
        </Button>
        :
        <Button
        className='btn-sm'
        variant="danger"
        onClick={() => handleUpdateStatus(item)}
      >
        {item.status === true ? 'Active' : 'InActive'}
      </Button>
      }
      </td>

                <td>
                  <Button
                    variant="warning"
                    className="me-3"
                    onClick={() => handleEdit(item)}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <ReactPaginate
        nextLabel=">"
        pageRangeDisplayed={10}
        marginPagesDisplayed={2}
        previousLabel= "<"
        pageClassName="page-item"
        onPageChange={handlePageChange}
        currentPage={currentPage}
        pageCount={totalPages}
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination mx-3 p-2"
        activeClassName="active"
        forcePage={currentPage}
        renderOnZeroPageCount={null}
      />

        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title  className='fw-bold '>
              {selectedItem ? 'Edit Restaurant' : 'Add Restaurant'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold'>Restaurant Name:</Form.Label>
                  <Form.Control
                     type="text"
                     placeholder="Enter Restaurant Name"
                     value={restaurantName}
                     required
                     onChange={(e) => setRestaurantName(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold'>Owner Name:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Owner Name"
                    value={ownerName}
                    required
                    onChange={(e) => setOwnerName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Owner Email:</Form.Label>
                  <Form.Control
                    type="text"
                    value={ownerEmail}
                    required
                    placeholder="Enter Owner Email"
                    onChange={(e) => setOwnerEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Owner Phone:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Owner Phone"
                    value={ownerPhone}
                    required
                    onChange={(e) => setOwnerPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
              <Form.Group>
                  <Form.Label className='fw-bold pt-4'> Country:</Form.Label>
                  <Form.Select aria-label='select'
                    value={country}
                    required
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option selected disabled >Select Country</option>
                    {countries.map(countries =>
                         <option key={countries._id} value={countries._id}>{countries.name}</option> )};
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={6}>
              <Form.Group>
                  <Form.Label className='fw-bold pt-4'> Subscription:</Form.Label>
                  <Form.Select
                  as="select"
                  aria-label='select'
                  value={Subscription}
                  required
                  onChange={(e) => setSubscription(e.target.value)}
                  >
                    <option selected disabled >Select Subscription</option>
                     {subscription.map(subscription =>
                         <option key={subscription._id} value={subscription._id}>{subscription.title}</option> )};
                  </Form.Select>
                </Form.Group>
              </Col>
              {/* <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Parcels:</Form.Label>
                  <Form.Control
                    type="number"
                    value={parcels}
                    placeholder="Enter Parcels"
                    required
                    onChange={(e) => setParcels(e.target.value)}
                  />
                </Form.Group>
              </Col> */}
            </Row>
            {/* <Row>
              {/* <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Tables:</Form.Label>
                  <Form.Control
                    type="number"
                    value={tables}
                    placeholder="Enter Tables"
                    required
                    onChange={(e) => setTables(e.target.value)}
                  />
                </Form.Group>
              </Col> */}
            {/* </Row>  */}
            <Row>
              <Col lg={6}>
                  <Form.Group controlId="category" style={{ marginBottom: '30px' }}>
                                <Form.Label className='fw-bold pt-4'>Image</Form.Label>
                                <Form.Control
                                    type="File"
                                    name="image"
                                    placeholder=' '
                                    // style={{ width: '100%', padding: '5px' }}
                                />
                            </Form.Group>
              </Col>
              <Col lg={6}>
              <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Address:</Form.Label>
                  <Form.Control
                   type='text'
                   placeholder="Enter Address"
                   value={Address}
                   required
                   onChange={(e) => setAddress(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className='mt-4'>
            <Button variant="danger" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="warning" onClick={handleSave}>
              {selectedItem ? 'Save Changes' : 'Add Restaurant'}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Restaurant;
