import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const getDashboard = async (token, branchId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/dashboard?branchId=${branchId?.branchId}`,
    config
  );
  if (response.data.body) {
    return response.data;
  }
};



const dashboardService = {
    getDashboard,
};

export default dashboardService;
