import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import expenseCategoryService from "./ExpenseCategoryService";

const initialState = {
  expenseCategory: [],
  totalPages: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};
// get ExpenseCategory
export const allExpenseCategory = createAsyncThunk(
  "admin/allExpenseCategory",
  async ({branchId,pageNo}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await expenseCategoryService.getAllExpenseCategory(user.accessToken, branchId,pageNo);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//add ExpenseCategory
export const addExpenseCategory = createAsyncThunk(
    "admin/addExpenseCategory",
    async ({ branchId, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await expenseCategoryService.addExpenseCategory(user.accessToken, branchId, data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  //update ExpenseCategory
export const updateExpenseCategory = createAsyncThunk(
    "admin/updateCategories",
    async ({ branchId, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await expenseCategoryService.updateExpenseCategory(user.accessToken,branchId,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  //update ExpenseCategory Status
  export const updateExpenseCategoryStatus = createAsyncThunk(
    "admin/updateExpenseCategoryStatus",
    async ({ branchId, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await expenseCategoryService.updateExpenseCategoryStatus(user.accessToken,branchId,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const allActiveExpenseCategory = createAsyncThunk(
    "admin/allActiveExpenseCategory",
    async (branchId, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await expenseCategoryService.getActiveExpenseCategory(
          user.accessToken,
          branchId
        );
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const expenseCategorySlice = createSlice({
  name: "expenseCategory",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allExpenseCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allExpenseCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.expenseCategory = action.payload?.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(allExpenseCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.expenseCategory = [];
      })
      .addCase(addExpenseCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addExpenseCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.expenseCategory = action.payload.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(addExpenseCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateExpenseCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateExpenseCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.expenseCategory = action.payload;
      })
      .addCase(updateExpenseCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateExpenseCategoryStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateExpenseCategoryStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.expenseCategory = action.payload;
      })
      .addCase(updateExpenseCategoryStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(allActiveExpenseCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allActiveExpenseCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.expenseCategory = action.payload;
      })
      .addCase(allActiveExpenseCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});
export const { reset } = expenseCategorySlice.actions;

export default expenseCategorySlice.reducer;
