import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, Pagination, Form, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import {useDispatch,useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import ReactPaginate from "react-paginate";



import {activeMenuList} from '../../../redux/features/admin/Menu/menuSlice';
import {allMenuItem,addMenuItem,reset,updateMenuItem,updateMenuItemStatus} from '../../../redux/features/admin/MenuItem/menuItemSlice';

const MenuItem = () => {

    const dispatch = useDispatch();
    const {menus} = useSelector((state) => state.menu);
    const {menuItem,isError,isSuccess,message,totalPages} = useSelector((state) => state.menuItem);

  const [data, setData] = useState([]);// Set the initial value based on table data 
  const [showModal, setShowModal] = useState(false);// Set the initial value based on Add modal show 
  const [searchQuery, setSearchQuery] = useState('');// Set the initial value based on Search for Table
  const [currentPage, setCurrentPage] = useState(1);// Set the initial value based on currentPage for Table
  const [itemsPerPage] = useState(10);// Set the initial value based on itemsPerPage for Table
  const [selectedItem, setSelectedItem] = useState(null);// Set the initial value based on selected modal add/edit 
 
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [menuCategory, setMenuCategory] = useState(null);
  const [description, setDescription] = useState('');

   // useEffect is used to get allMenuItem and activeMenuList
   useEffect(() => {
    dispatch(activeMenuList());
    dispatch(allMenuItem());
 }, []);

 useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      setShowModal(false);
    }
    dispatch(reset());
  }, [isError, isSuccess, message,dispatch]);

   // Handle Edit Function is used for edit the data of expensesData
  const handleEdit = (item) => {
    setSelectedItem(item);
    setTitle(item?.title);
    setPrice(item?.price);
    setMenuCategory(item?.menuCategories?._id)
    setDescription(item?.description)
    setShowModal(true);
  };

  // Handle Save Function is used for Edit the data of expensesData
  const handleSave = () => {
    if (selectedItem) {
      // Update existing item
      const data ={
        title:title,
        price:price,
        menuCategoryId:menuCategory,
        description:description,
        _id:selectedItem._id,
        pageNo:currentPage  
      }
      dispatch(updateMenuItem(data));
    } else {
      // Add new item
    const data ={
        title:title,
        price:price,
        menuCategoryId:menuCategory,
        description:description,
        pageNo:currentPage   }
      dispatch(addMenuItem(data));
    }
  };
  const handleUpdateStatus = (item) => {
    const newStatus = item?.isActive == true ? false : true;
    const data ={isActive:newStatus,_id:item._id,pageNo:currentPage}
    dispatch(updateMenuItemStatus(data));
  };
      // Handle Close Function is used for close the modal of add/edit expensesData
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setMenuCategory(null);
    setPrice('');
    setTitle('');
  };

  // Handle Search Function is used for search the data in table
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

    // handlePageChange Function is used for Change Page the data in table 

  const handlePageChange = (pageNumber) => {
    let pageNo=pageNumber.selected+1;
    setCurrentPage(pageNo);
    dispatch(allMenuItem(pageNo));
  };
 // logic for indexOfLastItem
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;// logic for indexOfFirstItem
    //logic for filtered data
  const filteredData = data.filter((item) =>
    item.Category.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div>
      <h2 className="p-3 fw-bold">Menu Items</h2>
      <div className="bg-white">
        <div className="d-flex justify-content-between mb-3 p-4">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Form.Group>
          <Button variant="danger" onClick={() => setShowModal(true)}>
            Add Menu Item
          </Button>
        </div>
        <Table style={{borderRadius: '5px', overflow: 'hidden'}} className="text-center">
          <thead>
            <tr>
              <th>sr#</th>
              <th>Title</th>
              <th>Image</th>
              <th>Price</th>
              <th>MenuCategory</th>
              <th>Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {menuItem?.map((item,index) => (
              <tr key={index}>
                <td>{index+1}</td>
                <td>{item?.title}</td>
                <td>{}</td>
                <td>{item?.price}</td>
                <td>{item?.menuCategories?.title}</td>
                <td>{item?.description}</td>
                <td>{item.isActive === true ? 
        <Button
          className='btn-sm'
          variant="success"
          onClick={() => handleUpdateStatus(item)}
        >
          {item.isActive === true ? 'Active' : 'InActive'}
        </Button>
        :
        <Button
        className='btn-sm'
        variant="danger"
        onClick={() => handleUpdateStatus(item)}
      >
        {item.isActive === true ? 'Active' : 'InActive'}
      </Button>
      }</td>
                <td>
                  <Button
                    variant="warning"
                    className="me-3"
                    onClick={() => handleEdit(item)}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ReactPaginate
        nextLabel=">"
        pageRangeDisplayed={10}
        marginPagesDisplayed={2}
        pageCount={totalPages}
        previousLabel= "<"
        pageClassName="page-item"
        onPageChange={handlePageChange}
        currentPage={currentPage}
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
        <Modal show={showModal} onHide={handleCloseModal} centered size="lg" aria-labelledby="contained-modal-title-vcenter">
          <Modal.Header closeButton>
            <Modal.Title  id="contained-modal-title-vcenter">
              {selectedItem ? 'Edit Item' : 'Add New Item'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
                    <Row>
                        <Col lg={4} sm={6}>
                            <Form.Group controlId="text" style={{ marginBottom: '30px' }}>
                                <Form.Label className='fw-bold'>Title:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="text"
                                    value={title}
                                    placeholder='Grilled Chicken Wings'
                                    style={{ width: '100%', padding: '5px' }}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={4} sm={6}>
                            <Form.Group controlId="price" style={{ marginBottom: '30px' }}>
                                <Form.Label className='fw-bold'> Price:</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="Price"
                                    value={price}
                                    placeholder='12'
                                    style={{ width: '100%', padding: '5px' }}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={4} sm={6}>
                            <Form.Group controlId="category" style={{ marginBottom: '30px' }}>
                                <Form.Label className='fw-bold'>Menu Category</Form.Label>
                                <Form.Select aria-label='select'
                                value={menuCategory}
                                onChange={(e) => setMenuCategory(e.target.value)}
                                >
                    <option selected disabled >Select Menu Category</option>
                    {menus?.map(item =>
                         <option key={item._id} value={item?._id}>{item?.title}</option> )};
                  </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={7} sm={6}>
                            <Form.Group controlId="description" style={{ marginBottom: '30px' }}>
                                <Form.Label className='fw-bold'>Description:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Description"
                                    value={description}
                                    placeholder='Whole BBQ Chicken, choice of Rice or Chips with Coleslaw.'
                                    style={{ width: '100%', padding: '5px' }}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={5} sm={6}>
                            <Form.Group controlId="text" style={{ marginBottom: '30px' }}>
                                <Form.Label className='fw-bold'>Image:</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="file"
                                    // placeholder='Whole BBQ Chicken, choice of Rice or Chips with Coleslaw.'
                                    style={{ width: '100%', padding: '5px' }}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
          </Modal.Body>
          <Modal.Footer className='mt-4'>
            <Button variant="danger" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="warning" onClick={handleSave}>
              {selectedItem ? 'Save Changes' : 'Add Menu Item'}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default MenuItem;