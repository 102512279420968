import {
  legacy_createStore as createStore,
  applyMiddleware,
  combineReducers,
  compose,
} from "redux";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { userSlice } from "./features/auth/createAction";
import { restaurantSlice } from "./features/superAdmin/Restaurants/createAction";
import { subscriptionSlice } from "./features/superAdmin/Subscription/createAction";
import { countrySlice } from "./features/superAdmin/Country/createAction";
import { categorySlice } from "./features/admin/Categories/createAction";
import { menuSlice } from "./features/admin/Menu/menuSlice";
import { branchesSlice } from "./features/admin/Branches/branchesAction";
import { menuItemSlice } from "./features/admin/MenuItem/menuItemSlice";
import { orderSlice } from "./features/admin/Order/orderSlice";
import { customerOrderSlice } from "./features/customer/Order/orderSlice";
import { otpSlice } from "./features/customer/Otp/otpSlice";
import { bankSlice } from "./features/admin/Bank/bankAction";
import { staffSlice } from "./features/admin/Staff/staffSlice";
import { dashboardSlice } from "./features/admin/dashboard/dashboardAction";
import { expenseCategorySlice } from "./features/admin/ExpenseCategory/ExpenseCategorySlice";
import {expensesSlice} from './features/admin/Expenses/ExpensesSlice';
import { kitchenOrderSlice } from "./features/cook/KitchenOrder/kitchenOrderAction";
import { waiterOrderSlice } from "./features/waiter/waiterOrderAction";
import {waiterPayStackSlice} from "./features/waiter/PayStack/waiterPayStackAction";
import {paymentMethodSlice} from "./features/customer/paymentMethod/paymentMethodSlice"
import {WaiterPaymentMethodSlice} from "./features/waiter/paymentMethod/paymentMethodSlice"
import {waiterStripeSlice} from "./features/waiter/Stripe/waiterStripeAction";
import {variantSlice} from "./features/admin/Variant/VariantSlice";
import {variantOptionSlice} from "./features/admin/VariantOption/VariantOptionSlice";
import { userTypeSlice } from "./features/superAdmin/UserType/userTypeAction";
import {allergySlice}  from "./features/admin/Allergy/allergySlice";

const rootReducer = combineReducers({
  user: userSlice.reducer,
  restaurant: restaurantSlice.reducer,
  subscription: subscriptionSlice.reducer,
  country: countrySlice.reducer,
  category: categorySlice.reducer,
  menu: menuSlice.reducer,
  menuItem: menuItemSlice.reducer,
  order: orderSlice.reducer,
  customerOrder: customerOrderSlice.reducer,
  otp: otpSlice.reducer,
  branches: branchesSlice.reducer,
  bank: bankSlice.reducer,
  staff: staffSlice.reducer,
  dashboard: dashboardSlice.reducer,
  kitchenOrder:kitchenOrderSlice.reducer,
  waiterOrder: waiterOrderSlice.reducer,
  expenseCategory:expenseCategorySlice.reducer,
  expenses:expensesSlice.reducer,
  waiterPayStack:waiterPayStackSlice.reducer,
  paymentMethod:paymentMethodSlice.reducer,
  waiterPaymentMethod:WaiterPaymentMethodSlice.reducer,
  waiterStripe:waiterStripeSlice.reducer,
  variant:variantSlice.reducer,
  variantOption:variantOptionSlice.reducer,
  userType:userTypeSlice.reducer,
  allergy:allergySlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const middleware = applyMiddleware(promise, thunk);

let composeEnhancers = compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const STORE = createStore(
  persistedReducer,
  composeEnhancers(middleware)
);
export const PERSISTOR = persistStore(STORE);
