import {createSlice} from '@reduxjs/toolkit';

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
  });
  
  export const restaurantSlice = createSlice({
    name: 'restaurant',
    initialState: {
      status: STATUSES.IDLE,
      error: '',
      restaurantList:[],
      totalPages:null,
      updateRestaurant:null,
      updateRestaurantStatus:null,
 },
    reducers: {
      setStatus: function (state, action) {
        state.status = action.payload.status;
        state.error = action.payload.error;
      },
      setRestaurantList: function (state, action) {
        state.restaurantList =action.payload.restaurantList;
        state.totalPages =action.payload.totalPages;
        state.error = action.payload.error;
      },
      setUpdateRestaurant: function (state, action) {
        state.status = action.payload.status;
        state.updateRestaurant = action.payload.updateRestaurant;
        state.error = action.payload.error;
      },
      setupdateRestaurantStatus: function (state, action) {
        state.status = action.payload.status;
        state.updateRestaurantStatus = action.payload.updateRestaurantStatus;
        state.error = action.payload.error;
      },
      default: (state) => {
        return [...state];
      },
    },
  });
  
  export const {setStatus,setRestaurantList,setUpdateRestaurant,setupdateRestaurantStatus} = restaurantSlice.actions;
  
  export default restaurantSlice.reducer;