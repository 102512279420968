import React from "react";
import { Button, Modal,Col,Row } from "react-bootstrap";

function KitchenOrderModal(props) {

  return (
    <Modal
   centered {...props}
     >
      <Modal.Header closeButton>
        <Modal.Title>Kitchen Order </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Row  className="g-3">
      {props?.orderStatus?.processingOrders?.map(
          (item) => (
            <Col lg={4}>
            <Button
              onClick={() => {
                props.handleTable(item);
              }}
              variant="warning mx-2"
              style={{ width: "auto" }}
            >
              {item?.table?.orderTable}:{item?.customerId?.username}
            </Button>
            </Col>
          )
        )}
</Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={()=> {props.onHide()}}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default KitchenOrderModal;
