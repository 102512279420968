import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ReactPaginate from "react-paginate";

import { Container, Table, Button, Badge } from "react-bootstrap";

import { allOrderByBranchId } from "../../../redux/features/admin/Order/orderSlice";

const OrderTable = (props) => {
  const dispatch = useDispatch();

  const { orders, totalPages } = useSelector((state) => state.order);
  const { selectBranches } = useSelector((state) => state.branches);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(1);

  const handlePageChange = (pageNumber) => {
    let pageNo = pageNumber?.selected + 1;
    setCurrentPage(pageNumber?.selected);
    dispatch(
      allOrderByBranchId({ branchId: selectBranches[0], pageNo: pageNo })
    );
    if (pageNo) {
      setCurrentIndex(pageNo);
    }
  };

  useEffect(() => {
    if (selectBranches.length > 0 && selectBranches[0] !== undefined) {
      handlePageChange();
    }
  }, [selectBranches]);

  return (
    <Container>
      {/* <Button variant="warning" className="float-end my-4">Add Orders</Button> */}
      <div className="d-flex justify-content-between text-center">
        <h1 className="fw-bold my-4"> {props.title} </h1>
        <Link to="/admin/order">
          <Button
            variant="warning"
            style={{ height: "40px", marginTop: "25px" }}
          >
            {" "}
            New Order
          </Button>
        </Link>
      </div>
      <div style={{ overflow: "auto", overflowX: "scroll" }}>
        <Table
          style={{ borderRadius: "5px", overflow: "hidden" }}
          className="bg-white text-center"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Table</th>
              <th>Customer</th>
              <th>Total</th>
              <th>Discount</th>
              <th>Payment Status</th>
              <th>Date</th>
              <th>Status</th>
              {/* <th>Served By</th>
              <th>Action</th> */}
            </tr>
          </thead>

          <tbody>
            {orders?.map((val, ind) => {
              const adjustedIndex = (currentIndex - 1) * 10 + ind + 1;
              return (
                <tr>
                  <td className="text-center fw-bold">{adjustedIndex}</td>
                  <td>{val?.table?.orderTable}</td>
                  <td>{val?.customer?.username}</td>
                  {/* <td>
                    <Button variant="danger" className="btn-md px-3">
                      Invoice
                    </Button>
                  </td> */}
                  <td>{val?.totalAmount}</td>
                  <td>{val?.discount ? val?.discount : 0}</td>
                  <td>
                    {val?.cashReceived !== undefined &&
                    val?.cashReceived !== 0 ? (
                      <Badge bg="success">Paid</Badge>
                    ) : (
                      <Badge bg="danger">Not Paid</Badge>
                    )}
                  </td>
                  <td>
                    {moment(val?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                  </td>
                  <td>
                    {" "}
                    {val?.status === "complete" ? (
                      <Badge bg="success">Complete</Badge>
                    ) : val?.status === "processing" ? (
                      <Badge bg="danger">Processing</Badge>
                    ) : val?.status === "dining" ? (
                      <Badge bg="warning">Dining</Badge>
                    ) : val?.status === "sendToComplete" ? (
                      <Badge bg="secondary">SendToComplete</Badge>
                    ) : (
                      <Badge bg="warning">{val?.status}</Badge>
                    )}
                  </td>
                  <td>{val.ServedData}</td>
                  {/* <td>
                    <div className="d-grid text-center">
                      <Button variant="warning" className="btn-sm mb-2 p-0">
                        Print Invoice
                      </Button>
                      <Button variant="danger" className="btn-sm p-0">
                        Delete Invoice
                      </Button>
                    </div>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ReactPaginate
          nextLabel=">"
          pageRangeDisplayed={10}
          marginPagesDisplayed={2}
          previousLabel="<"
          pageClassName="page-item"
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pageCount={totalPages}
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          forcePage={currentPage}
          renderOnZeroPageCount={null}
        />
      </div>
    </Container>
  );
};

export default OrderTable;
