import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Card,
  Collapse,
  ListGroup,
  Form,
  Row,
} from "react-bootstrap";
import Asun from "../../components/Images/Menu-Items/Asun.png";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";

function ItemCard({ menucategories, handleAddCard, currency, color }) {
  const [open, setOpen] = useState(false);
  const [variantOption, setVariantOption] = useState([]);
  const [variantOptionData, setVariantOptionData] = useState([]);
  // const [selectedOption, setSelectedOption] = useState(null);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [accordionSelectedOptions, setAccordionSelectedOptions] = useState([]);

  const [buttonColor, setButtonColor] = useState("warning");
  const [buttonText, setButtonText] = useState("plus");
  const handleButtonClick = () => {
    setOpen(!open);
    if (buttonColor === "warning") {
      setButtonColor("danger");
      setButtonText("minus");
    } else {
      setButtonColor("warning");
      setButtonText("plus");
    }
  };

  const handleVariantOption = (option) => {
    // setSelectedOption(option.variantOption._id);
    const isSelect = selectedOptions.some(
      (selected) => selected._id === option._id
    );
    if (!isSelect) {
      setSelectedOptions((prevOptions) => [...prevOptions, option]);
    } else {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((selected) => selected._id !== option._id)
      );
    }
    //   if(option?.variant.isMutiSelection){
    //   const foundIndex = variantOption.findIndex(
    //     (element) => element._id === option.variantOption._id
    //   );
    //   if (foundIndex === -1) {
    //     const newOption = {
    //       _id: option.variantOption._id,
    //       title: option.variantOption.title,
    //       price: option.price,
    //       quantity: 1,
    //     };
    //     variantOption.push(newOption);
    //   } else {
    //     variantOption.splice(foundIndex, 1);
    //   }
    //  }
    //  else{
    //  }

    const selectedOptionsForAccordion =
      accordionSelectedOptions[option.variantId] || [];
    const isSelected = selectedOptionsForAccordion.some(
      (selected) => selected._id === option._id
    );

    if (!isSelected) {
      // Add the selected option to the array for the specific accordion
      setAccordionSelectedOptions((prevOptions) => ({
        ...prevOptions,
        [option.variantId]: option.variant.isMutiSelection
          ? [...selectedOptionsForAccordion, option]
          : [option],
      }));
    } else {
      // Remove the selected option from the array for the specific accordion
      setAccordionSelectedOptions((prevOptions) => ({
        ...prevOptions,
        [option.variantId]: selectedOptionsForAccordion.filter(
          (selected) => selected._id !== option._id
        ),
      }));
    }
  };
  const uniqueVariantTitles = [
    ...new Set(
      menucategories?.menuVariants
        ?.filter((i) => i?.variant?.isActive)
        .map((i) => i?.variant?.title)
    ),
  ];

  return (
    <Col lg={6} className="my-3">
      <Card className="rounded-4 h-100">
        <Card.Body>
          <div className="d-flex">
            <Card.Img
              src={Asun}
              alt="dimsins"
              className=""
              style={{ width: "60px", height: "60px" }}
            />
            <div className="d-grid">
              <Card.Text className="px-2 text-danger fw-bold mb-0">
                {menucategories?.title}
              </Card.Text>
              <Card.Text
                className=" fw-bold mb-0"
                style={{ marginLeft: " 10px" }}
              >
                {currency} {menucategories?.price}
              </Card.Text>
              <Card.Text className="px-2 mb-0">
                {menucategories?.description}
              </Card.Text>
            </div>
          </div>

          {menucategories?.menuVariants?.length != 0 ||
          menucategories?.menuallergies?.length != 0 ? (
            <div className="mx-4 mt-2 float-end">
              <Button
                onClick={handleButtonClick}
                variant={buttonColor}
                aria-controls="example-collapse-text"
                aria-expanded={open}
              >
                {buttonColor === "warning" ? (
                  <FontAwesomeIcon icon={faPlus} />
                ) : (
                  <FontAwesomeIcon icon={faMinus} />
                )}
              </Button>
            </div>
          ) : (
            <div className="mx-2 my-2 text-end">
              <Button
                // variant={color ? "danger" : "warning"}
                variant={"warning"}
                onClick={() =>
                  handleAddCard(
                    menucategories,
                    color,
                    variantOption,
                    setSelectedOptions,
                    setVariantOption,
                    setOpen,
                    setButtonText,
                    setButtonColor,
                    accordionSelectedOptions,
                    setAccordionSelectedOptions
                  )
                }
              >
                {/* {color ? "Added" : "Add to Basket"} */}
                Add to Basket
              </Button>
            </div>
          )}

          {/* <div className=" mx-4 mt-2 float-end">
            <Button
              className=""
              //  variant="warning"
              variant={color ? "danger" : "warning"}
              onClick={() => handleAddCard(menucategories, color)}
            >
              {color ? "Added" : "Add to Basket"}
              {/* Add to Basket */}
          {/* </Button> */}
          {/* </div> */}
        </Card.Body>
        <Collapse in={open}>
          <div id="example-collapse-text">
            <div>
              {uniqueVariantTitles.map((variantTitle, index) => {
                // Find the first menuVariant with the matching variant title
                const menuVariant = menucategories.menuVariants.find(
                  (i) => i.variant.title === variantTitle
                );
                return (
                  <Row>
                    <Col lg={12}>
                      <Accordion
                        key={index}
                        defaultActiveKey="0"
                        style={{
                          width: "80%",
                          paddingBottom: "10px",
                          marginLeft: "45px",
                        }}
                      >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>{variantTitle}</Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <ListGroup>
                                {menucategories.menuVariants
                                  .filter(
                                    (option) =>
                                      option.variantId ===
                                      menuVariant.variant._id
                                  )
                                  .map((option) => (
                                    <ListGroup.Item key={option._id}>
                                      <div className="d-flex justify-content-between">
                                        <div>
                                          <b>
                                            {option?.variantOption?.title
                                              .charAt(0)
                                              ?.toUpperCase() +
                                              option?.variantOption?.title?.slice(
                                                1
                                              )}
                                          </b>{" "}
                                          <b>
                                            (+{currency}
                                            {option.price})
                                          </b>
                                        </div>
                                        <div className="text-end">
                                          {option?.variant.isMutiSelection ? (
                                            <Form.Check
                                              type={"checkbox"}
                                              // checked={
                                              //   selectedOption ===
                                              //   option?.variantOption?._id
                                              // }
                                              checked={selectedOptions.some(
                                                (selected) =>
                                                  selected._id === option._id
                                              )}
                                              onChange={() =>
                                                handleVariantOption(option)
                                              }
                                            />
                                          ) : (
                                            <Form.Check
                                              type={"radio"}
                                              reverse
                                              checked={selectedOptions.some(
                                                (selected) =>
                                                  selected._id === option._id
                                              )}
                                              name={`variantRadioGroup-${variantTitle}`}
                                              onChange={() =>
                                                handleVariantOption(option)
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </ListGroup.Item>
                                  ))}
                              </ListGroup>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                  </Row>
                );
              })}
            </div>
            {menucategories?.menuallergies?.length != 0 ? (
              <b className="mx-5 my-5 text-danger">Allergens</b>
            ) : (
              ""
            )}
            <p>
              <b className="mx-5 my-2">
                {menucategories?.menuallergies
                  ?.filter((i) => i?.allergies?.isActive)
                  .map((i, index) => (
                    <React.Fragment key={index}>
                      {i?.allergies?.title?.charAt(0)?.toUpperCase() +
                        i?.allergies?.title?.slice(1)}
                      {index < menucategories?.menuallergies.length - 1 && " "}
                    </React.Fragment>
                  ))}
              </b>
            </p>
            <div className="mx-2 my-2 text-end">
              <Button
                // variant={color ? "danger" : "warning"}
                variant={"warning"}
                onClick={() =>
                  handleAddCard(
                    menucategories,
                    color,
                    variantOption,
                    setSelectedOptions,
                    setVariantOption,
                    setOpen,
                    setButtonText,
                    setButtonColor,
                    accordionSelectedOptions,
                    setAccordionSelectedOptions
                  )
                }
              >
                {/* {color ? "Added" : "Add to Basket"} */}
                Add to Basket
              </Button>
            </div>
          </div>
        </Collapse>
      </Card>
    </Col>
  );
}
export default ItemCard;
