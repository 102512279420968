import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Col, Row, Table, Card } from "react-bootstrap";
import { toast } from "react-toastify";

import CardInformation from "./CardInformation";
import PaymentModal from "./PaymentModal";
import CashConfirmationModal from "./CashConfirmationModal";
import StripePaymentModal from "./StripePaymentModal";

import { payStack } from "../../../redux/features/waiter/PayStack/waiterPayStackAction";

import { reset } from "../../../redux/features/waiter/waiterOrderAction";

import { useDispatch, useSelector } from "react-redux";

function CompleteOrderModal(props) {
  const { waiterOrders, isError, isSuccess, message } = useSelector(
    (state) => state.waiterOrder
  );
  const { selectBranches } = useSelector((state) => state.branches);
  const { paymentMethod } = useSelector((state) => state.paymentMethod);

  console.log(
    "pppp --------------------->",
    paymentMethod?.paymentaccount?.type
  );

  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showCashModal, setShowCashModal] = useState(false);
  const [showStripePaymentModal, setShowStripePaymentModal] = useState(false);
  const dispatch = useDispatch();
  const [selectedMethod, setSelectedMethod] = useState(null);

  useEffect(() => {
    if (isError) {
      // toast.error(message);
    }
    if (isSuccess) {
      setShowModal(false);
      setShowCashModal(false);
      setSelectedMethod(null);
      props.onHide();
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  const handleButtonClick = (method) => {
    setSelectedMethod(method);
    if (method === "Cash") {
      //    const data = {
      //   _id: props?.orderInformation?._id,
      //   status: "complete",
      //   branchId: selectBranches[0],
      // };
      // dispatch(updateOrder(data));
      // props.onHide();
      // setSelectedMethod(null);
      setShowCashModal(true);
    }
    if (method === "Card") {
      setShowModal(true);
      // props.onHide();
      // setSelectedMethod(null);
    }
    if (method === "Online Payment") {
      setShowPaymentModal(true);
      props.onHide();
      setSelectedMethod(null);
      let data = {
        email: props?.orderInformation?.customer.email,
        amount: props?.orderInformation?.totalAmount * 100,
        subaccount: "ACCT_ra2fiuew6134giq",
      };
      dispatch(payStack(data));
    }

    if (method === "Online PaymentStripe") {
      setShowStripePaymentModal(true);
      props.onHide();
      setSelectedMethod(null);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        style={{ maxHeight: "80vh", overflowY: "auto" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Payment Method
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center gap-2">
            <Button
              variant={selectedMethod === "Cash" ? "danger" : "warning"}
              onClick={() => handleButtonClick("Cash")}
            >
              Cash
            </Button>
            <Button
              variant={selectedMethod === "Card" ? "danger" : "warning"}
              onClick={() => handleButtonClick("Card")}
            >
              Card
            </Button>
            {paymentMethod?.paymentaccount?.type == "payStack" ? (
              <Button
                variant={
                  selectedMethod === "Online Payment" ? "danger" : "warning"
                }
                onClick={() => handleButtonClick("Online Payment")}
              >
                Online Payment
              </Button>
            ) : (
              <Button
                variant={
                  selectedMethod === "Online PaymentStripe"
                    ? "danger"
                    : "warning"
                }
                onClick={() => handleButtonClick("Online PaymentStripe")}
              >
                Online Payment
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <CardInformation
        onHide={() => setShowModal(false)}
        show={showModal}
        orderInformation={props.orderInformation}
        setSelectedMethod={setSelectedMethod}
      ></CardInformation>
      <PaymentModal
        onHide={() => setShowPaymentModal(false)}
        show={showPaymentModal}
        orderInformation={props.orderInformation}
      ></PaymentModal>
      <CashConfirmationModal
        onHide={() => setShowCashModal(false)}
        show={showCashModal}
        orderInformation={props.orderInformation}
        setSelectedMethod={setSelectedMethod}
      ></CashConfirmationModal>
      <StripePaymentModal
        onHide={() => setShowStripePaymentModal(false)}
        show={showStripePaymentModal}
        orderInformation={props.orderInformation}
      ></StripePaymentModal>
    </>
  );
}

export default CompleteOrderModal;
