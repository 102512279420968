import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Table,
  Form,
  Tabs,
  Tab,
  Nav,
  Button,
} from "react-bootstrap";

import { useSelector, useDispatch } from "react-redux";
import { FaTimes } from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";

import { activeMenuList } from "../../../redux/features/admin/Menu/menuSlice";
import { activeCategoriesMenu } from "../../../redux/features/admin/Categories/createAction";
import {
  getOrderStatus,
  addOrderItem,
} from "../../../redux/features/admin/Order/orderSlice";
import { useParams } from "react-router-dom";
import VariantModal from "./VariantModal";

import grillItem_1 from "../../../components/Images/Menu-Items/Asun.png";
import Asun from "../../../components/Images/Menu-Items/Asun.png";
import Prawns from "../../../components/Images/Menu-Items/Sautted-Prawns.jpg";
import GrilledSalad from "../../../components/Images/Menu-Items/Grilled-Halloumi-Salad.jpg";
import ToyoSuya from "../../../components/Images/Menu-Items/Tozo-Suya.jpg";
import { toast } from "react-toastify";

const NewOrderMenuCard = ({
  discountType,
  discountValue,
  setDiscountType,
  setDiscountValue,
  cashReceived,
  setCashReceived,
  cashChange,
  setCashChange,
  totalAmount,
  setTotalAmount,
  product,
  setProduct,
  tableId,
  setId,
  setOrderInformation,
  setTable,
  orderId,
  setOrderId,
}) => {
  const dispatch = useDispatch();
  const { selectBranches } = useSelector((state) => state.branches);
  const { menus } = useSelector((state) => state.menu);
  const { categories } = useSelector((state) => state.category);
  const { menuItem } = useSelector((state) => state.menuItem);
  const { order, isSuccessOrder } = useSelector((state) => state.order);
  const [items, setItems] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  // const { tableId } = useParams();
  const [menuId, setMenuId] = useState("");
  const [addMenu, setAddMenu] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [showVariantModal, setShowVariantModal] = useState(false);
  const [menuVariantData, setMenuVariantData] = useState({});

  useEffect(() => {
    setTable("Select Table");
    if (selectBranches.length > 0 && selectBranches[0] !== undefined) {
      dispatch(activeMenuList());
      dispatch(getOrderStatus({ branchId: selectBranches[0], tableId: "" }));
      dispatch(activeCategoriesMenu(selectBranches[0]));
    }
    // dispatch(activeMenuItemList());
  }, [selectBranches]);

  useEffect(() => {
    let item = menuItem?.map((item) => ({
      ...item,
      quantity: 0,
    }));
    setItems(item);
  }, [menuItem]);

  useEffect(() => {
    dispatch(activeMenuList({ id: categoryId, branchId: selectBranches[0] }));
  }, [categoryId, selectBranches]);
  useEffect(() => {
    setCashChange(order?.cashChange ? order?.cashChange : 0);
    setCashReceived(order?.cashReceived ? order?.cashReceived : 0);
    setOrderInformation(order);
    setOrderId(order?._id);
    // if (order?.tableId !== undefined) {
    //   setTable(order?.tableId);
    // }
  }, [order]);

  // useState(() => {
  //   setTable("Select Table");
  // }, [order, selectBranches]);

  useEffect(() => {
    if (categories) {
      setActiveKey(categories[0]?._id);
    }
  }, [categories]);

  useEffect(() => {
    calculateChange();
  }, [totalAmount, cashReceived, order]);

  useEffect(() => {
    calculateTotal();
    setId(order._id);
    // setTable(order._id);
  }, [discountType, discountValue, order]);

  const handleIncrement = (id) => {
    //     let updatedItems = [...items];
    //     updatedItems[index].quantity += 1;
    //     setItems(updatedItems);

    //  const found = product.find((element) => element._id ===data._id);
    //      if (found){
    //         Object.assign(product);
    //       }else{
    //         product.push(data);
    //       }
    //       calculateTotal();

    let data = {
      orderId: id,
      type: "increment",
      tableId: tableId,
      branchId: selectBranches[0],
    };
    dispatch(addOrderItem(data));
  };

  const handleDecrement = (item) => {
    // const updatedItems = [...items];
    // if (updatedItems[index].quantity > 0) {
    //     updatedItems[index].quantity -= 1;
    //     setItems(updatedItems);
    // }
    // const indexProduct  = product.findIndex((element) => element._id ===data._id);
    // if (indexProduct !== -1){
    //     product.splice(indexProduct, 1);
    //  }
    //  calculateTotal();
    if (item?.quantity != 1) {
      let data = {
        orderId: item?._id,
        type: "decrement",
        // orderId: orderId,
        tableId: tableId,
        branchId: selectBranches[0],
      };
      dispatch(addOrderItem(data));
    } else {
      let data = {
        orderId: item?._id,
        type: "delete",
        // orderId: orderId,
        tableId: tableId,
        branchId: selectBranches[0],
      };
      dispatch(addOrderItem(data));
    }
  };

  const handleIncrementVariantOption = (option, item) => {
    let data = {
      orderId: item?._id,
      type: "incrementVariantOption",
      tableId: tableId,
      branchId: selectBranches[0],
      variantOptionName: option?.title,
      updateItemPrice: option?.price + item?.itemPrice,
    };
    dispatch(addOrderItem(data));
  };
  const handleDecrementVariantOption = (option, item) => {
    if (option?.quantity != 1) {
      let data = {
        orderId: item?._id,
        type: "decrementVariantOption",
        tableId: tableId,
        branchId: selectBranches[0],
        variantOptionName: option?.title,
        updateItemPrice: item?.itemPrice - option?.price,
      };
      dispatch(addOrderItem(data));
    } else {
      let data = {
        orderId: item?._id,
        type: "deleteVariantOption",
        tableId: tableId,
        branchId: selectBranches[0],
        variantOptionName: option?.title,
        updateItemPrice: item?.itemPrice - option?.price,
      };
      dispatch(addOrderItem(data));
    }
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    items?.forEach((item) => {
      subtotal += item.price * item.quantity;
    });
    return subtotal;
  };

  const calculateTotal = () => {
    const subtotal = order?.totalPayment ? order?.totalPayment : 0;
    const tax = 0;
    const serviceCharges = 0;
    let discount = 0;

    if (discountType === "flat") {
      discount = discountValue;
    } else if (discountType === "percentage") {
      discount = (subtotal * discountValue) / 100;
    }
    const total = subtotal + (subtotal * tax) / 100 + serviceCharges - discount;
    setTotalAmount(total);
  };

  const handleDiscountTypeChange = (e) => {
    setDiscountType(e.target.value);
  };

  const handleDiscountValueChange = (e) => {
    const enteredValue = Number(e.target.value);

    if (discountType === "percentage") {
      // If the discount type is "percentage", limit the entered value between 0 and 100
      setDiscountValue(
        enteredValue >= 0 && enteredValue <= 100 ? enteredValue : 0
      );
    } else {
      // If it's not a percentage, limit the entered value between 0 and totalAmount
      setDiscountValue(
        enteredValue >= 0 && enteredValue <= totalAmount ? enteredValue : 0
      );
    }
  };

  const handleCashReceivedChange = (e) => {
    const enteredValue = Number(e.target.value);
    if (enteredValue >= 0) {
      setCashReceived(enteredValue);
    } else {
      setCashReceived("");
    }
  };

  const calculateChange = () => {
    const total = cashReceived - totalAmount;
    setCashChange(total);
  };
  const tabStyle = {
    color: "black",
    // backgroundColor: '#ffc107',
    // padding: '10px',
    // borderRadius: '10px',
  };
  const handleNavLinkClick = (itemId) => {
    // Your custom logic here when Nav.Link is clicked
    setCategoryId(itemId);
    setActiveKey(itemId);
  };

  const handleTabSelect = (dataItem) => {
    // Handle tab selection here, e.g., perform some action
    // const foundIndex = addMenu.findIndex((element) => element._id ===data._id);
    // if (foundIndex !== -1){
    //     addMenu[foundIndex].quantity += 1;
    //  }else{
    //     let item={
    //         title:data?.title,
    //         price:data?.price,
    //         _id:data?._id,
    //         quantity:1,
    //     }
    //     addMenu.push(item);
    //  }
    //  console.log("dataaaaaaa.",addMenu)
    // if (tableId) {
    if (tableId !== "Select Table") {
      if (
        dataItem?.menuvariants.length != 0 ||
        dataItem?.menuallergies.length != 0
      ) {
        setMenuVariantData(dataItem);
        setShowVariantModal(true);
      } else {
        let data = {
          orderId: order?._id ? order?._id : "",
          menuCategoriesId: dataItem._id,
          quantity: 1,
          itemPrice: dataItem.price,
          tableId: tableId,
          branchId: selectBranches[0],
          variantOption: dataItem?.menuvariants ? dataItem?.menuvariants : [],
        };
        dispatch(addOrderItem(data));
      }
    } else {
      toast.error("Please Select Table");
    }
    // }
  };

  const handleAddOrderItem = (
    accordionSelectedOptions,
    dataItem,
    setAccordionSelectedOptions,
    setSelectedOptions
  ) => {
    const transformedArray = [].concat(
      ...Object.values(accordionSelectedOptions).map((array) =>
        array.map((option) => ({
          _id: option.variantOption._id,
          title: option.variantOption.title,
          price: option.price,
          quantity: 1,
        }))
      )
    );

    const variantOptionPrice = transformedArray.reduce(
      (total, option) => total + option.price,
      0
    );
    let data = {
      orderId: order?._id ? order?._id : "",
      menuCategoriesId: dataItem._id,
      quantity: 1,
      itemPrice: dataItem.price + variantOptionPrice,
      tableId: tableId,
      branchId: selectBranches[0],
      variantOption: transformedArray,
    };
    dispatch(addOrderItem(data));
    setShowVariantModal(false);
    setAccordionSelectedOptions([]);
    setSelectedOptions([]);
  };

  useEffect(() => {
    if (isSuccessOrder) {
      setOrderId(order?._id);
    }
  }, [isSuccessOrder]);

  const handleDeleteMenu = (id) => {
    let data = {
      orderId: id,
      type: "delete",
      tableId: tableId,
      branchId: selectBranches[0],
    };
    dispatch(addOrderItem(data));
  };

  const tabHoverStyle = {
    backgroundColor: "#ffca38",
    padding: "10px",
    borderRadius: "10px",
  };
  const itemHoverStyles = {
    backgroundColor: "black",
    Color: "white",
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleTabHover = () => {
    setIsHovered(true);
  };

  const handleTabLeave = () => {
    setIsHovered(false);
  };

  return (
    <div>
      <Row style={{ display: "flex" }}>
        <Col lg={4}>
          <Tab.Container id="left-tabs-example" defaultActiveKey={activeKey}>
            <Row className="my-5">
              <Col
                sm={4}
                className="overflow-auto"
                style={{ maxHeight: "600px" }}
              >
                <Nav
                  variant="tabs"
                  className="flex-column active"
                  Style={itemHoverStyles}
                >
                  <Nav.Item>
                    {categories?.map((item) => (
                      <Nav.Link
                        onClick={() => handleNavLinkClick(item._id)}
                        eventKey={item?._id}
                        key={item?._id}
                        className={`text-dark my-2 ${
                          activeKey === item._id ? "active" : ""
                        }`}
                      >
                        {item?.title}{" "}
                      </Nav.Link>
                    ))}
                  </Nav.Item>
                </Nav>
              </Col>
              <Col
                sm={8}
                className="overflow-auto"
                style={{ maxHeight: "600px" }}
              >
                <Tab.Content>
                  {menus?.map((item) => (
                    <Tab.Pane
                      className="bg-warning p-3 rounded-2 mb-3"
                      eventKey={item?._id}
                      active={activeKey === item?.categories}
                      onClick={() => handleTabSelect(item)}
                    >
                      <Card className="rounded-4">
                        <Card.Body className="d-flex">
                          <Card.Img
                            src={Asun}
                            alt="dimsins"
                            className=""
                            style={{ width: "40px", height: "40px" }}
                          />
                          <div className="d-grid">
                            <Card.Text className="px-2 text-danger fw-bold">
                              {item?.title}
                            </Card.Text>
                            <Card.Text className="px-1">
                              Price:{item?.price}
                            </Card.Text>
                          </div>
                        </Card.Body>
                      </Card>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
        <Col lg={5}>
          <div style={{ overflow: "auto", overflowX: "scroll" }}>
            <Table striped bordered className="mt-5">
              <thead>
                <tr>
                  <th>Del</th>
                  <th>Items</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {order?.orderItemList?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Button
                        variant="link"
                        onClick={() => handleDeleteMenu(item?._id)}
                      >
                        <FaTimes className="text-white bg-danger fa-lg p-1 rounded-1" />
                      </Button>
                    </td>
                    <td>
                      {item?.menuCategories?.title}
                      <div>
                        {item?.variantOption?.map((option, index) => (
                          <div
                            key={index}
                            className="d-flex justify-content-between"
                          >
                            {option?.quantity}x
                            {option?.title?.charAt(0)?.toUpperCase() +
                              option?.title?.slice(1)}
                            <div className="text-right">
                              <Button
                                variant="link"
                                className="bg-dark btn-sm text-white text-decoration-none rounded-circle mx-1 px-2 py-0"
                                onClick={() =>
                                  handleDecrementVariantOption(option, item)
                                }
                              >
                                -
                              </Button>
                              {option?.quantity}
                              <Button
                                variant="link"
                                className="bg-dark text-white btn-sm text-decoration-none rounded-circle mx-2 px-2 py-0"
                                onClick={() =>
                                  handleIncrementVariantOption(option, item)
                                }
                              >
                                +
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </td>

                    <td>
                      <input
                        style={{ width: "80px" }}
                        disabled
                        type="number"
                        className="text-center"
                        value={item?.itemPrice}
                      />
                    </td>
                    <td>
                      <Button
                        style={{ margin: "0px 5px" }}
                        onClick={() => handleDecrement(item)}
                        variant="link"
                        className="bg-danger btn-sm text-white text-decoration-none"
                      >
                        -
                      </Button>

                      {item?.quantity}

                      <Button
                        style={{ margin: "0px 5px" }}
                        onClick={() => handleIncrement(item?._id)}
                        variant="link"
                        className="bg-warning text-white btn-sm text-decoration-none"
                      >
                        +
                      </Button>
                    </td>
                    <td className="text-center">
                      {item?.itemPrice * item?.quantity}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
        <Col lg={3} className="text-right float-end my-5">
          <Table striped bordered className="my-0">
            <tbody>
              <tr>
                <td>Subtotal</td>
                <td>{order?.totalPayment}</td>
              </tr>
              {/* <tr>
                <td>Tax (0%)</td>
                <td>0</td>
              </tr> */}
              {/* <tr>
                <td>Service Charges</td>
                <td></td>
              </tr> */}
              <tr>
                <td>Discount Type</td>
                <td>
                  <Form.Check
                    inline
                    type="radio"
                    label="Flat"
                    value="flat"
                    checked={discountType === "flat"}
                    onChange={handleDiscountTypeChange}
                    disabled={tableId === "Select Table"}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Percentage"
                    value="percentage"
                    checked={discountType === "percentage"}
                    onChange={handleDiscountTypeChange}
                    disabled={tableId === "Select Table"}
                  />
                </td>
              </tr>
              <tr>
                <td>Discount</td>
                <td>
                  <Form.Control
                    type="number"
                    value={discountValue}
                    onChange={handleDiscountValueChange}
                    disabled={tableId === "Select Table"}
                  />
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td>{totalAmount}</td>
              </tr>
              <tr>
                <td>Cash Received</td>
                <td>
                  <Form.Control
                    type="number"
                    value={cashReceived}
                    onChange={handleCashReceivedChange}
                    disabled={tableId === "Select Table"}
                  />
                </td>
              </tr>
              <tr>
                <td>Change</td>
                <td>{cashChange}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <VariantModal
        onHide={() => setShowVariantModal(false)}
        show={showVariantModal}
        menuVariantData={menuVariantData}
        handleAddOrderItem={handleAddOrderItem}
      />
    </div>
  );
};

export default NewOrderMenuCard;
