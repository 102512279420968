import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const addVariantOption = async (token, branchId, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/admin/variantOption/add?branchId=${branchId}&page=${data?.pageNo}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Added Variant Option");
    return response.data;
  }
};

const getAllVariantOption = async (token, branchId,pageNo) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/variantOption?branchId=${branchId}&page=${pageNo}`,
      config
    );
    if (response.data.body) {
      return response.data;
    }
  };

  const updateVariantOption = async (token,branchId,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/admin/variantOption/${data._id}?branchId=${branchId}&page=${data?.pageNo}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Successfully Updated Variant Option");
      return response.data.body;
    }
  };

  const updateVariantOptionStatus = async (token,branchId,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/admin/variantOption/status/${data._id}?branchId=${branchId}&page=${data?.pageNo}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Successfully Updated Variant Option Status");
      return response.data.body;
    }
  };
  const getActiveVariantOption = async (token,branchId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/variantOption/active?branchId=${branchId}`,
      config
    );
    if (response.data.body) {
      return response.data.body;
    }
  };
const variantOptionService = {
    addVariantOption,
    getAllVariantOption,
    updateVariantOption,
    updateVariantOptionStatus,
    getActiveVariantOption
};

export default variantOptionService;
