import React, { useEffect, useState } from "react";
import { Card, Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping, faUser } from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";

import {
  allOrder,
  getComingOrder,
  updateOrder,
} from "../../../redux/features/waiter/waiterOrderAction";
import { getPaymentMethod } from "../../../redux/features/waiter/paymentMethod/paymentMethodSlice";

import GeneralIndicator from "../../model/GeneralIndicator";

import CompleteOrderModal from "./CompleteOrderModal";

const Order = () => {
  const dispatch = useDispatch();

  const { selectBranches } = useSelector((state) => state.branches);

  const { waiterOrders, isLoading } = useSelector((state) => state.waiterOrder);

  const [showModal, setShowModal] = useState(false);
  const [orderInformation, setOrderInformation] = useState({});

  useEffect(() => {
    dispatch(allOrder(selectBranches[0]));
    dispatch(getPaymentMethod(selectBranches[0]));
  }, [selectBranches]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getComingOrder(selectBranches[0]));
    }, 5000);
    return () => clearInterval(interval);
  }, [selectBranches]);

  const handleSendToComplete = (item) => {
    const data = {
      _id: item?._id,
      status: "sendToComplete",
      branchId: selectBranches[0],
    };
    dispatch(updateOrder(data));
  };

  const handleComplete = (item) => {
    setOrderInformation(item);
    // const data = {
    //   _id: item?._id,
    //   status: "complete",
    //   branchId: selectBranches[0],
    // };
    // dispatch(updateOrder(data));
    setShowModal(true);
  };

  return (
    <>
      {isLoading ? <GeneralIndicator /> : null}
      <Row>
        {waiterOrders?.order
          ?.slice()
          ?.reverse()
          ?.map((item, index) => (
            <Col lg={4} sm={12}>
              <Card className="my-3 p-2">
                <Card.Body>
                  <div className="d-flex justify-content-between text-center fw-bolder h5">
                    <Card.Text>
                      {" "}
                      <FontAwesomeIcon icon={faBagShopping} /> Table #.{" "}
                      {item?.ttable?.orderTable}
                    </Card.Text>
                    <Card.Text>Name: {item?.customer?.username}</Card.Text>
                  </div>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Order Item</Accordion.Header>
                      <Accordion.Body>
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Item Name</th>
                                <th>Quantity</th>
                                <th>Item Price</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.orderitem?.map((item, index) => (
                                <tr>
                                  <td>
                                    {item?.menucategoeies?.title}
                                    <div>
                                      {item?.variantOption?.map(
                                        (option, index) => (
                                          <div key={index}>
                                            <b>
                                              {option?.quantity}x
                                              {option?.title
                                                ?.charAt(0)
                                                ?.toUpperCase() +
                                                option?.title?.slice(1)}
                                            </b>{" "}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </td>
                                  <td>{item?.quantity}</td>
                                  <td>{item?.itemPrice}</td>
                                  <td>{item?.quantity * item?.itemPrice}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <hr />
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12 ">
                        {/* <Button
                          className="me-3"
                          variant={
                            item?.status == "sendToComplete"
                              ? "danger"
                              : "warning"
                          }
                          onClick={() => handleSendToComplete(item)}
                        >
                          {item?.status == "sendToComplete"
                            ? "Send"
                            : "  Send to Complete"}
                        </Button> */}
                        <Button
                          variant="success"
                          className="me-3"
                          onClick={() => handleComplete(item)}
                        >
                          Complete
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>

      <CompleteOrderModal
        onHide={() => setShowModal(false)}
        show={showModal}
        orderInformation={orderInformation}
      ></CompleteOrderModal>
    </>
  );
};

export default Order;
