import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import waiterPayStackService from "./waiterPayStackService";

const initialState = {
  paystack: {},
  transactionVerifyPayStack: {},
  isError: false,
  isSuccess: false,
  isSuccessVerify: false,
  isLoading: false,
  message: "",
};

export const payStack = createAsyncThunk(
  "waiter/payStack",
  async (data, thunkAPI) => {
    try {
      return await waiterPayStackService.payStack(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyPayStack = createAsyncThunk(
  "waiter/verifyPayStack",
  async (reference, thunkAPI) => {
    try {
      return await waiterPayStackService.payStackVerfiy(reference);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addPaymentTransaction = createAsyncThunk(
  "admin/addPaymentTransaction",
  async (data, thunkAPI) => {
    try {
      return await waiterPayStackService.paymentTransaction(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const waiterPayStackSlice = createSlice({
  name: "waiterPayStack",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.isSuccessVerify = false;
      state.transactionVerifyPayStack = {};
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(payStack.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(payStack.fulfilled, (state, action) => {
        state.isLoading = false;
        state.paystack = action.payload;
      })
      .addCase(payStack.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(verifyPayStack.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyPayStack.fulfilled, (state, action) => {
        state.isLoading = false;
        state.transactionVerifyPayStack = action.payload;
      })
      .addCase(verifyPayStack.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addPaymentTransaction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPaymentTransaction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addPaymentTransaction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});
export const { reset } = waiterPayStackSlice.actions;

export default waiterPayStackSlice.reducer;
