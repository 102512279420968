    // DASHBOARD FIRST TWO CARDS IMAGES
import Admin from "./components/Icons/admin.png";
import OrdersCap from "./components/Icons/cap.png";

// THESE ICONS ARE USE IN DASHBOARD FIRST SECTION PAGE 
import { faSun, faClock, faSpoon } from '@fortawesome/free-solid-svg-icons';

// DASHBOARD COLLEAGUES IMAGES 
import Colleague_1 from "./components/Images/Colleagues/Colleagues-1.jpg";
import Colleague_2 from "./components/Images/Colleagues/Colleagues-2.jpg";
import Colleague_3 from "./components/Images/Colleagues/Colleagues-3.jpg";
import Colleague_4 from "./components/Images/Colleagues/Colleagues-4.jpg";
import Colleague_5 from "./components/Images/Colleagues/Colleagues-5.jpg";
import Colleague_6 from "./components/Images/Colleagues/Colleagues-6.jpg";

// DASHBOARD ASSIGNED TABLES LAST SECTION CARDS IMAGES
import Table from "./components/Icons/table.jpeg";
import User from "./components/Icons/user-icon.png";



// MENU PAGE DATA SOME REASONS BASE CLIENT CHANGE THE DESIGN AND MAYBE USE IN FUTURE.  
import MenuItem_1 from "./components/Images/Menu-Items/MenuItems_1.jpg";
import MenuItem_2 from "./components/Images/Menu-Items/MenuItems_2.jpg";
import MenuItem_3 from "./components/Images/Menu-Items/MenuItems_3.jpg";
import MenuItem_4 from "./components/Images/Menu-Items/MenuItems_4.jpg";
import MenuItem_5 from "./components/Images/Menu-Items/MenuItems_5.jpg";
import MenuItem_6 from "./components/Images/Menu-Items/MenuItems_6.jpg";

//  ========== THAT ARE ALSO SAME ==========
import secondMenuItem_1 from "./components/Images/Menu-Items/chicken-karahi.jpg";
import secondMenuItem_2 from "./components/Images/Menu-Items/second-menu-2.jpeg";
import secondMenuItem_3 from "./components/Images/Menu-Items/second-menu-3.jpeg";
import secondMenuItem_4 from "./components/Images/Menu-Items/second-menu-4.jpeg";
import secondMenuItem_6 from "./components/Images/Menu-Items/second-menu-6.jpeg";

// TABLES PAGE IMAGES
import chair from "./components/Icons/chairIcon.png";
import users from "./components/Icons/user-icon.png";

// MENU PAGE ALL IMAGES IS HERE 
import grillItem_1 from "./components/Images/Menu-Items/Asun.png"
import grillItem_2 from "./components/Images/Menu-Items/Sautted-Prawns.jpg"
import grillItem_3 from "./components/Images/Menu-Items/Grilled-Halloumi-Salad.jpg"
import grillItem_4 from "./components/Images/Menu-Items/Tozo-Suya.jpg"
import grillItem_5 from "./components/Images/Grills-Category/Grill-Category-1.jpg"
import grillItem_6 from "./components/Images/Grills-Category/Grill-Category-2.jpg"
import grillItem_7 from "./components/Images/Grills-Category/Grill-Category-3.jpg"
import grillItem_8 from "./components/Images/Grills-Category/Grill-Category-4.jpg"
import grillItem_9 from "./components/Images/Grills-Category/Grill-Category-5.jpg"
import grillItem_10 from "./components/Images/Grills-Category/Grill-Category-6.jpg"
import grillItem_11 from "./components/Images/Grills-Category/Grill-Category-7.jpg"
import grillItem_12 from "./components/Images/Grills-Category/Grill-Category-8.jpg"
import grillItem_13 from "./components/Images/Grills-Category/Grill-Category-9.jpg"
import grillItem_14 from "./components/Images/Grills-Category/Grill-Category-10.jpg"
import grillItem_15 from "./components/Images/Grills-Category/Grill-Category-11.jpg"
import grillItem_16 from "./components/Images/Grills-Category/Grill-Category-12.jpg"
import grillItem_17 from "./components/Images/Grills-Category/Grill-Category-13.jpg"
import grillItem_18 from "./components/Images/Grills-Category/Grill-Category-3.jpg"
import grillItem_19 from "./components/Images/Grills-Category/Grill-Category-8.jpg"
import grillItem_20 from "./components/Images/Grills-Category/Grill-Category-10.jpg"


import Kitchen from "./components/Images/Menu-Items/first.png"
import Dining from "./components/Images/Menu-Items/second.png"
import Parcels from "./components/Icons/parcel.png"
import CompleteOrders from "./components/Images/Menu-Items/fourth.png"

// DASHBOARD ORDER SECTION DATA

const DashboardTopCards = [
    {
        imgsrc: Kitchen,
        orderName: "Kitchen Orders",
        orderQuantity: 16,
    },
    {
        imgsrc: Dining,
        orderName: "Dining Orders",
        orderQuantity: 12,
    },
    {
        imgsrc: Parcels,
        orderName: "Take Away",
        orderQuantity: 6,
    },
    {
        imgsrc: CompleteOrders,
        orderName: "Complete Orders",
        orderQuantity: 20,
    },

];

const DashboardTopCardsSuperAdmin = [
    {
        imgsrc: Kitchen,
        orderName: "Total Restaurants",
        orderQuantity: 16,
    },
    {
        imgsrc: Dining,
        orderName: "Active Restaurants",
        orderQuantity: 12,
    },
    {
        imgsrc: Parcels,
        orderName: "Expired Restaurants",
        orderQuantity: 6,
    },
    {
        imgsrc: CompleteOrders,
        orderName: "Registered in 2023",
        orderQuantity: 20,
    },
    {
        imgsrc: CompleteOrders,
        orderName: "Total Earnings",
        orderQuantity: 20,
    },
    {
        imgsrc: CompleteOrders,
        orderName: "Total Expense",
        orderQuantity: 20,
    },
    {
        imgsrc: CompleteOrders,
        orderName: "Income Jun,23",
        orderQuantity: 20,
    },
    {
        imgsrc: CompleteOrders,
        orderName: "Expense Jun,23",
        orderQuantity: 20,
    },

];

const SaleDashboardTopCard = [
    {
        imgsrc: Kitchen,
        orderName: "Total Revenue",
        orderQuantity: 16,
    },
    {
        imgsrc: Dining,
        orderName: "Total Cost",
        orderQuantity: 12,
    },
    {
        imgsrc: Parcels,
        orderName: "Total Profit",
        orderQuantity: 6,
    },
    {
        imgsrc: Parcels,
        orderName: "Order Complete",
        orderQuantity: 6,
    },

];

const LatestOrders = [

    {
        orders: 1,
        tables: "Take Away 1",
        dishes: "Invoice",
        total: 5000,
        date: "2023-06-14 02:19:33",
        status: "Complete",
        servedBy: "Mike Micheal",
        action: "",
    },
    {
        orders: 2,
        tables: "Take Away 2",
        dishes: "Invoice",
        total: 3000,
        date: "2023-06-14 02:19:33",
        status: "Complete",
        servedBy: "Mike Micheal",
        action: "",
    },
    {
        orders: 3,
        tables: "Take Away 3",
        dishes: "Invoice",
        total: 15000,
        date: "2023-06-14 02:19:33",
        status: "Complete",
        servedBy: "Jhon Wick",
        action: "",
    },
    {
        orders: 4,
        tables: "Take Away 4",
        dishes: "Invoice",
        total: 10000,
        date: "2023-06-14 02:19:33",
        status: "Complete",
        servedBy: "Angela Wick",
        action: "",
    },{
        orders: 5,
        tables: "Take Away 5",
        dishes: "Invoice",
        total: 6000,
        date: "2023-06-14 02:19:33",
        status: "Complete",
        servedBy: "Jenny Tom",
        action: "",
    },{
        orders: 6,
        tables: "Take Away 6",
        dishes: "Invoice",
        total: 8000,
        date: "2023-06-14 02:19:33",
        status: "Complete",
        servedBy: "Harry Porter",
        action: "",
    },{
        orders: 7,
        tables: "Take Away 7",
        dishes: "Invoice",
        total: 2000,
        date: "2023-06-14 02:19:33",
        status: "Complete",
        servedBy: "Wick Tomi",
        action: "",
    },
]

const SaleDashboardStatus = [
    {
        imgsrc: Kitchen,
        orderName: "Revenue",
        orderQuantity: 16,
    },
    {
        imgsrc: Dining,
        orderName: "Expenses",
        orderQuantity: 12,
    },
    {
        imgsrc: Parcels,
        orderName: "Parcels",
        orderQuantity: 6,
    },
    {
        imgsrc: Parcels,
        orderName: "Complete Orders",
        orderQuantity: 6,
    },

];

const mostOrders = [

        {
            foodItem: "Asun	",
            totalQuantity: 208,
            soldQuantity: 30,
            variant: "warning",
        },
        {
            foodItem: "Sautéed Prawns",
            totalQuantity: 208,
            soldQuantity: 30,
            progressBar: 10,
            variant: "danger",
        },
        {
            foodItem: "Grilled Halloumi Salad",
            totalQuantity: 208,
            soldQuantity: 17,
            progressBar: 30,
            variant: "warning",
        },
        {
            foodItem: "Tozo Suya Ⓡ",
            totalQuantity: 208,
            soldQuantity: 28,
            progressBar: 40,
            variant: "danger",
        }, 
        {
            foodItem: "Grilled Chicken Wings",
            totalQuantity: 208,
            soldQuantity: 10,
            progressBar: 30,
            variant: "warning",
        },
        {
            foodItem: "Whole BBQ Chicken, choice of Rice or Chips with Coleslaw.",
            totalQuantity: 208,
            soldQuantity: 12,
            progressBar: 40,
            variant: "danger",
        },
        {
            foodItem: "½ BBQ Chicken, choice of Rice or Chips with Coleslaw	",
            totalQuantity: 208,
            soldQuantity: 17,
            progressBar: 30,
            variant: "warning",
        },
        {
            foodItem: "Whole Chicken Suya, choice of Rice or Chips with Coleslaw.",
            totalQuantity: 208,
            soldQuantity: 15,
            progressBar: 40,
            variant: "danger",
        },
         {
            foodItem: "½ Chicken Suya, choice of Rice or Chips with Coleslaw.	",
            totalQuantity: 208,
            soldQuantity: 17,
            progressBar: 30,
            variant: "warning",
        },
        {
            foodItem: "FEMI'S FISH: Grilled Croaker Fish, Peppered Sauce, choice of Fried Yam or Plantain with Coleslaw	",
            totalQuantity: 208,
            soldQuantity: 25,
            progressBar: 40,
            variant: "danger",
        },
{
            foodItem: "Smoky Bourbon Ribs, Sweet Potato Fries, Corn on the Cob & Coleslaw",
            totalQuantity: 208,
            soldQuantity: 17,
            progressBar: 30,
            variant: "warning",
        },
        {
            foodItem: "Grilled Lamb Chops, Rice, Gravy with Coleslaw		",
            totalQuantity: 208,
            soldQuantity: 10,
            progressBar: 40,
            variant: "danger",
        },
{
            foodItem: "Jerk Ribs, Rice, Corn on Cob with Coleslaw",
            totalQuantity: 208,
            soldQuantity: 28,
            progressBar: 40,
            variant: "warning",
        },
{
            foodItem: "PG Beef Burger with Chips",
            totalQuantity: 208,
            soldQuantity: 24,
            progressBar: 30,
            variant: "danger",
        },
        {
            foodItem: "Chicken Burger with Chips",
            totalQuantity: 208,
            soldQuantity: 37,
            progressBar: 40,
            variant: "warning",
        },

    
];

const DashboardData = {
    "topCards": DashboardTopCards,
    "latestOrders": LatestOrders,
    "mostOrders": mostOrders,
}

const SuperAdminDashboardData = {
    "topCards": DashboardTopCardsSuperAdmin,
    "latestOrders": LatestOrders,
    "mostOrders": mostOrders,
}

const SaleDashboardData = {
    "topCards": SaleDashboardTopCard,
    "latestOrders": SaleDashboardStatus,
}

// DASHBOARD FIRST TWO COLUMNS DATA
const ServedData = [
    {
        icon: Admin,
        guestQuantity: 50,
        message: "NO OF GUESTS SERVED",
    },
    {
        icon: OrdersCap,
        guestQuantity: 90,
        message: "NO OF ORDERS SERVED",
    },
];

// DASHBOARD SECOND TWO COLUMNS DATA
const DateTimeData = [
    {
        icon: faSun,
        day: "Full Day",
        shifts: "Shift",
    },
    {
        icon: faClock,
        day: "9am to 5pm",
        shifts: "Shift Time",
    },
];

// DASHBOARD COLLEAGUES DATA 
const ColleagueData = [
    {
        imgsrc: Colleague_1,
        name: "Lenda Brith",
        designation: "Bartender",
        number: "+44 565-584-1426",
    },
    {
        imgsrc: Colleague_2,
        name: "Smith Jonas",
        designation: "Cheff",
        number: "+44 565-584-1426",
    },
    {
        imgsrc: Colleague_3,
        name: "Camille Earth",
        designation: "Waiter",
        number: "+44 565-584-1426",
    },
    {
        imgsrc: Colleague_4,
        name: "Jason Ogle",
        designation: "Manager",
        number: "+44 565-584-1426",
    },
    {
        imgsrc: Colleague_5,
        name: "Tom Cruise",
        designation: "Worker",
        number: "+44 565-584-1426",
    },
    {
        imgsrc: Colleague_6,
        name: "Scott Jarvis",
        designation: "Labor",
        number: "+44 565-584-1426",
    },
    {
        imgsrc: Colleague_3,
        name: "Hallian javes",
        designation: "Guard",
        number: "+44 565-584-1426",
    },
    {
        imgsrc: Colleague_2,
        name: "Spick",
        designation: "Waiter",
        number: "+44 565-584-1426",
    },
];

// DASHBOARD AVAILABLE TABLES DATA 
const ColleagueDataNew = [
    {
        imgsrc: Table,
        name: "F1 T1",
    },
    {
        imgsrc: Table,
        name: "F1 T2",
    },
    {
        imgsrc: Table,

        name: "F1 T3",
    },
    {
        imgsrc: Table,
        name: "F1 T4",
    },
    {
        imgsrc: Table,
        name: "F1 T5",
    },
    {
        imgsrc: Table,
        name: "F1 T6",
    },
];

// DASHBOARD LAST SECTION ASSIGNED TABLES DATA 
const AssignedTablesData = [
    {
        Client: "Jacob Nyle",
        AdminImg: User,
        Quantity: "6 People",
        TableImg: Table,
        TableNumber: "F1 T1",
    },
    {
        Client: "Summer Collier",
        AdminImg: User,
        Quantity: "6 People",
        TableImg: Table,
        TableNumber: "F1 T1",
    },
    {
        Client: "Robert Bentham",
        AdminImg: User,
        Quantity: "6 People",
        TableImg: Table,
        TableNumber: "F1 T1",
    },
    {
        Client: "Robert Bentha",
        AdminImg: User,
        Quantity: "6 People",
        TableImg: Table,
        TableNumber: "F1 T1",
    },
    {
        Client: "Linda Bird",
        AdminImg: User,
        Quantity: "6 People",
        TableImg: Table,
        TableNumber: "F1 T1",
    },
    {
        Client: "Kathleen Buchan",
        AdminImg: User,
        Quantity: "6 People",
        TableImg: Table,
        TableNumber: "F1 T1",
    },
    {
        Client: "Thomas Davies",
        AdminImg: User,
        Quantity: "6 People",
        TableImg: Table,
        TableNumber: "F1 T1",
    },
    {
        Client: "Kathleen dock",
        AdminImg: User,
        Quantity: "6 People",
        TableImg: Table,
        TableNumber: "F1 T1",
    },
];

//  ORDER PAGE TABLE DATA 
const OrdersTableData = [
    {
        TableData: "1",
        TableNameData: "Take Away 1",
        // button
        DishesData: "Dishes",
        // button end
        DiscountData: "1000",
        TotalData: "11000",
        DateData: "	26-May-2023 2:46 pm",
        StatusData: "Complete",
        ServedData: "Glowingsoft",
        // button
        ActionData: "Dishes",
    }, {
        TableData: "2",
        TableNameData: "Take Away 2",
        // button
        DishesData: "Dishes",
        // button end
        DiscountData: "500",
        TotalData: "6000",
        DateData: "	11-May-2023 6:46 pm",
        StatusData: "Pending",
        ServedData: "Tom Cruise",
        // button
        ActionData: "Dishes",
    }, {
        TableData: "3",
        TableNameData: "Take Away 3",
        // button
        DishesData: "Dishes",
        // button end
        DiscountData: "0",
        TotalData: "15000",
        DateData: "	2-feb-2023 8:46 pm",
        StatusData: "Complete",
        ServedData: "Glowing Walter",
        // button
        ActionData: "Dishes",
    }, {
        TableData: "4",
        TableNameData: "Take Away 4",
        // button
        DishesData: "Dishes",
        // button end
        DiscountData: "200",
        TotalData: "4000",
        DateData: "	6-sep-2023 1:46 pm",
        StatusData: "Pending",
        ServedData: "Glowingsoft",
        // button
        ActionData: "Dishes",
    }, {
        TableData: "5",
        TableNameData: "Take Away 5",
        // button
        DishesData: "Dishes",
        // button end
        DiscountData: "4000",
        TotalData: "17000",
        DateData: "	18-feb-2023 10:46 pm",
        StatusData: "Complete",
        ServedData: "Jhon Wick",
        // button
        ActionData: "Dishes",
    }, {
        TableData: "6",
        TableNameData: "Take Away 6",
        // button
        DishesData: "Dishes",
        // button end
        DiscountData: "1000",
        TotalData: "6000",
        DateData: "	15-dec-2023 11:22 pm",
        StatusData: "Pending",
        ServedData: "Jhon Walter",
        // button
        ActionData: "Dishes",
    }, {
        TableData: "7",
        TableNameData: "Take Away 7",
        // button
        DishesData: "Dishes",
        // button end
        DiscountData: "2000",
        TotalData: "8000",
        DateData: "	11-jan-2023 6:46 pm",
        StatusData: "Pending",
        ServedData: "Micheal",
        // button
        ActionData: "Dishes",
    }, {
        TableData: "8",
        TableNameData: "Take Away 8",
        // button
        DishesData: "Dishes",
        // button end
        DiscountData: "5000",
        TotalData: "15000",
        DateData: "	1-nov-2023 3:46 pm",
        StatusData: "Pending",
        ServedData: "Jimmy Carter",
        // button
        ActionData: "Dishes",
    }, {
        TableData: "9",
        TableNameData: "Take Away 9",
        // button
        DishesData: "Dishes",
        // button end
        DiscountData: "800",
        TotalData: "4000",
        DateData: "	4-apr-2023 6:46 pm",
        StatusData: "Complete",
        ServedData: "James Bond",
        // button
        ActionData: "Dishes",
    },
];

// MENU PAGE TABLE DATA ALL DISHES IN IT.
const MenuTableData = [
    {
        srNumber: "1",
        title: "Asun",
        foodImg: grillItem_1,
        description: "Grilled lamb sautéed in Peppered Sauce",
        price: "13",
        category: "LITTLE PLATES",
        status: "Active",
        action: "Modal",
    }, {
        srNumber: "2",
        title: "Sautéed Prawns",
        foodImg: grillItem_2,
        description: "Tiger prawns marinated in fresh herbs & spices sauteed in unsalted butter & garlic oil",
        price: "13",
        category: "LITTLE PLATES",
        status: "Active",
        action: "Modal",
    }, {
        srNumber: "3",
        title: "Grilled Halloumi Salad",
        foodImg: grillItem_3,
        description: "",
        price: "12",
        category: "LITTLE PLATES",
        status: "Active",
        action: "Modal",
    }, {
        srNumber: "4",
        title: "Tozo Suya Ⓡ",
        foodImg: grillItem_4,
        description: "Grilled thinly sliced beef marinated in various spices which include peanut cake, salt, vegetable oil & other flavorings",
        price: "15",
        category: "LITTLE PLATES",
        status: "Active",
        action: "Modal",
    }, {
        srNumber: "5",
        title: "Grilled Chicken Wings",
        foodImg: grillItem_5,
        description: "Freshly grilled wings tossed in BBQ sauce",
        price: "12",
        category: "LITTLE PLATES",
        status: "Active",
        action: "Modal",
    },
    {
        srNumber: "6",
        title: "Whole BBQ Chicken, choice of Rice or Chips with Coleslaw.",
        foodImg: grillItem_6,
        description: "",
        price: "25",
        category: "GRILLS",
        status: "Active",
        action: "Modal",
    },
    {
        srNumber: "7",
        title: "½ BBQ Chicken, choice of Rice or Chips with Coleslaw",
        foodImg: grillItem_7,
        description: "",
        price: "18",
        category: "GRILLS",
        status: "Active",
        action: "Modal",
    },
    {
        srNumber: "8",
        title: "Whole Chicken Suya, choice of Rice or Chips with Coleslaw.",
        foodImg: grillItem_8,
        description: "",
        price: "25",
        category: "GRILLS",
        status: "Active",
        action: "Modal",
    },
    {
        srNumber: "9",
        title: "½ Chicken Suya, choice of Rice or Chips with Coleslaw.",
        foodImg: grillItem_9,
        description: "",
        price: "18",
        category: "GRILLS",
        status: "Active",
        action: "Modal",
    },
    {
        srNumber: "10",
        title: "FEMI'S FISH: Grilled Croaker Fish, Peppered Sauce, choice of Fried Yam or Plantain with Coleslaw",
        foodImg: grillItem_10,
        description: "(Fish cooked to order please allow 30 mins)",
        price: "30",
        category: "GRILLS",
        status: "Active",
        action: "Modal",
    },
    {
        srNumber: "11",
        title: "Smoky Bourbon Ribs, Sweet Potato Fries, Corn on the Cob & Coleslaw",
        foodImg: grillItem_11,
        description: "",
        price: "22.50",
        category: "GRILLS",
        status: "Active",
        action: "Modal",
    },
    {
        srNumber: "12",
        title: "Grilled Lamb Chops, Rice, Gravy with Coleslaw",
        foodImg: grillItem_12,
        description: "",
        price: "24",
        category: "GRILLS",
        status: "Active",
        action: "Modal",
    },
    {
        srNumber: "13",
        title: "Jerk Ribs, Rice, Corn on Cob with Coleslaw",
        foodImg: grillItem_13,
        description: "",
        price: "22.50",
        category: "GRILLS",
        status: "Active",
        action: "Modal",
    },
    {
        srNumber: "14",
        title: "PG Beef Burger with Chips",
        foodImg: grillItem_14,
        description: "Tomato, Lettuce, Ketchup, Cheese & Cucumber",
        price: "12",
        category: "GRILLS",
        status: "Active",
        action: "Modal",
    },
    {
        srNumber: "15",
        title: "Chicken Burger with Chips",
        foodImg: grillItem_15,
        description: "Tomato, Lettuce, Ketchup & Cucumber",
        price: "12",
        category: "GRILLS",
        status: "Active",
        action: "Modal",
    },
    {
        srNumber: "16",
        title: "Spicy Halloumi Burger with Chips ✔",
        foodImg: grillItem_16,
        description: "Tomato, Lettuce, Ketchup & Cucumber",
        price: "12",
        category: "GRILLS",
        status: "Active",
        action: "Modal",
    },

    {
        srNumber: "17",
        title: "BBQ Chicken Drumsticks, choice of Rice or Chips with Coleslaw",
        foodImg: grillItem_17,
        description: "Chicken Malai Boti with Salad",
        price: "24",
        category: "KIDS MAIN",
        status: "Active",
        action: "Modal",
    }, {
        srNumber: "18",
        title: "Chicken Piece",
        foodImg: grillItem_18,
        description: "Fisha-man's Soup Croaker, King Prawns and Yam in Spicy Soup",
        price: "11.50",
        category: "SOUPS & BROTHS",
        status: "Active",
        action: "Modal",
    }, {
        srNumber: "19",
        title: "FEMI'S FEAST: Southern Seafood Broth for One",
        foodImg: grillItem_19,
        description: "Crab Clusters, Blue Crab, Prawns, Squid Yam and Corn on the Cob",
        price: "25",
        category: "SOUPS & BROTHS",
        status: "Active",
        action: "Modal",
    }, 
    {
        srNumber: "20",
        title: "FEMI'S FEAST: Southern Seafood Broth for Two",
        foodImg: grillItem_20,
        description: "Lobster Tail, Crab Clusters, Blue Crab, Squid Prawns, Yam and Corn on the Cob",
        price: "46",
        category: "SOUPS & BROTHS",
        status: "Active",
        action: "Modal",
    },

];

// THIS IS THE MENU ITEMS DATA SOME ISSUES BASE WE CAN'T USE THIS DESIGN BUT USE IN MAYBE FUTURE 
const NewOrderMenuData = {
    recomended: [
        {
            imgsrc: grillItem_1,
            name: "Starters",
            ingredients: "Grilled lamb sautéed in Peppered Sauce",
            price: "13",
            offPrice: "MRP, 20",
        },
        {
            imgsrc: grillItem_2,
            name: "Specials",
            ingredients: "Tiger prawns marinated in fresh herbs & spices sauteed in unsalted butter & garlic oil",
            price: "13",
            offPrice: "MRP, 25",
        },
        {
            imgsrc: grillItem_3,
            name: "Pepper Soup",
            ingredients: "",
            price: "12",
            offPrice: "MRP, 15",
        },
        {
            imgsrc: grillItem_4,
            name: "Main Dishes",
            ingredients: "",
            price: "12",
            offPrice: "MRP, 18",
        },
        {
            imgsrc: grillItem_5,
            name: "Stockfish Dishes",
            ingredients: "Grilled thinly sliced beef marinated in various spices which include peanut cake, salt, vegetable oil & other flavoring",
            price: "12",
            offPrice: "MRP, 12",
        },
        {
            imgsrc: grillItem_6,
            name: "Rice Dishes",
            ingredients: "Freshly grilled wings tossed in BBQ sauce",
            price: "25",
            offPrice: "MRP, 33",
        },
        {
            imgsrc: grillItem_7,
            name: "Yam Dishes",
            ingredients: "",
            price: "18",
            offPrice: "MRP, 24",
        },
        {
            imgsrc: grillItem_8,
            name: "Kids Menu",
            ingredients: "With sprouted amaranth",
            price: "25",
            offPrice: "MRP, 32",
        },
        {
            imgsrc: grillItem_5,
            name: "Vegetarian- Vegan Menu",
            ingredients: "Extras",
            price: "25",
            offPrice: "MRP, 32",
        },
    ],
    fast_food: [
        {
            imgsrc: MenuItem_3,
            name: "Italic Pizza",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
        {
            imgsrc: MenuItem_2,
            name: "Beef Grill Burger",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
        {
            imgsrc: MenuItem_5,
            name: "Supreme Pizza",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
        {
            imgsrc: MenuItem_5,
            name: "italic Pizza",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
        {
            imgsrc: MenuItem_6,
            name: "Juicy Burger",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
    ],
    french_fries: [
        {
            imgsrc: MenuItem_1,
            name: "Italic Pizza",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
        {
            imgsrc: MenuItem_2,
            name: "Beef Grill Burger",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
        {
            imgsrc: MenuItem_3,
            name: "Chicken Tenders",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
        {
            imgsrc: MenuItem_4,
            name: "Chicken Steam",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
        {
            imgsrc: MenuItem_5,
            name: "Supreme Pizza",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
        {
            imgsrc: MenuItem_6,
            name: "Patty Burger",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
    ],
    omlattes: [
        {
            imgsrc: MenuItem_1,
            name: "Italic Pizza",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
        {
            imgsrc: MenuItem_2,
            name: "Beef Grill Burger",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
        {
            imgsrc: MenuItem_3,
            name: "Chicken Tenders",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
        {
            imgsrc: MenuItem_4,
            name: "Chicken Steam",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
        {
            imgsrc: MenuItem_5,
            name: "Supreme Pizza",
            ingredients: "With sprouted amaranth",
            price: "£120",
            offPrice: "MRP, £180",
        },
    ],
}


// const BookingCardsData = {
//     upcoming: [
//         {
//             customerImg: Colleague_1,
//             customerName: "Jacob Nyle",
//             time: "09:00 PM",
//             date: "11 feb",
//             customerMail: User,
//             persons: 1,
//         },
//      {
//             customerImg: Colleague_2,
//             customerName: "Jacob Nyle",
//             time: "09:00 PM",
//             date: "11 feb",
//             customerMail: User,
//             persons: 2,
//         },
//      {
//             customerImg: Colleague_3,
//             customerName: "Jacob Nyle",
//             time: "09:00 PM",
//             date: "11 feb",
//             customerMail: User,
//             persons: 4,
//         },
//      {
//             customerImg: Colleague_4,
//             customerName: "Jacob Nyle",
//             time: "09:00 PM",
//             date: "11 feb",
//             customerMail: User,
//             persons: 3,
//         },
//      {
//             customerImg: Colleague_5,
//             customerName: "Jacob Nyle",
//             time: "09:00 PM",
//             date: "11 feb",
//             customerMail: User,
//             persons:1,
//         },
//     ],

//     history: [
//         {
//             customerImg: Colleague_5,
//             customerName: "Jacob Nyle",
//             time: "09:00 PM",
//             date: "11 feb",
//             customerMail: User,
//             persons: 2,
//         },
//      {
//             customerImg: Colleague_4,
//             customerName: "Jacob Nyle",
//             time: "09:00 PM",
//             date: "11 feb",
//             customerMail: User,
//             persons: 3,
//         },
//      {
//             customerImg: Colleague_3,
//             customerName: "Jacob Nyle",
//             time: "09:00 PM",
//             date: "11 feb",
//             customerMail: User,
//             persons: 5,
//         },
//      {
//             customerImg: Colleague_2,
//             customerName: "Jacob Nyle",
//             time: "09:00 PM",
//             date: "11 feb",
//             customerMail: User,
//             persons: 1,
//         },
//      {
//             customerImg: Colleague_1,
//             customerName: "Jacob Nyle",
//             time: "09:00 PM",
//             date: "11 feb",
//             customerMail: User,
//             persons: 2,
//         },
//     ]
// };



// ALL AVAILABLE AND RESERVED TABLES DATA IS HERE.
const ReservationTables = [
    {
        tableNumber: "1",
        chairImg: chair,
        peoplesImg: users,
        people: "4 Peoples",
    },
    {
        tableNumber: "2",
        chairImg: chair,
        peoplesImg: users,
        people: "2 Peoples",
    },
    {
        tableNumber: "3",
        chairImg: chair,
        peoplesImg: users,
        people: "6 Peoples",
    },
    {
        tableNumber: "4",
        chairImg: chair,
        peoplesImg: users,
        people: "3 Peoples",
    },
    {
        tableNumber: "5",
        chairImg: chair,
        peoplesImg: users,
        people: "4 Peoples",
    },
    {
        tableNumber: "6",
        chairImg: chair,
        peoplesImg: users,
        people: "2 Peoples",
    },
    {
        tableNumber: "7",
        chairImg: chair,
        peoplesImg: users,
        people: "6 Peoples",
    },
    {
        tableNumber: "8",
        chairImg: chair,
        peoplesImg: users,
        people: "3 Peoples",
    },
    {
        tableNumber: "9",
        chairImg: chair,
        peoplesImg: users,
        people: "8 Peoples",
    },
    {
        tableNumber: "10",
        chairImg: chair,
        peoplesImg: users,
        people: "3 Peoples",
    },
    {
        tableNumber: "11",
        chairImg: chair,
        peoplesImg: users,
        people: "5 Peoples",
    },
    {
        tableNumber: "12",
        chairImg: chair,
        peoplesImg: users,
        people: "4 Peoples",
    },
    {
        tableNumber: "12",
        chairImg: chair,
        peoplesImg: users,
        people: "2 Peoples",
    },
    {
        tableNumber: "13",
        chairImg: chair,
        peoplesImg: users,
        people: "4 Peoples",
    },
    {
        tableNumber: "14",
        chairImg: chair,
        peoplesImg: users,
        people: "Empty",
    },
    {
        tableNumber: "15",
        chairImg: chair,
        peoplesImg: users,
        people: "Empty",
    },
    {
        tableNumber: "16",
        chairImg: chair,
        peoplesImg: users,
        people: "Empty",
    },
    {
        tableNumber: "17",
        chairImg: chair,
        peoplesImg: users,
        people: "Empty",
    },
    {
        tableNumber: "18",
        chairImg: chair,
        peoplesImg: users,
        people: "Empty",
    },
    {
        tableNumber: "19",
        chairImg: chair,
        peoplesImg: users,
        people: "Empty",
    },
    {
        tableNumber: "20",
        chairImg: chair,
        peoplesImg: users,
        people: "Empty",
    },
    {
        tableNumber: "21",
        chairImg: chair,
        peoplesImg: users,
        people: "Empty",
    },
    {
        tableNumber: "22",
        chairImg: chair,
        peoplesImg: users,
        people: "Empty",
    },
    {
        tableNumber: "23",
        chairImg: chair,
        peoplesImg: users,
        people: "Empty",
    },
];

// BOOKING PAGE DATA 
const BookingCardsData = [
    {
        customerImg: Colleague_1,
        customerName: "Jacob Nyle",
        time: "09:00 PM",
        date: "11 feb",
        customerMail: "Waynejames@gmail.com",
        persons: "Booked For 2",
    },
    {
        customerImg: Colleague_2,
        customerName: "Robert",
        time: "7:44 PM",
        date: "3 feb",
        customerMail: "Robert@gmail.com",
        persons: "Booked For 3",
    },
    {
        customerImg: Colleague_3,
        customerName: "Jhon Wick",
        time: "10:00 PM",
        date: "15 feb",
        customerMail: "JhonWick@gmail.com",
        persons: "Booked For 4",
    },
    {
        customerImg: Colleague_4,
        customerName: "Tom Cruise",
        time: "12:00 PM",
        date: "20 feb",
        customerMail: "Tom Cruise@gmail.com",
        persons: "Booked For 6",
    },
    {
        customerImg: Colleague_5,
        customerName: "Alien Gaurd",
        time: "2:00 PM",
        date: "25 feb",
        customerMail: "Alien Gaurd@gmail.com",
        persons: "Booked For 3",
    },
];

// NOTIFICATION PAGE ALL DATA IS HERE
const NotificationData = [
    {
        orderNumber: "1",
        tableNumber: "F1 T1",
        CustomerName: "Tom Cruise",
        dateTime: "11 feb",
        message: "Add less sugar to watemelon juice",
    },
    {
        orderNumber: "2",
        tableNumber: "F1 T2",
        CustomerName: "Jhon Wick",
        dateTime: "12 feb",
        message: "Add less sugar in Desert",
    },
    {
        orderNumber: "3",
        tableNumber: "F1 T3",
        CustomerName: "Jacob Nyle",
        dateTime: "12 feb",
        message: "Add suger and sauce",
    },
    {
        orderNumber: "4",
        tableNumber: "F1 T4",
        CustomerName: "Linda Bird",
        dateTime: "13 feb",
        message: "Add Spicy Sauses",
    },
    {
        orderNumber: "5",
        tableNumber: "F1 T5",
        CustomerName: "Camille Earth",
        dateTime: "14 feb",
        message: "Don't Add the Spicy Sauces",
    },
    {
        orderNumber: "6",
        tableNumber: "F1 T6",
        CustomerName: "Smith Jonas",
        dateTime: "14 feb",
        message: "Add Extra Cheese",
    },
];

// NEW ORDER PAGE DATA START `=`=`=`=`=`=`=`=`=
const NewOrderPageData = [
    {
        orderNumber: "1",
        tableNumber: "F1 T1",
        CustomerName: "Tom Cruise",
        dateTime: "11 feb",
        message: "Add less sugar to watemelon juice",
    },
    {
        orderNumber: "2",
        tableNumber: "F1 T2",
        CustomerName: "Jhon Wick",
        dateTime: "12 feb",
        message: "Add less sugar in Desert",
    },
    {
        orderNumber: "3",
        tableNumber: "F1 T3",
        CustomerName: "Jacob Nyle",
        dateTime: "12 feb",
        message: "Add suger and sauce",
    },
    {
        orderNumber: "4",
        tableNumber: "F1 T4",
        CustomerName: "Linda Bird",
        dateTime: "13 feb",
        message: "Add Spicy Sauses",
    },
    {
        orderNumber: "5",
        tableNumber: "F1 T5",
        CustomerName: "Camille Earth",
        dateTime: "14 feb",
        message: "Don't Add the Spicy Sauces",
    },
    {
        orderNumber: "6",
        tableNumber: "F1 T6",
        CustomerName: "Smith Jonas",
        dateTime: "14 feb",
        message: "Add Extra Cheese",
    },
];

// NEW ORDER PAGE DATA START `=`=`=`=`=`=`=`=`=
const CategoryTableData = [
    {
        id: 1,
        category: "LITTLE PLATES",
        img: grillItem_1,
        status: "11 feb",
        action: "Add less sugar to watemelon juice",
    },
    {
        id: 2,
        category: "LITTLE PLATES",
        img: grillItem_2,
        status: "11 feb",
        action: "Add less sugar to watemelon juice",
    },
    {
        id: 3,
        category: "LITTLE PLATES",
        img: grillItem_3,
        status: "11 feb",
        action: "Add less sugar to watemelon juice",
    },
    {
        id: 4,
        category: "LITTLE PLATES",
        img: grillItem_4,
        status: "11 feb",
        action: "Add less sugar to watemelon juice",
    },
    {
        id: 5,
        category: "MAINS",
        img: grillItem_5,
        status: "11 feb",
        action: "Add less sugar to watemelon juice",
    },
    {
        id: 6,
        category: "KIDS MAIN",
        img: grillItem_6,
        status: "11 feb",
        action: "Add less sugar to watemelon juice",
    },
    {
        id: 7,
        category: "SOUPS & BROTHS",
        img: grillItem_7,
        status: "11 feb",
        action: "Add less sugar to watemelon juice",
    },
    
];

// NEW ORDER PAGE DATA START `=`=`=`=`=`=`=`=`=
const ExpenseTableData = [
    {
        id: 1,
        category: "Staff Salary",
        amount: 2000,
        description: "All staff salary transfered",
        date: "11 feb 2023",
        action: "edit",
    },{
        id: 2,
        category: "Rent",
        amount: 10000,
        description: "Rent Transfer to Jhon Wick",
        date: "13 feb 2023",
        action: "edit",
    },{
        id: 3,
        category: "Utility Bill",
        amount: 6000,
        description: "Utility bill successfully pay",
        date: "14 feb 2023",
        action: "edit",
    },{
        id: 4,
        category: "Grocery",
        amount: 15000,
        description: "Cheese, Chicken, Oil, Eggs, Burger Bun etc. ",
        date: "15 feb 2023",
        action: "edit",
    },{
        id: 5,
        category: "Furniture",
        amount: 20000,
        description: "Tables, Chairs, Decoration Pieces etc.",
        date: "17 feb 2023",
        action: "edit",
    },{
        id: 6,
        category: "Taxes",
        amount: 4000,
        description: "All Taxes are pay",
        date: "20 feb 2023",
        action: "edit",
    },
    {
        id: 7,
        category: "Restaurant Website",
        amount: 30000,
        description: "Restaurant Website create and uploaded",
        date: "24 feb 2023",
        action: "edit",
    },
    {
        id: 8,
        category: "Maintenance",
        amount: 1000,
        description: "Lights, AC, Wall Clocks etc.",
        date: "27 feb 2023",
        action: "edit",
    },
    
    
];

export { DashboardData, ServedData, DateTimeData, ColleagueData, ColleagueDataNew, AssignedTablesData, NewOrderMenuData, OrdersTableData, ReservationTables, BookingCardsData, NotificationData, MenuTableData, NewOrderPageData, CategoryTableData, ExpenseTableData,SuperAdminDashboardData,SaleDashboardData };