import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import PingLogo from "./ping logo.png";
import logo from "./tittle logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { publicCountry } from "../src/redux/features/superAdmin/Country/createAction";
import { getPublicSubscription } from "../src/redux/features/superAdmin/Subscription/subscriptionDetailsSlice";
import { addPublicRestaurant } from "../src/redux/features/superAdmin/Restaurants/RestaurantDetailsSlice";

// Import Components
import GeneralIndicator from "./components/model/GeneralIndicator";

function SignUp() {
  const { countries } = useSelector((state) => state.country);
  const subscription = useSelector((state) => state.subscription.subscriptions);

  const { status } = useSelector((state) => state.restaurant);

  const [restaurantName, setRestaurantName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [ownerPhone, setOwnerPhone] = useState("");
  const [country, setCountry] = useState("");
  const [parcels, setParcels] = useState("");
  const [tables, setTables] = useState("");
  const [Subscription, setSubscription] = useState("");
  const [Address, setAddress] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(publicCountry());
    dispatch(getPublicSubscription());
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addPublicRestaurant(
        restaurantName,
        ownerName,
        ownerEmail,
        ownerPhone,
        country,
        parcels,
        tables,
        Subscription,
        Address,
        history
      )
    );
  };

  return (
    <>
      {status === "loading" && <GeneralIndicator />}
      <Navbar bg="light" variant="dark">
        <Container>
          <Navbar.Brand href="#">
            <div className="d-flex">
              <img src={PingLogo} alt="logo" className="me-2" />
              <img src={logo} alt="logo" />
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>

      <div
        style={{
          // display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Container>
          <Card
            style={{ width: "700px", padding: "50px", margin: "10px auto" }}
          >
            <Card.Body>
              <Card.Text className="text-center h2 fw-bold">
                Create Restaurant
              </Card.Text>
              {/* <Card.Text className="text-center h6 ">
                Welcome Back! We are glad to see you
              </Card.Text> */}
              <Form method="post" onSubmit={onSubmit}>
                <Row>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label className="fw-bold">
                        Restaurant Name:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter Restaurant Name"
                        onChange={(e) => setRestaurantName(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label className="fw-bold">Owner Name:</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter Owner Name"
                        onChange={(e) => setOwnerName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label className="fw-bold pt-4">
                        Owner Email:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter Owner Email"
                        onChange={(e) => setOwnerEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label className="fw-bold pt-4">
                        Owner Phone:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter Owner Phone"
                        onChange={(e) => setOwnerPhone(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label className="fw-bold pt-4">
                        {" "}
                        Country:
                      </Form.Label>
                      <Form.Select
                        aria-label="select"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        required
                      >
                        <option value="" disabled hidden>
                          Select Country
                        </option>
                        {countries.map((countries) => (
                          <option key={countries._id} value={countries._id}>
                            {countries.name}
                          </option>
                        ))}
                        ;
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label className="fw-bold pt-4">Parcels:</Form.Label>
                      <Form.Control
                        type="number"
                        value={parcels}
                        required
                        placeholder="Enter Parcels"
                        onChange={(e) => {
                          const enteredValue = e.target.value;
                          if (enteredValue >= 1) {
                            setParcels(enteredValue);
                          } else {
                            setParcels("");
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label className="fw-bold pt-4">Tables:</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Tables"
                        value={tables}
                        required
                        onChange={(e) => {
                          const enteredValue = e.target.value;
                          if (enteredValue >= 1) {
                            setTables(enteredValue);
                          } else {
                            setTables("");
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label className="fw-bold pt-4">
                        {" "}
                        Subscription:
                      </Form.Label>
                      <Form.Select
                        onChange={(e) => setSubscription(e.target.value)}
                        as="select"
                        value={Subscription}
                        aria-label="select"
                        required
                      >
                        <option value="" disabled hidden>
                          Select Subscription
                        </option>
                        {subscription.map((subscription) => (
                          <option
                            key={subscription._id}
                            value={subscription._id}
                          >
                            {subscription.title}
                          </option>
                        ))}
                        ;
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group style={{ marginBottom: "30px" }}>
                      <Form.Label className="fw-bold pt-4">Address:</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Enter Address"
                        onChange={(e) => setAddress(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    {/* <Form.Group controlId="category" style={{ marginBottom: '30px' }}>
                                <Form.Label className='fw-bold pt-4'>Image</Form.Label>
                                <Form.Control
                                    type="File"
                                    name="image"
                                    placeholder=' '
                                    // style={{ width: '100%', padding: '5px' }}
                                />
                            </Form.Group> */}
                  </Col>
                </Row>
                <div className="text-center">
                  <Button
                    type="submit"
                    variant="warning"
                    className="text-center"
                    style={{ width: "100%", padding: "10px 140px" }}
                  >
                    SIGN UP
                  </Button>
                </div>
              </Form>
              <div className="text-center py-3">
                <p>
                  Already have a account?
                  <a href="/" class="text-danger">
                    Login
                  </a>
                </p>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default SignUp;
