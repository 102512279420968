import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Table,
  Pagination,
  Form,
  Col,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";

import grillItem_1 from "../../Images/Grills-Category/Grill-Category-1.jpg";
import {
  allcategories,
  addcategories,
  reset,
  updateCategories,
  updateCategoriesStatus,
  allCategoriesByBranchId,
} from "../../../redux/features/admin/Categories/createAction";
import ReactPaginate from "react-paginate";
import GeneralIndicator from "../../model/GeneralIndicator";

const Category = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const [inputCategory, setInputCategory] = useState("");
  const [inputImage, setInputImage] = useState("null");
  const [currentIndex, setCurrentIndex] = useState(1);

  const [type, setType] = useState(0);

  const { selectBranches } = useSelector((state) => state.branches);

  const { categories, isError, isLoading, isSuccess, message, totalPage } =
    useSelector((state) => state.category);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      setShowModal(false);
      setInputCategory("");
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  // useEffect(() => {
  //   dispatch(allCategoriesByBranchId(selectBranches));
  // }, [selectBranches]);

  const handleEdit = (item) => {
    setSelectedItem(item);
    setInputCategory(item?.title);
    setType(item?.type);
    setShowModal(true);
  };

  const handleSave = () => {
    if (selectedItem) {
      //update item
      const data = {
        title: inputCategory,
        type: type,
        _id: selectedItem._id,
        pageNo: currentPage + 1,
      };
      dispatch(
        updateCategories({
          branchId: selectBranches[0],
          data: data,
          search: searchQuery,
        })
      );
    } else {
      // Add new item
      let data = {
        title: inputCategory,
        type: type,
        pageNo: currentPage + 1,
      };
      dispatch(addcategories({ branchId: selectBranches[0], data: data }));
      setSearchQuery("");
    }
  };

  const handleUpdateStatus = (item) => {
    const newStatus = item?.isActive == true ? false : true;
    const data = { status: newStatus, _id: item._id, pageNo: currentPage + 1 };
    dispatch(
      updateCategoriesStatus({
        branchId: selectBranches[0],
        data: data,
        search: searchQuery,
      })
    );
  };

  const handleDelete = (item) => {
    const updatedData = data.filter((d) => d.id !== item.id);
    setData(updatedData);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setInputCategory("");
    setInputImage("");
    setType("");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    dispatch(
      allCategoriesByBranchId({
        branchId: selectBranches[0],
        pageNo: currentPage,
        search: event.target.value,
      })
    );
    setCurrentPage(0);
    setCurrentIndex(1);
  };

  const handlePageChange = (pageNumber) => {
    let pageNo = pageNumber?.selected + 1;
    setCurrentPage(pageNumber?.selected);
    dispatch(
      allCategoriesByBranchId({
        branchId: selectBranches[0],
        pageNo: pageNo,
        search: searchQuery,
      })
    );
    if (pageNo) {
      setCurrentIndex(pageNo);
    }
  };

  useEffect(() => {
    if (selectBranches.length > 0 && selectBranches[0] !== undefined) {
      handlePageChange();
    }
  }, [selectBranches]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredData = data.filter((item) =>
    item.Category.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div
      style={{
        margin: "60px 20px 300px 20px",
        padding: "40px ",
        backgroundColor: "white",
      }}
    >
      {isLoading && <GeneralIndicator />}
      <h2 className="p-3 fw-bold">Categories</h2>
      <div className="bg-white">
        <div className="d-flex justify-content-between mb-3 p-4">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Search by Category"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Form.Group>
          <Button variant="danger" onClick={() => setShowModal(true)}>
            Add Category
          </Button>
        </div>
        <Table
          striped
          style={{ borderRadius: "5px", overflow: "hidden" }}
          hover
          className="text-center"
        >
          <thead>
            <tr>
              <th>sr#</th>
              <th>Category</th>
              {/* <th>Image</th> */}
              <th>Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {categories?.map((item, index) => {
              const adjustedIndex = (currentIndex - 1) * 10 + index + 1;
              return (
                <tr key={index}>
                  <td className="text-center fw-bold">{adjustedIndex}</td>
                  <td>{item?.title}</td>
                  <td>
                    {item?.type
                      ? item.type.charAt(0).toUpperCase() +
                        item.type.slice(1).toLowerCase()
                      : ""}
                  </td>
                  {/* <td>
                  item.Image ? (
                  <img src={item.Image} alt={item.Category} />
                  ) : (
                  <img
                    src={grillItem_1}
                    alt="logo"
                    width={50}
                    className="rounded-circle"
                  />
                  )
                </td> */}
                  <td>
                    {item.isActive === true ? (
                      <Button
                        className="btn-sm"
                        variant="success"
                        onClick={() => handleUpdateStatus(item)}
                      >
                        {item.isActive === true ? "Active" : "InActive"}
                      </Button>
                    ) : (
                      <Button
                        className="btn-sm"
                        variant="danger"
                        onClick={() => handleUpdateStatus(item)}
                      >
                        {item.isActive === true ? "Active" : "InActive"}
                      </Button>
                    )}
                  </td>
                  <td>
                    <Button
                      variant="warning"
                      className="me-3"
                      onClick={() => handleEdit(item)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <ReactPaginate
          nextLabel=">"
          pageRangeDisplayed={10}
          marginPagesDisplayed={2}
          previousLabel="<"
          pageClassName="page-item"
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pageCount={totalPage}
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          forcePage={currentPage}
          renderOnZeroPageCount={null}
        />

        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold ">
              {selectedItem ? "Edit Category" : "Add Category"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="fw-bold">Title:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Title"
                    value={inputCategory}
                    onChange={(e) => setInputCategory(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Form.Group>
                    <Form.Label className="fw-bold">Type:</Form.Label>
                    <Form.Select
                      value={type}
                      aria-label="select"
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="" selected hidden>
                        Select Type
                      </option>
                      <option value="kitchen">Kitchen</option>
                      <option value="bar">Bar</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </Col>
              {/* <Col lg={6}>
                <Form.Group>
                  <Form.Label className="fw-bold">Image:</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Enter Image"
                    // value={inputImage}
                    onChange={(e) => setInputImage(e.target.files[0])}
                  />
                </Form.Group>
              </Col> */}
            </Row>
          </Modal.Body>
          <Modal.Footer className="mt-4">
            <Button variant="danger" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="warning" onClick={handleSave}>
              {selectedItem ? "Save Changes" : "Add Category"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Category;
