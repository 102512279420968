import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import staffService from "./staffService";

const initialState = {
  staff: [],
  totalPages: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};
// get staff
export const allStaff = createAsyncThunk(
  "admin/allStaff",
  async ({ branchId, pageNo, search }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await staffService.getAllStaff(
        user.accessToken,
        branchId,
        pageNo,
        search
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// add staff
export const addStaff = createAsyncThunk(
  "admin/addStaff",
  async ({ branchId, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await staffService.addStaff(user.accessToken, branchId, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update staff
export const updateStaff = createAsyncThunk(
  "admin/updateStaff",
  async ({ branchId, data, search }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await staffService.updateStaff(
        user.accessToken,
        data,
        branchId,
        search
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update staff Status
export const updateStaffStatus = createAsyncThunk(
  "admin/updateStaffStatus",
  async ({ branchId, data, search }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await staffService.updateStaffStatus(
        user.accessToken,
        data,
        branchId,
        search
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get Active Staff
export const activeStaffList = createAsyncThunk(
  "admin/activeStaffList",
  async (branchId, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await staffService.getActiveStaff(user.accessToken, branchId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allStaff.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allStaff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.staff = action.payload?.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(allStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.staff = [];
      })
      .addCase(addStaff.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addStaff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.staff = action.payload?.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(addStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateStaff.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateStaff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.staff = action.payload;
      })
      .addCase(updateStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateStaffStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateStaffStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.staff = action.payload;
      })
      .addCase(updateStaffStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeStaffList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activeStaffList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.staff = action.payload;
      })
      .addCase(activeStaffList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.staff = [];
      });
  },
});
export const { reset } = staffSlice.actions;

export default staffSlice.reducer;
