import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const getAllBranches = async (token, pageNo) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/admin/branches`, config);
  if (response.data.body) {
    return response.data;
  }
};

const updateBranch = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/admin/branches/${data.id}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Update Branch");
    return response.data.body;
  }
};

const addBranch = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/admin/branches/add`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Branch Added Successfully");
    return response.data;
  }
};

const branchUpdate = async (token, id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/admin/branches/update/${id}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Branch Updated Successfully");
    return response.data;
  }
};

const branchesService = {
  getAllBranches,
  updateBranch,
  addBranch,
  branchUpdate,
};

export default branchesService;
