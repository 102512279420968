import axios from 'axios';
import {toast} from 'react-toastify';
const BASE_URL =process.env.REACT_APP_BASE_URL;


const getAllMenuItem = async (token,pageNo) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(`${BASE_URL}/admin/product/all?page=${pageNo}`, config);
    if (response.data.body) {
        return response.data;
    }
  }
  const addMenuItem = async (token,data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.post(`${BASE_URL}/admin/product/add?page=${data?.pageNo}`,data,config);
    if (response.data.body) {
      toast.success('Successfully Add MenuItem');
        return response.data;
    }
  }

  const updateMenuItem = async (token, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/product/${data._id}?page=${data?.pageNo}`,data,config);
    if (response.data.body) {
      toast.success('Successfully Update MenuItem');
        return response.data;
    }
  }

  const updateMenuItemStatus = async (token, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(`${BASE_URL}/admin/product/status/${data._id}?page=${data?.pageNo}`,data,config);
    if (response.data.body) {
      toast.success('Successfully Update MenuItem Status');
        return response.data;
    }
  }

  const getActiveMenuItem = async (token,id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.get(`${BASE_URL}/admin/product/active?id=${id?id:''}`, config);
    if (response.data.body) {
        return response.data.body;
    }
  }
const menuItemService = {
    getAllMenuItem,
    addMenuItem,
    updateMenuItem,
    updateMenuItemStatus,
    getActiveMenuItem
}


export default menuItemService;