import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import menuItemService from './menuItemService';


const initialState = {
    menuItem : [],
    totalPages:null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ""
}

// get MenuItem
export const allMenuItem = createAsyncThunk('admin/allMenuItem', async (pageNo,thunkAPI) => {
    try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        return await menuItemService.getAllMenuItem(user.accessToken,pageNo);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
//add MenuItem
export const addMenuItem = createAsyncThunk('admin/addMenuItem', async (data,thunkAPI) => {
    try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        return await menuItemService.addMenuItem(user.accessToken,data);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
  })

//update MenuItem
export const updateMenuItem = createAsyncThunk('admin/updateMenuItem', async (data,thunkAPI) => {
    try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
       return await menuItemService.updateMenuItem(user.accessToken, data);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
  })

//update MenuItem Status
export const updateMenuItemStatus = createAsyncThunk('admin/updateMenuItemStatus', async (data,thunkAPI) => {
    try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
       return await menuItemService.updateMenuItemStatus(user.accessToken, data);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
  })

  // get Active MenuItem
export const activeMenuItemList = createAsyncThunk('admin/activeMenuItemList', async (id,thunkAPI) => {
    try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        return await menuItemService.getActiveMenuItem(user.accessToken,id);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const menuItemSlice = createSlice({
    name: "menuItem",
    initialState,
    reducers: {
      reset: (state) => {
        state.isLoading = false
        state.isError = false
        state.isSuccess = false
        state.message = ""
    }
    },
    extraReducers: (builder) => {
        builder.addCase(allMenuItem.pending, (state) => {
        state.isLoading = true;
    }).addCase(allMenuItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.menuItem = action.payload.body;
        state.totalPages=action.payload.total_pages;
    }).addCase(allMenuItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.menuItem = null;
    }).addCase(addMenuItem.pending, (state) => {
        state.isLoading = true;
    }).addCase(addMenuItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;  
        state.menuItem = action.payload.body;
        state.totalPages=action.payload.total_pages;
    }).addCase(addMenuItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;  
        state.message = action.payload;
    }).addCase(updateMenuItem.pending, (state) => {
        state.isLoading = true;
    }).addCase(updateMenuItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;  
        state.menuItem = action.payload.body;
        state.totalPages=action.payload.total_pages;
    }).addCase(updateMenuItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;  
        state.message = action.payload;
    }).addCase(updateMenuItemStatus.pending, (state) => {
        state.isLoading = true;
    }).addCase(updateMenuItemStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;  
        state.menuItem = action.payload.body;
        state.totalPages=action.payload.total_pages;
    }).addCase(updateMenuItemStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;  
        state.message = action.payload;
    }).addCase(activeMenuItemList.pending, (state) => {
        state.isLoading = true;
    }).addCase(activeMenuItemList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.menuItem = action.payload;
    }).addCase(activeMenuItemList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.menuItem = null;
    })
    }
})
export const { reset } = menuItemSlice.actions;


export default menuItemSlice.reducer