import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import {
  addBank,
  getBank,
  updateBank,
  createStripeAccount,
  updateStripeAccount,
  verifyStripeAccount,
  reset,
  getStripeAccountInformation,
} from "../../../redux/features/admin/Bank/bankAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";

import GeneralIndicator from "../../model/GeneralIndicator";

const PaymentSetting = () => {
  const dispatch = useDispatch();

  const { selectBranches } = useSelector((state) => state.branches);
  const { bank, verifyStripe, isSuccessVerify, stripeAccount,isError,isSuccess,message,isLoading} = useSelector(
    (state) => state.bank
  );

  const [businessName, setBusinessName] = useState("");
  const [settlementBank, setSettlementBank] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [paystackAccountId, setPaystackAccountId] = useState("");
  const [id, setId] = useState("");
  const [paymentType, setPaymentType] = useState("");

  const [businessNameStripe, setBusinessNameStripe] = useState("");
  const [businessTypeStripe, setBusinessTypeStripe] = useState("");
  const [stripeAccountId, setStripeAccountId] = useState("");

  const [stripeRoutingNumber, setStripeRoutingNumber] = useState("");
  const [stripeAccountNumber, setStripeAccountNumber] = useState("");
  const [emailStripe, setEmailStripe] = useState("");

  const handlePaymentTypeChange = (e) => {
    setPaymentType(e.target.value);
  };

  useEffect(() => {
    if (isError) {
      toast.error(message);
      dispatch(reset());
    }
    if (isSuccess) {
    }
  }, [isError, isSuccess, message]);

  const handleSavePayStack = (e) => {
    e.preventDefault();
    let data = {
      businessName: businessName,
      settlementBank: settlementBank,
      accountNumber: accountNumber,
      type: "payStack",
      branchId: selectBranches[0],
    };
    dispatch(addBank(data));
  };

  const handleUpdatePayStack = (e) => {
    e.preventDefault();
    let data = {
      businessName: businessName,
      settlementBank: settlementBank,
      accountNumber: accountNumber,
      type: "payStack",
      branchId: selectBranches[0],
      accountId: paystackAccountId,
      id: id,
    };
    dispatch(updateBank(data));
  };

  const handleSaveStripe = (e) => {
    e.preventDefault();
    let data = {
      businessName: businessNameStripe,
      type: "stripe",
      businessType: businessTypeStripe,
      branchId: selectBranches[0],
      routingNumber: stripeRoutingNumber,
      accountNumber: stripeAccountNumber,
      email: emailStripe,
    };
    dispatch(createStripeAccount(data));
  };

  const handleUpdateStripe = (e) => {
    e.preventDefault();
    let data = {
      businessName: businessNameStripe,
      type: "stripe",
      businessType: businessTypeStripe,
      branchId: selectBranches[0],
      accountId: stripeAccountId,
      id: id,
      routingNumber: stripeRoutingNumber,
      accountNumber: stripeAccountNumber,
      email: emailStripe,
    };
    dispatch(updateStripeAccount(data));
  };

  useEffect(() => {
    dispatch(getBank({ branchId: selectBranches[0] }));
  }, [selectBranches]);

  useEffect(() => {
    if (isSuccessVerify && stripeAccountId) {
      let data = {
        accountId: stripeAccountId,
        returnUrl: window.location.href,
        email: emailStripe,
      };
      dispatch(verifyStripeAccount(data));
    }
  }, [stripeAccountId, isSuccessVerify]);

  useEffect(() => {
    if (isSuccessVerify) {
      toast.success("Please Check Your Email For Stripe Account Verification ");
      // window.location.href = verifyStripe.url;
      let data = { accountId: stripeAccountId };
      dispatch(getStripeAccountInformation(data));
      dispatch(reset());
    }
  }, [verifyStripe]);

  useEffect(() => {
    if (stripeAccount && paymentType == "stripe" && stripeAccountId) {
      const interval = setInterval(() => {
        let data = { accountId: stripeAccountId };
        dispatch(getStripeAccountInformation(data));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [stripeAccount, stripeAccountId, paymentType]);

  useEffect(() => {
    dispatch(getBank({ branchId: selectBranches[0] }));
  }, [selectBranches]);

  useEffect(() => {
    setBusinessName(bank?.account?.businessName);
    setAccountNumber(bank?.account?.accountNumber);
    setSettlementBank(bank?.account?.settlementBankNumber);
    setPaymentType(bank?.type);
    setId(bank?._id);
    setPaystackAccountId(bank?.account?.accountId);

    setBusinessNameStripe(bank?.stripeAccount?.businessName);
    setBusinessTypeStripe(bank?.stripeAccount?.businessType);
    setStripeAccountId(bank?.stripeAccount?.accountId);
    setStripeRoutingNumber(bank?.stripeAccount?.routingNumber);
    setStripeAccountNumber(bank?.stripeAccount?.accountNumber);
    setEmailStripe(bank?.stripeAccount?.email);
  }, [bank]);

  return (
    <>
     {isLoading ? <GeneralIndicator /> : null}
      <div
        style={{
          margin: "60px 20px 300px 20px",
          padding: "40px ",
          backgroundColor: "white",
        }}
      >
        <div className="px-3 pb-3">
          <h3 className="fw-bold">Payment Setting </h3>
          {bank?.type == "stripe" && paymentType == "stripe" ? (
            stripeAccount?.individual?.verification?.status == "verified" ? (
              <>
                <p>
                  <FontAwesomeIcon icon={faCheckCircle} /> verified
                </p>
              </>
            ) : (
              <>
                <p>
                  <FontAwesomeIcon icon={faTimesCircle} /> unverified
                </p>
              </>
            )
          ) : paymentType == "stripe" ? (
            "verified/unverified "
          ) : (
            ""
          )}
        </div>
        <Container>
          <Row className="mb-5">
            <Col lg={6}>
              <Form.Check
                inline
                type="checkbox"
                label="PayStack"
                value="payStack"
                checked={paymentType === "payStack"}
                onChange={handlePaymentTypeChange}
              />
            </Col>
            <Col lg={6}>
              <Form.Check
                inline
                type="checkbox"
                label="Stripe"
                value="stripe"
                checked={paymentType === "stripe"}
                onChange={handlePaymentTypeChange}
              />
            </Col>
          </Row>
          {paymentType === "payStack" ? (
            <>
              <Row className="mb-5">
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label className="fw-bold">Business Name </Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Enter Business Name"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridAddress">
                  <Form.Label className="fw-bold">Settlement Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Settlement Number"
                    value={settlementBank}
                    onChange={(e) => setSettlementBank(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <Row className="my-4">
                <Form.Group
                  as={Col}
                  className="mb-3"
                  controlId="formGridService"
                >
                  <Form.Label className="fw-bold">Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Account Number"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridState"></Form.Group>
              </Row>
              {bank?.type == "payStack" ? (
                <Button
                  variant="warning"
                  onClick={handleUpdatePayStack}
                  className="outline-warning px-5"
                  size="lg"
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="warning"
                  onClick={handleSavePayStack}
                  className="outline-warning px-5"
                  size="lg"
                >
                  Create
                </Button>
              )}
            </>
          ) : (
            ""
          )}

          {paymentType === "stripe" ? (
            <>
              <Row className="mb-5">
                <Col lg={6}>
                  <Form.Group as={Col} controlId="formGridName">
                    <Form.Label className="fw-bold">Email</Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter Email"
                      value={emailStripe}
                      onChange={(e) => setEmailStripe(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group as={Col} controlId="formGridName">
                    <Form.Label className="fw-bold">Business Name </Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter Business Name"
                      value={businessNameStripe}
                      onChange={(e) => setBusinessNameStripe(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className="fw-bold">BusinessType:</Form.Label>
                    <Form.Select
                      aria-label="select"
                      value={businessTypeStripe}
                      onChange={(e) => setBusinessTypeStripe(e.target.value)}
                    >
                      <option selected disabled>
                        Select BusinessType
                      </option>
                      <option value="individual">Individual</option>
                      <option value="company">Company</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group as={Col} controlId="formGridName">
                    <Form.Label className="fw-bold">Account Number</Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter Account Number"
                      value={stripeAccountNumber}
                      onChange={(e) => setStripeAccountNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col lg={6}>
                  <Form.Group as={Col} controlId="formGridName">
                    <Form.Label className="fw-bold">Routing Number</Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter Routing Number"
                      value={stripeRoutingNumber}
                      onChange={(e) => setStripeRoutingNumber(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {bank?.type == "stripe" ? (
                <Button
                  variant="warning"
                  onClick={handleUpdateStripe}
                  className="outline-warning px-5"
                  size="lg"
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="warning"
                  onClick={handleSaveStripe}
                  className="outline-warning px-5"
                  size="lg"
                >
                  Create
                </Button>
              )}
            </>
          ) : (
            ""
          )}

          {/* <Button
            variant="warning"
            // onClick={handleSave}
            className="outline-warning px-5"
            size="lg"
          >
            Create
          </Button> */}
        </Container>
      </div>
    </>
  );
};
export default PaymentSetting;
