import React, { useState, useContext } from "react";
import {
  Navbar,
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import PingLogo from "./ping logo.png";
import logo from "./tittle logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { login } from "../src/redux/features/auth/userDetailsSlice";
import GeneralIndicator from "./components/model/GeneralIndicator";

// import axios from 'axios';
// import { toast } from 'react-toastify';

function Login(props) {
  const { setCurrentUser } = useContext(AuthContext);
  const { status } = useSelector((state) => state.user);

  const history = useHistory();
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const dispatch = useDispatch();

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(login(inputEmail, inputPassword, props, history, setCurrentUser));
  };

  return (
    <>
      <div>
        {status === "loading" && <GeneralIndicator />}
        <Navbar>
          <Container>
            <Navbar.Brand href="#">
              <div className="d-flex">
                <img src={PingLogo} alt="logo" className="me-2" />
                <img src={logo} alt="logo" />
              </div>
            </Navbar.Brand>
          </Container>
        </Navbar>

        <Container bg="light" variant="dark">
          <Row>
            <Col sm={4} md={4} lg={{ span: 4, offset: 4 }}>
              <Card>
                <Card.Body>
                  <Card.Text className="text-center h2 fw-bold">Login</Card.Text>
                  <Card.Text className="text-center h6 ">
                    Welcome Back! We are glad to see you
                  </Card.Text>
                  <Form method="post" onSubmit={onSubmit}>
                    <Form.Group
                      controlId="name"
                      style={{ marginBottom: "40px", maxWidth: "100vh" }}
                    >
                      <Form.Label className="mt-4 h5">
                        <b>Email:</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        style={{ width: "100%", padding: "15px" }}
                        placeholder="Enter your email address"
                        onChange={(e) => setInputEmail(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="name"
                      style={{ marginBottom: "30px", maxWidth: "100vh" }}
                    >
                      <Form.Label className="mt-2 h5">
                        <b>Password:</b>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="name"
                        on
                        placeholder="Enter your password"
                        style={{ width: "100%", padding: "15px" }}
                        onChange={(e) => setInputPassword(e.target.value)}
                        required
                      />
                    </Form.Group>

                    <div className="text-center">
                      <Button
                        type="submit"
                        variant="warning"
                        className="text-center"
                      >
                        Login
                      </Button>
                    </div>
                  </Form>
                  <Row className="py-3">
                    <Col lg={7}>
                      <p>
                        <a href="/signUp" class="text-warning">
                          Create Account
                        </a>
                      </p>
                    </Col>
                    <Col lg={5}>
                      <p>
                        <a href="/forgotPassword" class="text-danger">
                          Forgot Password?
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Login;
