import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userTypeService from "./userTypeService";

const initialState = {
  userTypes: [],
  totalPages: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//get all UserType
export const allUserType = createAsyncThunk(
  "admin/allUserType",
  async (thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await userTypeService.getAllUserType(user.accessToken);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//add UserType
export const addUserType = createAsyncThunk(
    "admin/addUserType",
    async ({data} , thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await userTypeService.addUserType(
          user.accessToken,
          data
        );
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

//update UserType
export const updateUserType = createAsyncThunk(
    "admin/updateUserType",
    async ({ data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await userTypeService.updateUserType(user.accessToken, data,);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
//update UserType Status
export const updateUserTypeStatus = createAsyncThunk(
    "admin/updateUserTypeStatus",
    async ({data}, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await userTypeService.updateUserTypeStatus(user.accessToken,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  
export const userTypeSlice = createSlice({
  name: "userType",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allUserType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allUserType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userTypes = action.payload?.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(allUserType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.userTypes = [];
      })
      .addCase(addUserType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addUserType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userTypes = action.payload?.body;
      })
      .addCase(addUserType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateUserType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userTypes = action.payload;
      })
      .addCase(updateUserType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateUserTypeStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserTypeStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userTypes = action.payload;
      })
      .addCase(updateUserTypeStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});
export const { reset } = userTypeSlice.actions;

export default userTypeSlice.reducer;
