import React ,{useState} from 'react'
import { Form, Modal, Button, Col, Row, Table, Card } from 'react-bootstrap';
import { useDispatch,useSelector} from "react-redux";

import {
    updateOrder,
  } from "../../../redux/features/waiter/waiterOrderAction";

import {
    addPaymentTransaction
  } from "../../../redux/features/waiter/PayStack/waiterPayStackAction";



function CardInformation(props) {
    const dispatch = useDispatch();
    const { selectBranches } = useSelector((state) => state.branches);

    const handleConfirmOrder = () => {
        let data = {
            _id: props?.orderInformation?._id,
            status: "complete",
            branchId: selectBranches[0],
            cashReceived:props?.orderInformation?.totalAmount,
          };
          dispatch(updateOrder(data));
        let transactionData ={
            branchId:selectBranches[0],
            orderId:props?.orderInformation?._id,
            totalAmount:props?.orderInformation?.totalAmount,
            type:"card",
        }   
         dispatch((addPaymentTransaction(transactionData))); 
      };

    return (
        <>
            <Modal size="sm" centered {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Order Completion</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to complete this order?</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={()=> {props.onHide();props.setSelectedMethod(null)}}>
          No
        </Button>
        <Button variant="warning"onClick={handleConfirmOrder}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>

        </>
    );
}

export default CardInformation;