import React from 'react'
import { Row, Col, Card } from 'react-bootstrap';

function DashboardOrders(props) {

    return <Row>
        {props.DashboardTopCards.map((val, ind) => {
            return <Col lg={3} sm={6} key={ind}>
                <Card  className="text-center" >
                    <Card.Body className=' '>
                    <img src={val.imgsrc} alt="cards_images" className='pt-2' width={60}/>
                        <div className='d-grid'>
                            <h6 className='mx-2 pt-0'>{val.orderName}</h6>
                            <h6 className='mx-2 p-0 text-secondary'>{val.orderQuantity}</h6>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        }
        )}
    </Row>
        ;
}

export default DashboardOrders;