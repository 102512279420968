import React from "react";

import { Container, Col, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarker,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import PingLogo from "../../ping logo.png";
import logo from "../../tittle logo.png";

function RestaurantInformation({ order }) {
  return (
    <Container fluid style={{ backgroundColor: "#ffffff" }}>
      <Row className="p-4 justify-content-center">
        <div className="d-flex justify-content-center text-center">
          {order?.restaurantInformation?.logo !== "" &&
          order?.restaurantInformation?.logo !== undefined ? (
            <img
              src={
                // order?.restaurantInformation?.logo === "" ||
                // order?.restaurantInformation?.logo === undefined
                //   ? PingLogo
                //   :
                `https://pingmyfood.com/uploads/${order?.restaurantInformation?.logo}`
              }
              alt="logo"
              style={{ height: "80px" }}
            />
          ) : null}
        </div>
        {/* <div className="d-flex justify-content-between text-center">
          <div className="d-flex">
            <img
              src={`https://pingmyfood.com/uploads/${order?.restaurantInformation?.logo}`}
              alt="logo"
              style={{ width: "125px", height: "125px" }}
            />
            {/* <img src={logo} alt="logo" style={{ width: '75px' }}/> */}
        {/* </div> */}
        {/* <h6><FontAwesomeIcon icon={faMapMarker} style={{ color: 'red' }} /> {order?.restaurantInformation?.addresses?.fullAddress}</h6> */}
        {/* </div> */}
        {/* <h6 className='text-end'> <FontAwesomeIcon icon={faPhone} style={{ color: 'green' }} /> {order?.restaurantInformation?.mobile}</h6> */}
        {/* <div className="d-flex justify-content-between text-center"> */}
        {/* <h6>{order?.restaurantInformation?.restaurants?.title}</h6> */}
        {/* <h6> <FontAwesomeIcon icon={faEnvelope} /> {order?.restaurantInformation?.email}</h6> */}
        {/* </div> */}
      </Row>
    </Container>
  );
}

export default RestaurantInformation;
