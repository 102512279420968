import React from 'react';
import { Card, Button, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagShopping, faUser } from '@fortawesome/free-solid-svg-icons';

import NotificationsCards from './NotificationsCards';
import { NotificationData } from "../../../JsonData";


function Notification() {
    return <Container>
        <NotificationsCards title={'Notifications'} NotificationData={NotificationData}/>
    </Container>


        ;
}

export default Notification;