import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Col, Row } from "react-bootstrap";

import { stripePayment } from "../../../redux/features/waiter/Stripe/waiterStripeAction";

import { useSelector, useDispatch } from "react-redux";
import { publicCountry } from "../../../redux/features/superAdmin/Country/createAction";
import { branchUpdate } from "../../../redux/features/admin/Branches/branchesAction";

function EditBranch(props) {
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.country);

  const [name, setName] = useState("");
  const [logo, setLogo] = useState(
    props?.data?.logo === undefined ? "" : props?.data?.logo
  );
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    dispatch(publicCountry());
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    // let data = {
    //   id: id,
    //   branchName: name,
    //   address: address,
    //   countryId: country,
    // };

    const formData = new FormData();

    formData.append("branchName", name);
    formData.append("address", address);
    formData.append("countryId", country);
    formData.append("logo", logo);

    dispatch(branchUpdate({ id, data: formData }));
  };
  useEffect(() => {
    setId(props?.data?._id);
    setAddress(props?.data?.address?.fullAddress);
    setCountry(props?.data?.countryId);
    setName(props?.data?.branchName);
    setLogo(props?.data?.logo);
  }, [props.data]);

  const handleCloseModal = () => {
    setAddress("");
    setCountry("");
    setName("");
    props.setData([]);
    props.onHide();
  };

  return (
    <>
      <Modal {...props} centered onHide={handleCloseModal}>
        <Form method="post" onSubmit={handleSave}>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold ">Edit Branch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="fw-bold">Name:</Form.Label>
                  <Form.Control
                    value={name}
                    type="text"
                    required
                    placeholder="Enter Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Form.Group>
                    <Form.Label className="fw-bold">Address:</Form.Label>
                    <Form.Control
                      value={address}
                      required
                      type="text"
                      placeholder="Enter Address"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="fw-bold">Country:</Form.Label>
                  <Form.Select
                    aria-label="select"
                    value={country}
                    required
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option value="" disabled hidden>
                      Select Country
                    </option>
                    {countries.map((countries) => (
                      <option key={countries._id} value={countries._id}>
                        {countries.name}
                      </option>
                    ))}
                    ;
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="fw-bold">Logo:</Form.Label>
                  {typeof logo === "string" ? (
                    <div>
                      <img
                        src={`${process.env.REACT_APP_BASE_URL_FILES}/${logo}`}
                        alt="logo"
                        style={{ width: "100px", height: "100px" }}
                      />
                      <Form.Control
                        type="file"
                        onChange={(e) => setLogo(e.target.files[0])}
                        accept="image/*"
                      />
                    </div>
                  ) : (
                    <Form.Control
                      type="file"
                      onChange={(e) => setLogo(e.target.files[0])}
                      accept="image/*"
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="mt-4">
            <Button variant="warning" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default EditBranch;
