import axios from 'axios';
import {toast} from 'react-toastify';
import {setStatus,STATUSES,setSubscriptions } from './createAction'



export const getSubscription = (
  currentUser
) => {
  return async function createAsyncThunk(dispatch) {
    dispatch(setStatus({status: STATUSES.LOADING}));
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/superAdmin/subscriptionPlans/all?status=${'active'}`, {
        headers: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
      });
      console.log(res, 'response');
      dispatch(
        setStatus({
            status: STATUSES.IDLE,
        }),
      );
      dispatch(
        setSubscriptions({subscriptions:res.data.body}),);
    } catch (error) {
      console.log(error, 'Error');
      dispatch(
        setStatus({
          status: STATUSES.ERROR,
          error: error.response?.data.message,
        }),
      );
    toast.error( error.response?.data.message);
    }
  };
};


export const getPublicSubscription = (
  currentUser
) => {
  return async function createAsyncThunk(dispatch) {
    dispatch(setStatus({status: STATUSES.LOADING}));
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/superAdmin/subscriptionPlans/all?status=${'active'}`);
      console.log(res, 'response');
      dispatch(
        setStatus({
            status: STATUSES.IDLE,
        }),
      );
      dispatch(
        setSubscriptions({subscriptions:res.data.body}),);
    } catch (error) {
      console.log(error, 'Error');
      dispatch(
        setStatus({
          status: STATUSES.ERROR,
          error: error.response?.data.message,
        }),
      );
    toast.error( error.response?.data.message);
    }
  };
};