import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const customerPayment = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/waiter/payment`,data
  );
  if (response.data.body) {
    return response.data.body;
  }
};


const waiterStripeService = {
    customerPayment
};

export default waiterStripeService;