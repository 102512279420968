import React from 'react'
import { Row, Col, Card, Button, Table, Accordion, ProgressBar } from 'react-bootstrap';

function SaleTodayStatus(props) {
  
    return <>
    <div>
    <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0" className='my-5 '>
                <Accordion.Header style={{color:'yellow'}}> Today Status</Accordion.Header>
                <Accordion.Body>
                <Row>
        {props.LatestOrders.map((val, ind) => {
            return <Col lg={3} sm={6} key={ind}>
                <Card  className="text-center" >
                    <Card.Body className=' '>
                    <img src={val.imgsrc} alt="cards_images" className='pt-2' width={60}/>
                        <div className='d-grid'>
                            <h6 className='mx-2 pt-0'>{val.orderName}</h6>
                            <h6 className='mx-2 p-0 text-secondary'>{val.orderQuantity}</h6>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        }
        )}
    </Row>
                   
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
        
    </>

        ;
}

export default SaleTodayStatus