// import {createSlice} from '@reduxjs/toolkit';

// export const STATUSES = Object.freeze({
//     IDLE: 'idle',
//     ERROR: 'error',
//     LOADING: 'loading',
//   });
  
//   export const countrySlice = createSlice({
//     name: 'country',
//     initialState: {
//       status: STATUSES.IDLE,
//       error: '',
//       countries:[]
//     },
//     reducers: {
//       setStatus: function (state, action) {
//         state.status = action.payload.status;
//         state.error = action.payload.error;
//       },
//       setCountries: function (state, action) {
//         state.countries = action.payload.countries;
//         state.error = action.payload.error;
//       },
//       default: (state) => {
//         return [...state];
//       },
//     },
//   });
  
//   export const {setStatus,setCountries} = countrySlice.actions;
  
//   export default countrySlice.reducer;


import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import countryService from "./CountryDetailsSlice";

const initialState = {
  countries: [],
  totalPages: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//get all Country
export const allCountry = createAsyncThunk(
  "admin/allCountry",
  async ({pageNo,search},thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await countryService.getAllCountry(user.accessToken,pageNo,search);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//add Country
export const addCountry = createAsyncThunk(
    "admin/addCountry",
    async ({data} , thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await countryService.addCountry(
          user.accessToken,
          data
        );
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

//update Country
export const updateCountry = createAsyncThunk(
    "admin/updateCountry",
    async ({ data,search }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await countryService.updateCountry(user.accessToken, data,search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
//update Country Status
export const updateCountryStatus = createAsyncThunk(
    "admin/updateCountryStatus",
    async ({data,search}, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await countryService.updateCountryStatus(user.accessToken,data,search);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  
  //get Active Country
export const activeCountry = createAsyncThunk(
  "admin/activeCountry",
  async (thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await countryService.getActiveCountry(user.accessToken);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
  //get public Country
  export const publicCountry = createAsyncThunk(
    "admin/publicCountry",
    async (thunkAPI) => {
      try {
        return await countryService.getPublicCountry();
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allCountry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allCountry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.countries = action.payload?.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(allCountry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.countries = [];
      })
      .addCase(addCountry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCountry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.countries = action.payload?.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(addCountry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateCountry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCountry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.countries = action.payload;
      })
      .addCase(updateCountry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeCountry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activeCountry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.countries = action.payload?.body;
      })
      .addCase(activeCountry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.countries = [];
      })  
      .addCase(publicCountry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(publicCountry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.countries = action.payload?.body;
      })
      .addCase(publicCountry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.countries = [];
      })
      .addCase(updateCountryStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCountryStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.countries = action.payload;
      })
      .addCase(updateCountryStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
     
  },
});
export const { reset } = countrySlice.actions;

export default countrySlice.reducer;
