import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Routes } from "./Routes";
import { allBranches } from "../redux/features/admin/Branches/branchesAction";
import { useDispatch } from "react-redux";

const RouteConfig = ({ role }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(allBranches());
  }, []);
  const routesForRole = Routes[role];
  const allRoutes = Object.values(routesForRole).map((item) => (
    <Route key={item.route} path={item.route} exact>
      {item.component}
    </Route>
  ));

  return <Switch>{allRoutes}</Switch>;
};

export default RouteConfig;
