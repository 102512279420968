import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Table,
  Pagination,
  Form,
  Col,
  Row,
  Badge,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";

import { useDispatch, useSelector } from "react-redux";
import {
  allUserType,
  addUserType,
  updateUserType,
  reset,
  updateUserTypeStatus,
} from "../../../redux/features/superAdmin/UserType/userTypeAction";
import { toast } from "react-toastify";

import GeneralIndicator from "../../model/GeneralIndicator";

const UserType = () => {
  const dispatch = useDispatch();
  const { userTypes, isError, isSuccess, message, totalPages,isLoading } = useSelector(
    (state) => state.userType
  );
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [inputTitle, setInputTitle] = useState("");
  const [inputRestaurantSee, setInputRestaurantSee] = useState("");
  const [inputKey, setInputKey] = useState("");
  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      setShowModal(false);
      setSelectedItem(null);
      setInputTitle("");
      setInputRestaurantSee("");
      setInputKey("");
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  useEffect(() => {
    dispatch(allUserType());
  }, []);


  const handleEdit = (item) => {
    setSelectedItem(item);
    setInputTitle(item?.title);
    setInputKey(item?.key);
    setInputRestaurantSee(item?.canRestaurantSee);
    setShowModal(true);
  };
  const handleSave = (e) => {
    e.preventDefault();
    if (selectedItem) {
      const updatedItem = {
        id: selectedItem._id,
        title: inputTitle,
        key: inputKey,
        canRestaurantSee: inputRestaurantSee,
      };
      dispatch(updateUserType({ data: updatedItem }));
    } else {
      const newItem = {
        title: inputTitle,
        key: inputKey,
        canRestaurantSee: inputRestaurantSee,
      };
      dispatch(addUserType({ data: newItem }));
    }
  };

  const handleUpdateStatus = (item) => {
    const newStatus = item?.status == "active" ? "inactive" : "active";
    const data = { status: newStatus, _id: item._id };
    dispatch(updateUserTypeStatus({ data: data }));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setInputTitle("");
    setInputRestaurantSee("");
    setInputKey("");
  };
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      {isLoading ? <GeneralIndicator /> : null}
      <h2 className="p-3 fw-bold">UserType</h2>
      <div className="bg-white">
        <div className="d-flex justify-content-between mb-3 p-4">
          <Form.Group>
            {/* <Form.Control
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
            /> */}
          </Form.Group>
          <Button variant="danger" onClick={() => setShowModal(true)}>
            Add UserType
          </Button>
        </div>
        <Table striped hover className="text-center">
          <thead>
            <tr>
              <th>sr#</th>
              <th>Title</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userTypes.map((item, index) => {
              const adjustedIndex = (currentIndex - 1) * 10 + index + 1;
              return (
                <tr key={item.id}>
                  <td className="text-center fw-bold">{adjustedIndex}</td>
                  <td>
                    {item?.title?.charAt(0)?.toUpperCase() +
                      item?.title?.slice(1)}
                  </td>
                  <td>
                    {item.status === "active" ? (
                      <Button
                        className="btn-sm"
                        variant="success"
                        onClick={() => handleUpdateStatus(item)}
                      >
                        {item.status === "active" ? "Active" : "InActive"}
                      </Button>
                    ) : (
                      <Button
                        className="btn-sm"
                        variant="danger"
                        onClick={() => handleUpdateStatus(item)}
                      >
                        {item.status === "active" ? "Active" : "InActive"}
                      </Button>
                    )}
                  </td>
                  <td>
                    <Button
                      variant="warning"
                      className="me-3"
                      onClick={() => handleEdit(item)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <ReactPaginate
          nextLabel=">"
          pageRangeDisplayed={10}
          marginPagesDisplayed={2}
          previousLabel="<"
          pageClassName="page-item"
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pageCount={totalPages}
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination mx-3 p-2"
          activeClassName="active"
          forcePage={currentPage}
          renderOnZeroPageCount={null}
        />
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Form method="post" onSubmit={handleSave}>
            <Modal.Header closeButton>
              <Modal.Title className="fw-bold ">
                {selectedItem ? "Edit UserType" : "Add UserType"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className="fw-bold">Title:</Form.Label>
                    {/* <Form.Control
                      type="text"
                      required
                      value={inputTitle}
                      placeholder="Enter Title"
                      onChange={(e) => setInputTitle(e.target.value)}
                    /> */}
                          <Form.Select
                      as="select"
                      required
                      value={inputTitle}
                      onChange={(e) => setInputTitle(e.target.value)}
                    >
                      <option value="" disabled hidden>
                        Select Title
                      </option>
                      <option value="superAdmin">SuperAdmin</option>
                      <option value="admin">Admin</option>
                      <option value="guest">Guest</option>
                      <option value="customer">Customer</option>
                      <option value="manager">Manager</option>
                      <option value="cook">Cook</option>   
                      <option value="waiter">Waiter</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className="fw-bold">Key:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={inputKey}
                      placeholder="Enter Key"
                      onChange={(e) => setInputKey(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className="fw-bold">
                      Can Restaurant See:
                    </Form.Label>
                    <Form.Select
                      as="select"
                      required
                      value={inputRestaurantSee}
                      onChange={(e) => setInputRestaurantSee(e.target.value)}
                    >
                      <option value="" disabled hidden>
                        Select Restaurant See
                      </option>
                      <option value="active">Active</option>
                      <option value="inactive">inActive</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="mt-4">
              <Button variant="danger" onClick={handleCloseModal}>
                Close
              </Button>
              <Button variant="warning" type="submit">
                {selectedItem ? "Save Changes" : "Add UserType"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default UserType;
