import React from 'react';
import { Container } from 'react-bootstrap';

import { ReservationTables } from "../../../JsonData.js";
import TablesCards from "./TablesCards.js";

function Tables() {
    return <Container>
        <TablesCards title={'Reservation Tables'} ReservationTables={ReservationTables}/>
    </Container>
    ;
}

export default Tables;