import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Col, Row } from "react-bootstrap";

import { stripePayment } from "../../../redux/features/waiter/Stripe/waiterStripeAction";

import { useSelector, useDispatch } from "react-redux";
import { publicCountry } from "../../../redux/features/superAdmin/Country/createAction";
import { addBranches } from "../../../redux/features/admin/Branches/branchesAction";

function AddBranch(props) {
  const dispatch = useDispatch();
  const { countries } = useSelector((state) => state.country);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [logo, setLogo] = useState("");
  const [country, setCountry] = useState("");
  useEffect(() => {
    dispatch(publicCountry());
  }, []);

  const handleSave = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("branchName", name);
    formData.append("address", address);
    formData.append("countryId", country);
    formData.append("logo", logo);

    dispatch(addBranches({ data: formData }));
  };

  return (
    <>
      <Modal {...props} centered>
        <Form method="post" onSubmit={handleSave}>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold ">Add Branch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="fw-bold">Name:</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    placeholder="Enter Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Form.Group>
                    <Form.Label className="fw-bold">Address:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter Address"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="fw-bold">Country:</Form.Label>
                  <Form.Select
                    aria-label="select"
                    value={country}
                    required
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option value="" disabled hidden>
                      Select Country
                    </option>
                    {countries.map((countries) => (
                      <option key={countries._id} value={countries._id}>
                        {countries.name}
                      </option>
                    ))}
                    ;
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className="fw-bold">Logo:</Form.Label>
                  <Form.Control
                    type="file"
                    required
                    placeholder="Logo"
                    onChange={(e) => setLogo(e.target.files[0])}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="mt-4">
            <Button variant="warning" type="submit">
              Add Branch
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default AddBranch;
