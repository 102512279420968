import React from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';


const Setting = () => {
    return (
        <>
        <div style={{margin: '60px 20px 300px 20px', padding:'40px ', backgroundColor:'white'}}>
        <h3 className='px-3 pb-3 fw-bold'>Settings</h3>
        <Container>
            
                <Form>
                    <Row className="mb-5">
                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label className='fw-bold'>Restaurant Name </Form.Label>
                            <Form.Control type="name" placeholder="Enter Restaurant Name" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridAddress">
                            <Form.Label className='fw-bold'>Address</Form.Label>
                            <Form.Control type="text" placeholder="13th Street 47 W St, New York..." />
                        </Form.Group>
                    </Row>

                    <Row className='my-4'>
                    <Form.Group as={Col} className="mb-3" controlId="formGridService">
                        <Form.Label className='fw-bold'>Service Charges</Form.Label>
                        <Form.Control type='number' placeholder="11.50" />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridState">
                            <Form.Label className='fw-bold'>Service Charges Per Person</Form.Label>
                            <Form.Select defaultValue="yes">
                                <option>Yes</option>
                                <option>No</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <Row className="mb-4">
                        <Form.Group as={Col} className="mb-3" controlId="formGridTax">
                            <Form.Label className='fw-bold'>Tax (%)</Form.Label>
                            <Form.Control type='number' placeholder="5" />
                        </Form.Group>

                        {/* <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>State</Form.Label>
                            <Form.Select defaultValue="Choose...">
                                <option>Choose...</option>
                                <option>...</option>
                            </Form.Select>
                        </Form.Group> */}

                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label className='fw-bold'>Restaurant NTN </Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                    </Row>
                    
                    <Button variant="warning" type="submit" className='outline-warning px-5' size="lg">
                        Update
                    </Button>
                    
                    
                </Form>

            </Container>
        </div>
            

        </>
    );
};
export default Setting;