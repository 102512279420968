import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


import DashboardOrders from "./DashboardOrders";
import { SuperAdminDashboardData } from "../../../JsonData";

import LatestOrders from "./LatestOrdersTable";
import MostOrder from "./MostOrder";

function SuperAdminDashboard() {

    return (
        <div>
            <Container className='mt-5'>
                <DashboardOrders DashboardTopCards={SuperAdminDashboardData.topCards} />
                <LatestOrders LatestOrders={SuperAdminDashboardData.latestOrders} />
                <MostOrder MostOrders={SuperAdminDashboardData.mostOrders} />
            </Container>

            
        </div>

    );
}

export default SuperAdminDashboard;