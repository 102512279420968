import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import dashboardService from "./dashboardService";

const initialState = {
  dashboard: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const dashboard = createAsyncThunk(
  "admin/dashboard",
  async (branchId, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await dashboardService.getDashboard(user.accessToken, branchId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(dashboard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(dashboard.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.dashboard = action.payload.body;
      })
      .addCase(dashboard.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.dashboard = [];
      });
  },
});

export const { reset } = dashboardSlice.actions;

export default dashboardSlice.reducer;
