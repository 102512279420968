import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const PAY_STACK_KEY = process.env.REACT_APP_PAYSTACK_KEY;

const payStack = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_KEY}`,
    },
  };
  const response = await axios.post(
    `https://api.paystack.co/transaction/initialize`,
    data,
    config
  );
  if (response.data.data) {
    return response.data.data;
  }
};

const payStackVerfiy = async (reference) => {
  const config = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_KEY}`,
    },
  };
  const response = await axios.get(
    `https://api.paystack.co/transaction/verify/${reference}`,
    config
  );
  if (response.data) {
    return response.data;
  }
};

const paymentTransaction = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/waiter/paymentTransaction`,
    data
  );
  if (response.data.body) {
    return response.data;
  }
};

const waiterPayStackService = {
  payStack,
  payStackVerfiy,
  paymentTransaction,
};

export default waiterPayStackService;
