import React,{useEffect,useState} from "react";
import { NavLink } from 'react-router-dom';
import logo from "../../tittle logo.png"
import PingLogo from "../../ping logo.png"


import {
  CDBSidebar,
  CDBSidebarContent,
  // CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,

} from 'cdbreact';

import { Routes } from '../../route/Routes';

const Sidebar = ({ role }) => {
  const [data, setdata] = useState({});

  const sidebarStyles = {
    backgroundColor:'#1c232f',
    color: '#fff',
  };

  const itemStyles = {
    margin: '0px',
    color: '#fff',
    fontWeight: 'bold',
    textDecoration: 'none',
    transition: 'background-color 0.3s ease',
  };

  const itemHoverStyles = {
    backgroundColor: 'black',
  };

  useEffect(() => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  setdata(userData);
  }, []);

  const routesForRole = Routes[role];

  const filteredroutes = routesForRole?.filter((item) => item.showInNav);

  return (
    <>
  
   
    <div style={{ display: 'flex',  height: '200vh'}}>
      <CDBSidebar activeClassName="sidebarStyles">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>          
          <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
          <img src={PingLogo} alt='Logo'/>
            <img src={logo} alt='Logo'/>
          </a>
        </CDBSidebarHeader>
        <CDBSidebarContent className="sidebarStyles">
        <CDBSidebarMenu>
        {filteredroutes?.map((item) => (
            <NavLink exact style={itemStyles} hoverstyle={itemHoverStyles} to={item.route} activeClassName="activeClicked">
              <CDBSidebarMenuItem icon={item.icon}>{item.label}</CDBSidebarMenuItem>
            </NavLink>
        ))}
          </CDBSidebarMenu>

          </CDBSidebarContent>
{/* { data.userType =='admin' ?
        <SidebarAdmin/>
:
<SidebarSuperAdmin/>
}   */}
        {/* <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            style={{
              padding: '20px 5px',
            }}
          >
            Sidebar Footer
          </div>
        </CDBSidebarFooter> */}
      </CDBSidebar>
    </div> 
    </>
  );
};

export default Sidebar;