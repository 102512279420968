import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bankService from "./bankService";

const initialState = {
  bank: {},
  verifyStripe:{},
  stripeAccount:{},
  isError: false,
  isSuccess: false,
  isSuccessVerify: false,
  isLoading: false,
  message: "",
};

//add bank
export const addBank = createAsyncThunk(
  "admin/addBank",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await bankService.addBank(user.accessToken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateBank = createAsyncThunk(
  "admin/updateBank",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await bankService.updateBank(user.accessToken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


//get bank
export const getBank = createAsyncThunk(
  "admin/getBank",
  async ({branchId}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await bankService.getBank(user.accessToken, branchId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const createStripeAccount = createAsyncThunk(
  "admin/createStripeAccount",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await bankService.createStripe(user.accessToken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateStripeAccount = createAsyncThunk(
  "admin/updateStripeAccount",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await bankService.updateStripe(user.accessToken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const  verifyStripeAccount = createAsyncThunk(
  "admin/verifyStripeAccount",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await bankService.verifyStripe(user.accessToken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getStripeAccountInformation = createAsyncThunk(
  "admin/getStripeAccountInformation",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await bankService.getStripeAccountInformation(user.accessToken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.isSuccessVerify= false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBank.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBank.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.bank = action.payload.body;
      })
      .addCase(addBank.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getBank.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBank.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.bank = action.payload?.body;
      })
      .addCase(getBank.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createStripeAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createStripeAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessVerify = true;
        state.bank = action.payload.body;
      })
      .addCase(createStripeAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateBank.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBank.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.bank = action.payload.body;
      })
      .addCase(updateBank.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateStripeAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateStripeAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessVerify = true;
        state.bank = action.payload.body;
      })
      .addCase(updateStripeAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(verifyStripeAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyStripeAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.verifyStripe = action.payload.body;
      })
      .addCase(verifyStripeAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
       .addCase(getStripeAccountInformation.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getStripeAccountInformation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.stripeAccount = action.payload.body;
      })
      .addCase(getStripeAccountInformation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});
export const { reset } = bankSlice.actions;

export default bankSlice.reducer;
