import React, { useState, useEffect } from "react";
import { Modal, Button, Row } from "react-bootstrap";
import QRCode from "react-qr-code";

function StripePaymentModal(props) {
  return (
    <>
      <Modal {...props} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold ">Qr Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row id="qr-code-container">
            <div className="text-center">
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "30%" }}
                value={`${window.location.origin}/payment/${props.orderInformation._id}`}
                viewBox={`0 0 50 50`}
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer className="mt-4">
          <Button variant="success">Complete Order</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StripePaymentModal;
