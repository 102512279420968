import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const getAllCategory = async (token, pageNo) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/categories/list?page=${pageNo}`,
    config
  );
  if (response.data.body) {
    return response.data;
  }
};

const getAllCategoryByBranchId = async (
  token,
  branchId,
  pageNo,
  searchQuery
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/categories/list/branch?branchId=${branchId}&page=${pageNo}&search=${
      searchQuery ? searchQuery : ""
    }`,
    config
  );
  if (response.data.body) {
    return response.data;
  }
};

const addCategory = async (token, branchId, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/admin/categories/add?branchId=${branchId}&page=${data?.pageNo}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Added Category");
    return response.data;
  }
};

const updateCategory = async (token, data, branchId, search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/admin/categories/${data._id}?page=${
      data.pageNo
    }&branchId=${branchId}&search=${search ? search : ""}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Update Category");
    return response.data.body;
  }
};

const updateCategoryStatus = async (token, data, branchId, search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/admin/categories/status/${data._id}?page=${
      data.pageNo
    }&branchId=${branchId}&search=${search ? search : ""}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Update Category Status");
    return response.data.body;
  }
};

const getActiveCategory = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/categories/active?isActive=${true}`,
    config
  );
  if (response.data.body) {
    return response.data.body;
  }
};

const getActiveCategoryByBranchId = async (token, branchId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/categories/active/branch?branchId=${branchId}&isActive=${true}`,
    config
  );
  if (response.data.body) {
    return response.data.body;
  }
};

const getActiveCategoryMenu = async (token, branchId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/categories/menu?branchId=${branchId}`,
    config
  );
  if (response.data.body) {
    return response.data.body;
  }
};

const categoryService = {
  getAllCategory,
  getAllCategoryByBranchId,
  addCategory,
  updateCategory,
  updateCategoryStatus,
  getActiveCategory,
  getActiveCategoryByBranchId,
  getActiveCategoryMenu,
};

export default categoryService;
