import React, { useState } from "react";
import {
  Button,
  Modal,
  Col,
  Row,
  Collapse,
  ListGroup,
  Form,
} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

function VariantModal(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [accordionSelectedOptions, setAccordionSelectedOptions] = useState([]);

  const handleVariantOption = (option) => {
    const isSelect = selectedOptions.some(
      (selected) => selected._id === option._id
    );
    if (!isSelect) {
      setSelectedOptions((prevOptions) => [...prevOptions, option]);
    } else {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((selected) => selected._id !== option._id)
      );
    }
    const selectedOptionsForAccordion =
      accordionSelectedOptions[option.variantId] || [];
    const isSelected = selectedOptionsForAccordion.some(
      (selected) => selected._id === option._id
    );

    if (!isSelected) {
      setAccordionSelectedOptions((prevOptions) => ({
        ...prevOptions,
        [option.variantId]: option.variant.isMutiSelection
          ? [...selectedOptionsForAccordion, option]
          : [option],
      }));
    } else {
      setAccordionSelectedOptions((prevOptions) => ({
        ...prevOptions,
        [option.variantId]: selectedOptionsForAccordion.filter(
          (selected) => selected._id !== option._id
        ),
      }));
    }
  };
  const uniqueVariantTitles = [
    ...new Set(
      props?.menuVariantData?.menuvariants
        ?.filter((i) => i?.variant?.isActive)
        ?.map((i) => i?.variant?.title)
    ),
  ];

  const handleCloseModal = () => {
    setSelectedOptions([]);
    setAccordionSelectedOptions([]);
    props.onHide();
  };
  return (
    <Modal centered {...props} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.menuVariantData?.title}{" "}
          <span className="text-right">{props.menuVariantData?.price}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Collapse in={open}>
          <div id="example-collapse-text">
            <div>
              {uniqueVariantTitles?.map((variantTitle, index) => {
                // Find the first menuVariant with the matching variant title
                const menuVariant = props?.menuVariantData?.menuvariants?.find(
                  (i) => i.variant.title === variantTitle
                );
                return (
                  <Row>
                    <Col lg={12}>
                      <Accordion
                        key={index}
                        defaultActiveKey="0"
                        style={{
                          width: "80%",
                          paddingBottom: "10px",
                          marginLeft: "45px",
                        }}
                      >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>{variantTitle}</Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <ListGroup>
                                {props?.menuVariantData?.menuvariants
                                  .filter(
                                    (option) =>
                                      option.variantId ===
                                      menuVariant.variant._id
                                  )
                                  .map((option) => (
                                    <ListGroup.Item key={option?._id}>
                                      <div className="d-flex justify-content-between">
                                        <div>
                                          <b>
                                            {option?.variantOption?.title
                                              .charAt(0)
                                              ?.toUpperCase() +
                                              option?.variantOption?.title?.slice(
                                                1
                                              )}
                                          </b>{" "}
                                          <b>
                                            (+
                                            {option?.price})
                                          </b>
                                        </div>
                                        <div className="text-end">
                                          {option?.variant.isMutiSelection ? (
                                            <Form.Check
                                              type={"checkbox"}
                                              onChange={() =>
                                                handleVariantOption(option)
                                              }
                                            />
                                          ) : (
                                            <Form.Check
                                              type={"radio"}
                                              reverse
                                              name={`variantRadioGroup-${variantTitle}`}
                                              onChange={() =>
                                                handleVariantOption(option)
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </ListGroup.Item>
                                  ))}
                              </ListGroup>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                  </Row>
                );
              })}
            </div>
            {props?.menuVariantData?.menuallergies?.length != 0 ? (
              <b className="mx-4 my-5 text-danger">Allergens</b>
            ) : (
              ""
            )}
            <p>
              <b className="mx-5 my-2">
                {props?.menuVariantData?.menuallergies?.filter((i) => i?.allergies?.isActive).map((i, index) => (
                  <React.Fragment key={index}>
                    {i?.allergies?.title?.charAt(0)?.toUpperCase() +
                      i?.allergies?.title?.slice(1)}
                    {index < props?.menuVariantData?.menuallergies.length - 1 &&
                      " "}
                  </React.Fragment>
                ))}
              </b>
            </p>
            <div className="mx-2 my-2 text-end">
              <Button
                variant={"warning"}
                onClick={() =>
                  props.handleAddOrderItem(
                    accordionSelectedOptions,
                    props?.menuVariantData,
                    setAccordionSelectedOptions,
                    setSelectedOptions
                  )
                }
              >
                Add
              </Button>
            </div>
          </div>
        </Collapse>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            props.onHide();
          }}
        >
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}
export default VariantModal;
