import React, { useContext, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { useHistory } from "react-router-dom";

function Logout() {
  const { setCurrentUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    setCurrentUser(null);
    localStorage.removeItem("currentUser");
    localStorage.removeItem("persist:root");
    localStorage.clear();
    history.push("/");
    window.location.href = "/";
  }, []);
}

export default Logout;
