import React from 'react'
import {Container} from "react-bootstrap"

import QrCodeImg from "../../Images/QR-code/QR-code.png";

import ReactDOM from "react-dom";
import QRCode from "react-qr-code";

const QrCode = () => {
    return ( <Container>
            <h2 className='my-4'>QR Code</h2>
            {/* <img src={QrCodeImg} alt='barCode-img' width={400}/> */}
            <QRCode
    size={256}
    style={{ height: "auto", maxWidth: "100%", width: "30%" }}
    value="http://localhost:3000/res/1/2"
    viewBox={`0 0 100 100`}
    />
        </Container>
    );
};

export default QrCode;