import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import kitchenOrderService from "./kitchenOrderService";

const initialState = {
  kitchenOrders: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const allOrder = createAsyncThunk(
  "cook/allOrder",
  async (branchId, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await kitchenOrderService.getAllorder(user.accessToken, branchId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getComingOrder = createAsyncThunk(
  "cook/getComingOrder",
  async (branchId, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await kitchenOrderService. getComingOrder(user.accessToken, branchId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update order
export const updateOrder = createAsyncThunk(
  "cook/updateOrder",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await kitchenOrderService.updateOrder(user.accessToken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const kitchenOrderSlice = createSlice({
  name: "kitchenOrder",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.kitchenOrders = action.payload.body;
      })
      .addCase(allOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.kitchenOrders = [];
      })
      .addCase(getComingOrder.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getComingOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.kitchenOrders = action.payload.body;
      })
      .addCase(getComingOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.kitchenOrders = [];
      })
      .addCase(updateOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.kitchenOrders = action.payload;
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
  },
});

export const { reset } =
kitchenOrderSlice.actions;

export default kitchenOrderSlice.reducer;
