import React from 'react'
import { Row, Col, Card, Button, Table, Accordion, ProgressBar } from 'react-bootstrap';

function MostOrder(props) {
    // console.log('mostorder');
    // console.log(props.MostOrders);
    // return
    return <>
    <div>
    <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0" className='my-5 '>
                <Accordion.Header style={{color:'yellow'}}>  HOT ITEMS - MOST ORDERED </Accordion.Header>
                <Accordion.Body>
                    {props.MostOrders.map((val, ind) => {
                        return <>
                            <div className='d-flex justify-content-between text-center' key={ind}>
                                <h6>{val.foodItem}</h6>
                                <h6>{val.soldQuantity} / {val.totalQuantity}</h6>
                            </div>
                            <ProgressBar striped animated variant={val.variant} now={val.soldQuantity} className='my-2' />
                        </>
                    }
                    )}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    </div>
        
    </>

        ;
}

export default MostOrder