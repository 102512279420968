import React from "react";
import { useState, useEffect, useRef } from "react";

import {
  Container,
  Form,
  Col,
  Row,
  Button,
  Nav,
  Tab,
  Card,
  Table,
  Tabs,
} from "react-bootstrap";
import { FaTimes } from "react-icons/fa";

import Asun from "../../Images/Menu-Items/Asun.png";
import Prawns from "../../Images/Menu-Items/Sautted-Prawns.jpg";
import GrilledSalad from "../../Images/Menu-Items/Grilled-Halloumi-Salad.jpg";
import ToyoSuya from "../../Images/Menu-Items/Tozo-Suya.jpg";
import Grill_Category_1 from "../../Images/Grills-Category/Grill-Category-1.jpg";
import Grill_Category_2 from "../../Images/Grills-Category/Grill-Category-2.jpg";
import Grill_Category_3 from "../../Images/Grills-Category/Grill-Category-3.jpg";
import Grill_Category_4 from "../../Images/Grills-Category/Grill-Category-4.jpg";
import Grill_Category_5 from "../../Images/Grills-Category/Grill-Category-5.jpg";
import Grill_Category_6 from "../../Images/Grills-Category/Grill-Category-6.jpg";
import Grill_Category_7 from "../../Images/Grills-Category/Grill-Category-7.jpg";
import Grill_Category_8 from "../../Images/Grills-Category/Grill-Category-8.jpg";
import Grill_Category_9 from "../../Images/Grills-Category/Grill-Category-9.jpg";
import Grill_Category_10 from "../../Images/Grills-Category/Grill-Category-10.jpg";
import Grill_Category_11 from "../../Images/Grills-Category/Grill-Category-11.jpg";
import Grill_Category_12 from "../../Images/Grills-Category/Grill-Category-12.jpg";
import Grill_Category_13 from "../../Images/Grills-Category/Grill-Category-13.jpg";
import Grill_Category_14 from "../../Images/Grills-Category/Grill-Category-14.jpg";
import Grill_Category_15 from "../../Images/Grills-Category/Grill-Category-15.jpg";
import Grill_Category_16 from "../../Images/Grills-Category/Grill-Category-16.jpg";
// import Grill_Category_17 from "./components/Images/Grills-Category/Grill-Category-17.jpg"
import Grill_Category_18 from "../../Images/Grills-Category/Grill-Category-18.jpg";

import dimSims from "../../Images/Menu-Items/second-menu-6.jpeg";
import desi from "../../Images/Menu-Items/MenuItems_4.jpg";
import biryani from "../../Images/Menu-Items/second-menu-5.png";
import tikka from "../../Images/Menu-Items/second-menu-4.jpeg";
import cake from "../../Images/Menu-Items/second-menu-2.jpeg";
import pizza from "../../Images/Menu-Items/MenuItems_5.jpg";
import burger from "../../Images/Menu-Items/MenuItems_6.jpg";

// import MenuItemsCard from "./NewOrderMenuCard";
import { NewOrderMenuData } from "../../../JsonData";
import NewOrderMenuCard from "./NewOrderMenuCard";
import { useDispatch, useSelector } from "react-redux";
import Invoice from "./Invoice";
import { useReactToPrint } from "react-to-print";
import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import KitchenOrderModal from "./KitchenOrderModal";
import DiningOrderModal from "./DiningOrderModal";

import {
  addOrder,
  reset,
  clearOrder,
  getDiningAndProcessOrder,
} from "../../../redux/features/admin/Order/orderSlice";
import { getOrderStatus } from "../../../redux/features/admin/Order/orderSlice";
import GeneralIndicator from "../../model/GeneralIndicator";

// import MenuItems from "./MenuItems";
// import { MenuTableData } from "./JsonData";

const NewOrder = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const componentRef = useRef();

  const { tableId } = useParams();
  const { isLoading, isError, isSuccess, message, orderStatus } = useSelector(
    (state) => state.order
  );

  const { selectBranches } = useSelector((state) => state.branches);

  const [discountType, setDiscountType] = useState("flat");
  const [discountValue, setDiscountValue] = useState(0);
  const [cashReceived, setCashReceived] = useState(0);
  const [table, setTable] = useState("");
  const [cashChange, setCashChange] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [id, setId] = useState(0);
  const [orderInformation, setOrderInformation] = useState({});
  const [product, setProduct] = useState([]);
  const [tableValue, settableValue] = useState("");
  const [orderId, setOrderId] = useState("");

  const [showKitchenModal, setShowKitchenModal] = useState(false);
  const [showDiningModal, setShowDiningModal] = useState(false);

  const data = {
    _id: orderId,
    totalAmount: totalAmount,
    cashReceived: cashReceived,
    cashChange: cashChange,
    discount: discountValue,
    discountType: discountType,
    tableId: table,
    status: "complete",
  };
  const handleSave = () => {
    dispatch(addOrder(data));
  };

  useEffect(() => {
    setTable("Select Table");
    setOrderId("");
    setId("");
  }, [selectBranches]);

  useEffect(() => {
    dispatch(getDiningAndProcessOrder(selectBranches[0]));
  }, [selectBranches]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getDiningAndProcessOrder(selectBranches[0]));
    }, 5000);

    return () => clearInterval(interval);
  }, [selectBranches]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      handlePrint();
      // history.push("/admin/order");
      setTable("");
      if (selectBranches.length > 0 && selectBranches[0] !== undefined) {
        dispatch(getOrderStatus({ branchId: selectBranches[0], tableId: id }));
      }
      setDiscountType("flat");
      setDiscountValue(0);
      setCashReceived(0);
      setCashChange(0);
      setTotalAmount(0);
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  const handleAfterPrint = React.useCallback(() => {
    // window.location.reload();
  }, []);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  const [items, setItems] = useState([
    { id: 1, name: "Asun", price: 25, quantity: 6 },
    { id: 2, name: "Sautted Prawns", price: 9, quantity: 2 },
  ]);
  let subAmount = 0;

  const handleDelete = (id) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
  };

  const handlePriceChange = (id, event) => {
    const updatedItems = items.map((item) => {
      if (item.id === id) {
        return { ...item, price: parseInt(event.target.value) };
      }
      return item;
    });
    setItems(updatedItems);
  };
  const handleClearOrder = () => {
    let data = {
      id: id,
      tableId: table,
    };
    dispatch(clearOrder(data));
  };

  const handleQuantityChange = (id, value) => {
    const updatedItems = items.map((item) => {
      if (item.id === id) {
        const newQuantity = Math.max(0, item.quantity + value);
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setItems(updatedItems);
  };

  const subtotal = items.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  );
  const tax = 0;
  const serviceCharges = 0;
  const discountTypes = "";
  const discount = 0;
  const total = subtotal + tax + serviceCharges - discount;

  const [cashReceivedTab, setCashReceivedTab] = useState(0);

  const handleCashReceivedChange = (event) => {
    setCashReceivedTab(event.target.value);
  };

  const handleTable = (item) => {
    let id = item?.table?._id;
    setTable(id);
    // history.push(`/admin/order/${id}`);
    dispatch(getOrderStatus({ branchId: selectBranches[0], tableId: id }));
    setOrderId(item?._id);
    setShowKitchenModal(false);
    setShowDiningModal(false);
  };

  // useEffect(() => {
  //   // if (table !== undefined && table !== "") {
  //   //   history.push(`/admin/order/${table}`);
  //   // }
  //   if (table === undefined) {
  //     setTable(table);
  //   }
  // }, [table]);

  // useEffect(() => {
  //   if (table !== undefined && table !== null) {
  //     dispatch(getOrderStatus({ branchId: selectBranches[0], tableId: table }));
  //   }
  // }, [table]);

  const handleCashReceivedSubmit = (event) => {
    event.preventDefault();
    // Calculate the change
    const change = cashReceived - total;
    // Update the state
    setCashReceivedTab(change);
  };

  const addMyValue = (value) => {
    let totalAmount = subAmount + value;
    // return
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    items.forEach((item) => {
      subtotal += item.price * item.quantity; // Calculate subtotal based on price and quantity
    });
    return subtotal;
  };

  const calculateTotal = () => {
    const subtotal = calculateSubtotal();
    const tax = 0;
    const serviceCharges = 0;
    const discount = 0;
    return subtotal + (subtotal * tax) / 100 + serviceCharges - discount;
  };

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");

  const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return (
    <div className="bg-white px-3" style={{ margin: "  50px 20px 230px 20px" }}>
      {isLoading ? <GeneralIndicator /> : null}
      <div className="my-4">
        <b>Kitchen:</b>
        {/* {orderStatus?.processingOrders?.map(
          (item) => (
            <Button
              onClick={() => {
                handleTable(item);
              }}
              variant="warning mx-2"
              style={{ width: "60" }}
            >
              {item?.table?.orderTable}:{item?.customerId?.username}
            </Button>
          )
        )} */}
        <Button
          variant="warning mx-2"
          style={{ width: "60" }}
          onClick={() => {
            setShowKitchenModal(true);
          }}
        >
          {orderStatus?.processingOrders?.length
            ? orderStatus?.processingOrders?.length
            : 0}
        </Button>
      </div>
      <div className="my-4">
        <b>Dining:</b>
        {/* {orderStatus?.diningOrders?.map(
          (item) => (
            <Button
              variant="warning mx-2"
              style={{ width: "60" }}
              onClick={() => {
                handleTable(item);
              }}
            >
             {item?.table?.orderTable}:{item?.customerId?.username}
            </Button>
          )
          )} */}
        <Button
          variant="warning mx-2"
          style={{ width: "60" }}
          onClick={() => {
            setShowDiningModal(true);
          }}
        >
          {orderStatus?.diningOrders?.length
            ? orderStatus?.diningOrders?.length
            : 0}
        </Button>
      </div>
      {/* <div className="my-4">
        <b>Complete: </b>
        {orderStatus?.sendToCompleteOrders?.map(
          (item) => (
            <Button
              variant="warning mx-2"
              style={{ width: "60" }}
              onClick={() => {
                handleTable(item);
              }}
            >
             {item?.table?.orderTable}:{item?.customerId?.username}
            </Button>
          )
          )}
      </div> */}

      <Form className="my-2">
        <Row>
          <Col lg={2} className="my-3">
            {/* <Form.Group as={Col} controlId="formGridState">
              <Form.Select defaultValue="Choose...">
                <option>Tom Cruise</option>
                <option>Jhon Wick</option>
                <option>Camela Actor</option>
                <option>Mike Anderson</option>
              </Form.Select>
            </Form.Group> */}
          </Col>
          <Col lg={2} className="my-3">
            <Form.Group as={Col} controlId="formGridState">
              {/* <Form.Select defaultValue="Choose...">
                <option>Visit Customer</option>
              </Form.Select> */}
            </Form.Group>
          </Col>
          <Col lg={3} className="my-3">
            <Form.Group as={Col} controlId="formGridState">
              <Form.Select
                aria-label="select"
                value={table}
                onChange={(e) => {
                  setTable(e.target.value);
                  setOrderId(orderInformation?.orderItemList[0]?.orderId);
                  if (e.target.value === "Select Table") {
                    dispatch(
                      getOrderStatus({
                        branchId: selectBranches[0],
                        tableId: null,
                      })
                    );
                  } else {
                    setId(e.target.value);
                    dispatch(
                      getOrderStatus({
                        branchId: selectBranches[0],
                        tableId: e.target.value,
                      })
                    );
                  }
                }}
              >
                <option selected>Select Table</option>
                {orderInformation?.restaurantInformation?.ttable?.map(
                  (item, i) => (
                    <option key={item?._id} value={item?._id}>
                      {item?.orderTable}
                    </option>
                  )
                )}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={5} className="my-3 d-flex">
            {/* <Button variant="danger" onClick={handleClearOrder}>
              {" "}
              Clear Order
            </Button> */}
            {/* <Button variant="warning mx-3"> Kitchen Print</Button> */}
            {cashReceived >= totalAmount &&
            cashReceived > 0 &&
            totalAmount > 0 ? (
              <Button variant="success mx-2" onClick={handleSave}>
                Complete Order
              </Button>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Form>
      <Row>
        <Col lg={12}>
          <NewOrderMenuCard
            discountType={discountType}
            setDiscountType={setDiscountType}
            discountValue={discountValue}
            setDiscountValue={setDiscountValue}
            cashReceived={cashReceived}
            setCashReceived={setCashReceived}
            cashChange={cashChange}
            setCashChange={setCashChange}
            totalAmount={totalAmount}
            setTotalAmount={setTotalAmount}
            product={product}
            setProduct={setProduct}
            setSubTotal={setSubTotal}
            subTotal={subTotal}
            setId={setId}
            setTable={setTable}
            setOrderInformation={setOrderInformation}
            orderId={orderId}
            tableId={table}
            setOrderId={setOrderId}
          />
        </Col>
        {/* <Col lg={3}>
        <Table>
          <tbody>
            <tr>
              <td>Subtotal</td>
              <td>{calculateSubtotal()}</td>
            </tr>
            <tr>
              <td>Tax (0%)</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Service Charges</td>
              <td></td>
            </tr>
            <tr>
              <td>Discount Type</td>
              <td></td>
            </tr>
            <tr>
              <td>Discount</td>
              <td></td>
            </tr>
            <tr>
              <td>Total</td>
              <td>{calculateTotal()}</td>
            </tr>
            <tr>
              <td>Cash Received</td>
              <td></td>
            </tr>
            <tr>
              <td>Cash Received Change</td>
              <td></td>
            </tr>
          </tbody>
        </Table>
        </Col> */}
        {/* <Col lg={3}>
                <h2>Summary</h2>
                <Table striped bordered>
                    <tbody>
                        <tr>
                            <th>Subtotal</th>
                            <td>{calculateSubtotal()}</td>
                        </tr>
                        <tr>
                            <th>Tax (0%)</th>
                            <td>0</td>
                        </tr>
                        <tr>
                            <th>Service Charges</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Discount Type</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Discount</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Total</th>
                            <td>{calculateTotal()}</td>
                        </tr>
                        <tr>
                            <th>Cash Received</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Cash Received Change</th>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </Col> */}
        {/* <Col lg={3} md={2}>
                <Table striped bordered className='mt-4'>
                    <tbody>
                        <tr>
                            <th>Subtotal</th>
                            <td>{subtotal}</td>
                        </tr>
                        <tr>
                            <th>Tax (0%)</th>
                            <td>{tax}</td>
                        </tr>
                        <tr>
                            <th>Service Charges:</th>
                            <td>{serviceCharges}</td>
                        </tr>
                        <tr>
                            <th>Discount Type:</th>
                            <td>
                                <div className='d-flex justify-content-between text-center'>
                                        <input type='radio' name='flat-discount' id="flat-discount"/>
                                        <label className='px-2'> Flat</label>
                                        <input type='radio' name='percentage-discount' id="percentage-discount"/>
                                        <label className='px-2'> Percentage</label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Discount</th>
                            <td>{discount}</td>
                        </tr>
                        <tr>
                            <th>Total</th>
                            <td>{total}</td>
                        </tr>
                        <tr>
                            <th>Cash Received:</th>
                            <td>
                                <Form onSubmit={handleCashReceivedSubmit}>
                                    <Form.Control
                                        type="number"
                                        placeholder="Cash Received"
                                        onChange={handleCashReceivedChange}
                                    />
                                </Form>
                            </td>
                        </tr>
                        <tr>
                            <th>Change:</th>
                            <td>{cashReceived >= total ? cashReceived - total : '+'}</td>
                        </tr>
                    </tbody>
                </Table>
            </Col> */}
      </Row>

      <ReactToPrint
      // trigger={handlePrint}
      // removeAfterPrint
      />
      {isSuccess ? (
        <Invoice
          orderInformation={orderInformation}
          totalAmount={totalAmount}
          cashChange={cashChange}
          cashReceived={cashReceived}
          ref={componentRef}
          currentDateTime={currentDateTime}
          discountType={discountType}
          discountValue={discountValue}
        />
      ) : (
        ""
      )}

      <KitchenOrderModal
        onHide={() => setShowKitchenModal(false)}
        show={showKitchenModal}
        orderStatus={orderStatus}
        handleTable={handleTable}
      ></KitchenOrderModal>
      <DiningOrderModal
        onHide={() => setShowDiningModal(false)}
        show={showDiningModal}
        orderStatus={orderStatus}
        handleTable={handleTable}
      >
        {" "}
      </DiningOrderModal>
    </div>
  );
};
export default NewOrder;
