import React, { useState } from "react";
import { Card, Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping, faUser } from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";

function ItemCard({ item, handleAddDining }) {
  return (
    <>
      <Col lg={4} sm={12}>
        <Card className="my-3 p-2">
          <Card.Body>
            <div className="d-flex justify-content-between text-center fw-bolder h5">
              <Card.Text>
                {" "}
                <FontAwesomeIcon icon={faBagShopping} /> Table #.{" "}
                {item?.ttable?.orderTable}
              </Card.Text>
              <Card.Text>Name: {item?.customer?.username}</Card.Text>
            </div>
            <div>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Order Item</Accordion.Header>
                  <Accordion.Body>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Item Name</th>
                          <th>Quantity</th>
                          <th>Item Price</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item?.orderitem?.map((item, index) => (
                          <tr>
                            <td>{item?.menucategoeies?.title} 
                            <div>
                        {item?.variantOption?.map((option, index) => (
                          <div key={index}>
                          <b>
                            {option?.quantity}x
                            {option?.title?.charAt(0)?.toUpperCase() +
                              option?.title?.slice(1)}
                          </b>{' '}
                          </div>
                        ))}
                      </div>
                            </td>
                            <td>{item?.quantity}</td>
                            <td>{item?.itemPrice}</td>
                            <td>{item?.quantity*item?.itemPrice}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <Button
              className="me-3 my-3"
              variant={item?.status == 'dining'? "danger" : "warning"}
              onClick={() => handleAddDining(item)}
            >
              {item?.status == 'dining' ? "Dining" : "Send to Dining"}
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
export default ItemCard;
