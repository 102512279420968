import React from 'react';
import { Card, Button, Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagShopping, faUser } from '@fortawesome/free-solid-svg-icons';

function NotificationsCards(props) {
    return <Row>
        {props.NotificationData.map((val, ind) => {
            return <Col lg={6} sm={12} key={ind}>
                <Card className='my-3 p-2'>
                    <Card.Body>
                        <div className='d-flex justify-content-between text-center fw-bolder h5'>
                            <Card.Text> <FontAwesomeIcon icon={faBagShopping} /> OrderNo #. {val.orderNumber}</Card.Text>
                            <Card.Text>{val.tableNumber}</Card.Text>
                        </div>
                        <div className='d-flex justify-content-between text-center fw-bolder h5'>
                            <Card.Text> <FontAwesomeIcon icon={faUser} /> {val.CustomerName}</Card.Text>
                            <Card.Text> <FontAwesomeIcon icon={faBagShopping} /> {val.dateTime}</Card.Text>
                        </div>
                        <hr />
                        <Card.Text>Message</Card.Text>
                        <Card.Text className='fw-bold h5'>{val.message}</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            }
            )}
        </Row>
}

export default NotificationsCards;