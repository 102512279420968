import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import waiterOrderService from "./waiterOrderService";

const initialState = {
  waiterOrders: [],
  singleOrderData: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const allOrder = createAsyncThunk(
  "waiter/allOrder",
  async (branchId, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await waiterOrderService.getAllorder(user.accessToken, branchId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const singleOrder = createAsyncThunk(
  "waiter/singleOrder",
  async (id, thunkAPI) => {
    try {
      return await waiterOrderService.getSingleOrder(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getComingOrder = createAsyncThunk(
  "waiter/getComingOrder",
  async (branchId, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await waiterOrderService.getComingOrder(
        user.accessToken,
        branchId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update order
export const updateOrder = createAsyncThunk(
  "waiter/updateOrder",
  async (data, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await waiterOrderService.updateOrder(user.accessToken, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const waiterOrderSlice = createSlice({
  name: "waiterOrder",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.waiterOrders = action?.payload?.body;
      })
      .addCase(allOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.waiterOrders = [];
      })
      .addCase(getComingOrder.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getComingOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.waiterOrders = action.payload?.body;
      })
      .addCase(getComingOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.waiterOrders = [];
      })
      .addCase(updateOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.waiterOrders = action.payload;
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        state.waiterOrders = [];
      })
      .addCase(singleOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(singleOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.singleOrderData = action.payload;
      })
      .addCase(singleOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        state.singleOrderData = {};
      });
  },
});

export const { reset } = waiterOrderSlice.actions;

export default waiterOrderSlice.reducer;
