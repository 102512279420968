import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const PAY_STACK_KEY = process.env.REACT_APP_PAYSTACK_KEY;

const sendOtp = async (data) => {
  const response = await axios.put(`${BASE_URL}/auth/sendOtp`, data);
  if (response.data.body) {
    toast.success("Successfully Send Otp");
    return response.data.body;
  }
};
const verifyOtp = async (data) => {
  const response = await axios.put(`${BASE_URL}/auth/verifyOtp`, data);
  if (response.data) {
    toast.success("Pin Verified Successfully");
    return response.data;
  }
};

const payStack = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_KEY}`,
    },
  };
  const response = await axios.post(
    `https://api.paystack.co/transaction/initialize`,
    data,
    config
  );
  if (response.data.data.authorization_url) {
    const authorization_url = response.data.data.authorization_url;
    window.location.href = authorization_url;
    // window.open(authorization_url, '_blank');
    return response.data;
  }
};

const payStackVerfiy = async (reference) => {
  const config = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_KEY}`,
    },
  };
  const response = await axios.get(
    `https://api.paystack.co/transaction/verify/${reference}`,
    config
  );
  if (response.data) {
    return response.data;
  }
};

const otpService = {
  sendOtp,
  verifyOtp,
  payStack,
  payStackVerfiy,
};

export default otpService;
