import React, { useEffect, useState } from "react";
import { Button, Table, Form, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons"; // Replace 'faIconName' with the desired icon name
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

import { useSelector, useDispatch } from "react-redux";

import MenuItemsModal from "./MenuItemsModal";
import AddMenu from "./AddMenu";
import {
  updateMenuStatus,
  reset,
  allMenu,
  allMenuByBranchId,
} from "../../../redux/features/admin/Menu/menuSlice";
import ReactPaginate from "react-paginate";

// import Menu  ItemsData from "./MenuItemsData";
// import MenuTableData from "./ColleagueData";

function MenuItems(props) {
  const dispatch = useDispatch();

  const { selectBranches } = useSelector((state) => state.branches);

  const { isError, isSuccess, message, isLoading, totalPages } = useSelector(
    (state) => state.menu
  );

  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowMenu, setModalShowMenu] = React.useState(false);
  const [data, setData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [menuVariant, setMenuVariant] = useState([]);
  const [variantOptionData, setVariantsOptionData] = useState([]);
  const [allergyData, setAllergyData] = useState([]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      setModalShow(false);
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  const handleUpdateStatus = (item) => {
    const newStatus = item?.isActive == true ? false : true;
    const data = {
      isActive: newStatus,
      _id: item._id,
      pageNo: currentPage + 1,
    };
    dispatch(
      updateMenuStatus({
        branchId: selectBranches[0],
        data: data,
        search: searchQuery,
      })
    );
  };

  const handlePageChange = (pageNumber) => {
    let pageNo = pageNumber?.selected + 1;
    setCurrentPage(pageNumber?.selected);
    dispatch(
      allMenuByBranchId({
        branchId: selectBranches[0],
        pageNo: pageNo,
        search: searchQuery,
      })
    );
    if (pageNo) {
      setCurrentIndex(pageNo);
    }
  };

  useEffect(() => {
    if (selectBranches.length > 0 && selectBranches[0] !== undefined) {
      handlePageChange();
    }
  }, [selectBranches]);

  const handleEdit = (item) => {
    setData(item);
    setTitle(item?.title);
    setPrice(item?.price);
    setCategory(item?.categories?._id);
    setDescription(item?.description);
    setMenuVariant(item?.menuvariants);
    const newArray = [...item?.menuvariants];
    setVariantsOptionData(newArray);
    const allergyArray = [...item?.menuallergies];
    setAllergyData(allergyArray);
    setModalShow(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
    setTitle("");
    setPrice("");
    setCategory("");
    setDescription("");
    setVariantsOptionData([]);
    setData({});
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    dispatch(
      allMenuByBranchId({
        branchId: selectBranches[0],
        pageNo: currentPage,
        search: event.target.value,
      })
    );
    setCurrentPage(0);
    setCurrentIndex(1);
  };

  return (
    <>
      <h2 className="p-3 fw-bold">Menu</h2>
      <div className="bg-white">
        <div className="d-flex justify-content-between mb-3 p-4">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Search by Title"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Form.Group>
          <Button variant="danger" onClick={() => setModalShowMenu(true)}>
            Add Menu
          </Button>
        </div>

        <div>
          <Container>
            <Table
              style={{ borderRadius: "5px", overflow: "hidden" }}
              className="my-3 bg-light"
              striped
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  {/* <th>Image</th> */}
                  <th>Description</th>
                  <th>Price</th>
                  <th>Category</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {props.MenuTableData?.map((val, index) => {
                  const adjustedIndex = (currentIndex - 1) * 10 + index + 1;
                  return (
                    <tr key={index}>
                      <td className="text-center fw-bold ">{adjustedIndex}</td>
                      <td>{val?.title.replace(/ /g, "\u00a0")}</td>
                      {/* <td></td> */}
                      {/* <td><img src={val.foodImg} alt='Foods-Images' width={60} className='text-center rounded-circle '/></td> */}
                      <td>{val?.description}</td>
                      <td className=" fw-bold">
                        {val?.countries?.currencySymbol} {val?.price}
                      </td>
                      <td className="">{val?.categories?.title}</td>
                      <td>
                        {val?.isActive === true ? (
                          <Button
                            className="btn-sm"
                            variant="success"
                            onClick={() => handleUpdateStatus(val)}
                          >
                            {val?.isActive === true ? "Active" : "InActive"}
                          </Button>
                        ) : (
                          <Button
                            variant="danger"
                            className="btn-sm"
                            onClick={() => handleUpdateStatus(val)}
                          >
                            {val?.isActive === true ? "Active" : "InActive"}
                          </Button>
                        )}
                      </td>
                      <td>
                        <Button
                          className="btn btn-sm "
                          variant="warning"
                          onClick={() => handleEdit(val)}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <ReactPaginate
              nextLabel=">"
              pageRangeDisplayed={10}
              marginPagesDisplayed={2}
              previousLabel="<"
              pageClassName="page-item"
              onPageChange={handlePageChange}
              currentPage={currentPage}
              pageCount={totalPages}
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination mx-3 p-2"
              activeClassName="active"
              forcePage={currentPage}
              renderOnZeroPageCount={null}
            />
          </Container>

          <MenuItemsModal
            show={modalShow}
            onHide={handleCloseModal}
            data={data}
            title={title}
            price={price}
            category={category}
            description={description}
            setTitle={setTitle}
            setPrice={setPrice}
            setCategory={setCategory}
            setDescription={setDescription}
            currentPage={currentPage}
            searchQuery={searchQuery}
            menuVariant={menuVariant}
            variantOptionData={variantOptionData}
            setVariantsOptionData={setVariantsOptionData}
            setAllergyData={setAllergyData}
            allergyData={allergyData}
          />
          <AddMenu
            show={modalShowMenu}
            setSearchQuery={setSearchQuery}
            onHide={() => setModalShowMenu(false)}
            currentPage={currentPage}
          ></AddMenu>
        </div>
      </div>
    </>
  );
}

// const MenuItems = (props) => {
//     function tabNameFormates(tabName) {
//         const nameWithSpace = tabName.replace(/_/g, ' ');
//         return nameWithSpace.toUpperCase();
//     }
//     return (
//         <Container className='bg-transparent my-5'>
//             <Tabs
//                 // defaultActiveKey="profile"
//                 id="justify-tab-example"
//                 className="mb-3 text-dark"
//                 style={{ Color: 'black'}}
//                 justify
//             >
//                 {Object.keys(props.MenuItemsData).map((val, ind) => {
//                     return (
//                         <Tab eventKey={val} title={tabNameFormates(val)}>
//                             <MenuItemsData Category={props.MenuItemsData[val]} />
//                         </Tab>
//                     )
//                 })}
//             </Tabs>

//         </Container>
//     );
// };

export default MenuItems;
