import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import allergyService from "./allergyService";

const initialState = {
  allergy: [],
  totalPages: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};
// get allergy
export const allAllergy = createAsyncThunk(
  "admin/allAllergy",
  async ({branchId,pageNo}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await allergyService.getAllAllergy(user.accessToken, branchId,pageNo);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//add allergy
export const addAllergy = createAsyncThunk(
    "admin/addAllergy",
    async ({ branchId, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await allergyService.addAllergy(user.accessToken, branchId, data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  //update Allergy
export const updateAllergy = createAsyncThunk(
    "admin/updateAllergy",
    async ({ branchId, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await allergyService.updateAllergy(user.accessToken,branchId,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  //update Allergy Status
  export const updateAllergyStatus = createAsyncThunk(
    "admin/updateAllergyStatus",
    async ({ branchId, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await allergyService.updateAllergyStatus(user.accessToken,branchId,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const allActiveAllergy = createAsyncThunk(
    "admin/allActiveAllergy",
    async (branchId, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await allergyService.getActiveAllergy(
          user.accessToken,
          branchId
        );
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const allergySlice = createSlice({
  name: "allergy",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allAllergy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allAllergy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allergy = action.payload?.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(allAllergy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.allergy = [];
      })
      .addCase(addAllergy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAllergy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allergy = action.payload.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(addAllergy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateAllergy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAllergy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allergy = action.payload;
      })
      .addCase(updateAllergy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateAllergyStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAllergyStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allergy = action.payload;
      })
      .addCase(updateAllergyStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(allActiveAllergy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allActiveAllergy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allergy = action.payload;
      })
      .addCase(allActiveAllergy.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});
export const { reset } = allergySlice.actions;

export default allergySlice.reducer;
