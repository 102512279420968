import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import menuService from "./menuService";

const initialState = {
  menus: [],
  totalPages: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};
//add Menu
export const addMenu = createAsyncThunk(
  "admin/addMenu",
  async ({ branchId, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await menuService.addMenu(user.accessToken, branchId, data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// get menu
export const allMenu = createAsyncThunk(
  "admin/allMenu",
  async (pageNo, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await menuService.getAllMenu(user.accessToken, pageNo);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// getAllMenuByBranchId
export const allMenuByBranchId = createAsyncThunk(
  "admin/allMenuByBranchId",
  async ({ branchId, pageNo, search }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await menuService.getAllMenuByBranchId(
        user.accessToken,
        branchId,
        pageNo,
        search
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get Active Menu
export const activeMenuList = createAsyncThunk(
  "admin/activeMenuList",
  async ({ id, branchId }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await menuService.getActiveMenu(user.accessToken, id, branchId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update Menu Status
export const updateMenuStatus = createAsyncThunk(
  "admin/updateMenuStatus",
  async ({ branchId, data, search }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await menuService.updateMenuStatus(
        user.accessToken,
        data,
        branchId,
        search
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update Menu
export const updateMenu = createAsyncThunk(
  "admin/updateMenu",
  async ({ branchId, data, search }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await menuService.updateMenu(
        user.accessToken,
        data,
        branchId,
        search
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMenu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addMenu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.menus = action.payload?.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(addMenu.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(allMenu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allMenu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.menus = action.payload?.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(allMenu.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.menus = [];
      })
      .addCase(allMenuByBranchId.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(allMenuByBranchId.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.isSuccess = true;
        state.menus = action.payload?.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(allMenuByBranchId.rejected, (state, action) => {
        // state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.menus = [];
      })
      .addCase(updateMenuStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMenuStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.menus = action.payload;
      })
      .addCase(updateMenuStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateMenu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMenu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.menus = action.payload;
      })
      .addCase(updateMenu.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(activeMenuList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activeMenuList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.menus = action.payload;
      })
      .addCase(activeMenuList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.menus = [];
      });
  },
});
export const { reset } = menuSlice.actions;

export default menuSlice.reducer;
