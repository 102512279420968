import Dashboard from "../components/Admin/Dashboard/Dashboard";
import Orders from "../components/Admin/Order/Orders";
import Menu from "../components/Admin/Menu/Menu";
import Tables from "../components/Admin/Table/Tables";
import Booking from "../components/Admin/Booking/Booking";
import Notification from "../components/Admin/Notification/Notification";
import Profile from "../components/Admin/Profile/Profile";
import Setting from "../components/Admin/Setting/Setting";
import PaymentSetting from "../components/Admin/PaymentSetting/PaymentSetting";
import Branches from "../components/Admin/Branches/Branches";

import NewOrder from "../components/Admin/Order/NewOrder";

import Expenses from "../components/Admin/Expenses/Expenses";
import AddExpense from "../components/Admin/Expenses/AddExpenseModal";

import Category from "../components/Admin/category/Category";
import AddCategory from "../components/Admin/category/AddCategoryModal";
import QrCode from "../components/Admin/qr-code/QrCode";

import Staff from "../components/Admin/staff/Staff";
import MenuItem from "../components/Admin/MenuItem/MenuItem";
import Variant from "../components/Admin/Variant/Variant";
import VariantOption from "../components/Admin/VariantOption/VariantOption";

import SuperAdminDashboard from "../components/SuperAdmin/Dashboard/SuperAdminDashboard";
import ExpensesSuperAdmin from "../components/SuperAdmin/Expenses/Expenses";
import Restaurant from "../components/SuperAdmin/Restaurants/Restaurant";
import User from "../components/SuperAdmin/Users/User";
import Sale from "../components/SuperAdmin/Sales/Sale";
import AddRestaurant from "../components/SuperAdmin/Restaurants/AddRestaurant";
import UserType from "../components/SuperAdmin/UserType/UserType";
import Country from "../components/SuperAdmin/Country/Country";
import Logout from "../Logout";
import ExpenseCategory from '../components/Admin/ExpenseCategory/ExpenseCategory'
import Kitchen from "../components/Cook/Kitchen/Kitchen";
import Order from "../components/Waiter/Order/Order";
import Allergy from"../components/Admin/Allergy/Allergy"

export const Routes = {
  superAdmin: [
    {
      label: "Dashboard",
      route: "/dashboard",
      component: <SuperAdminDashboard />,
      icon: "th-large",
      showInNav: true,
    },
    {
      label: "Restaurant",
      route: "/superAdmin/restaurant",
      component: <Restaurant />,
      icon: "columns",
      showInNav: true,
    },
    {
      label: "Add Restaurant",
      route: "/superAdmin/restaurant/addrestaurant",
      component: <AddRestaurant />,
      icon: "columns",
      showInNav: false,
    },
    {
      label: "Sale",
      route: "/superAdmin/sale",
      component: <Sale />,
      icon: "credit-card",
      showInNav: false,
    },
    {
      label: "Expenses",
      route: "/superAdmin/expenses",
      component: <ExpensesSuperAdmin />,
      icon: "chart-line",
      showInNav: false,
    },
    {
      label: "User",
      route: "/superAdmin/user",
      component: <User />,
      icon: "user",
      showInNav: false,
    },
    {
      label: "UserType",
      route: "/superAdmin/userType",
      component: <UserType />,
      icon: "users",
      showInNav: true,
    },
    {
      label: "Country",
      route: "/superAdmin/country",
      component: <Country />,
      icon: "globe",
      showInNav: true,
    },
    {
      label: "Backup Database",
      route: "/superAdmin",
      component: <></>,
      icon: "database",
      showInNav: false,
    },
    {
      label: "Logout",
      route: "/",
      component: <Logout />,
      icon: "columns",
      showInNav: true,
    },
  ],
  admin: [
     { label: 'Dashboard', route: '/dashboard', component: <Dashboard />,icon:"th-large", showInNav: true },
    {
      label: "Orders",
      route: "/admin/orders",
      component: <Orders />,
      icon: "table",
      showInNav: true,
    },
    {
      label: "New Order",
      route: "/admin/order",
      component: <NewOrder />,
      icon: "table",
      showInNav: false,
    },
    {
      label: "New Order",
      route: "/admin/order/:tableId",
      component: <NewOrder />,
      icon: "table",
      showInNav: false,
    },
    {
      label: "Menu",
      route: "/admin/menu",
      component: <Menu />,
      icon: "book",
      showInNav: true,
    },
    {
      label: "Variant",
      route: "/admin/variant",
      component: <Variant />,
      icon: "chart-line",
      showInNav: true,
    },
    {
      label: "Allergy",
      route: "/admin/allergy",
      component: <Allergy />,
      icon: "chart-line",
      showInNav: true,
    },
    {
      label: "Variant Option",
      route: "/admin/variantOption",
      component: <VariantOption />,
      icon: "chart-line",
      showInNav: true,
    },
    {
      label: "MenuItem",
      route: "/admin/menuItem",
      component: <MenuItem />,
      icon: "book",
      showInNav: false,
    },
    {
      label: "Tables",
      route: "/admin/tables",
      component: <Tables />,
      icon: "chair",
      showInNav: false,
    },
    {
      label: "Expense Category",
      route: "/admin/expenseCategory",
      component: <ExpenseCategory />,
      icon: "sticky-note",
      showInNav: true,
    },
    {
      label: "Expenses",
      route: "/admin/expenses",
      component: <Expenses />,
      icon: "chart-line",
      showInNav: true,
    },
    {
      label: "Category",
      route: "/admin/category",
      component: <Category />,
      icon: "plus",
      showInNav: true,
    },
    {
      label: "Staff",
      route: "/admin/staff",
      component: <Staff />,
      icon: "users",
      showInNav: true,
    },
    {
      label: "Booking",
      route: "/admin/booking",
      component: <Booking />,
      icon: "clock",
      showInNav: false,
    },
    {
      label: "Notification",
      route: "/admin/notification",
      component: <Notification />,
      icon: "bell",
      showInNav: false,
    },
    {
      label: "Profile",
      route: "/admin/profile",
      component: <Profile />,
      icon: "user",
      showInNav: false,
    },
    {
      label: "Branches",
      route: "/admin/branches",
      component: <Branches />,
      icon: "users",
      showInNav: true,
    },
    {
      label: "Setting",
      route: "/admin/setting",
      component: <Setting />,
      icon: "chart-line",
      showInNav: false,
    },
    {
      label: "Payment Setting",
      route: "/admin/paymentSetting",
      component: <PaymentSetting />,
      icon: "chart-line",
      showInNav: true,
    },
    {
      label: "Menu OR",
      route: "/admin/qrCode",
      component: <QrCode />,
      icon: "book",
      showInNav: false,
    },
    {
      label: "Logout",
      route: "/",
      component: <Logout></Logout>,
      icon: "columns",
      showInNav: true,
    },
  ],
  manager: [
    // {
    //   label: "Dashboard",
    //   route: "/dashboard",
    //   component: <Dashboard />,
    //   icon: "th-large",
    //   showInNav: true,
    // },
    {
      label: "Orders",
      route: "/manager/orders",
      component: <Orders />,
      icon: "table",
      showInNav: true,
    },
    {
      label: "New Order",
      route: "/manager/order",
      component: <NewOrder />,
      icon: "table",
      showInNav: false,
    },
    {
      label: "New Order",
      route: "/manager/order/:tableId",
      component: <NewOrder />,
      icon: "table",
      showInNav: false,
    },
    {
      label: "Menu",
      route: "/manager/menu",
      component: <Menu />,
      icon: "book",
      showInNav: true,
    },
    {
      label: "MenuItem",
      route: "/manager/menuItem",
      component: <MenuItem />,
      icon: "book",
      showInNav: false,
    },
    {
      label: "Tables",
      route: "/manager/tables",
      component: <Tables />,
      icon: "chair",
      showInNav: false,
    },
    {
      label: "Expenses",
      route: "/manager/expenses",
      component: <Expenses />,
      icon: "chart-line",
      showInNav: false,
    },
    {
      label: "Category",
      route: "/manager/category",
      component: <Category />,
      icon: "plus",
      showInNav: true,
    },
    {
      label: "Staff",
      route: "/manager/staff",
      component: <Staff />,
      icon: "users",
      showInNav: false,
    },
    {
      label: "Booking",
      route: "/manager/booking",
      component: <Booking />,
      icon: "clock",
      showInNav: false,
    },
    {
      label: "Notification",
      route: "/manager/notification",
      component: <Notification />,
      icon: "bell",
      showInNav: false,
    },
    {
      label: "Profile",
      route: "/manager/profile",
      component: <Profile />,
      icon: "user",
      showInNav: false,
    },
    {
      label: "Branches",
      route: "/manager/branches",
      component: <Branches />,
      icon: "users",
      showInNav: false,
    },
    {
      label: "Setting",
      route: "/manager/setting",
      component: <Setting />,
      icon: "chart-line",
      showInNav: false,
    },
    {
      label: "Menu OR",
      route: "/manager/qrCode",
      component: <QrCode />,
      icon: "book",
      showInNav: false,
    },
    {
      label: "Logout",
      route: "/",
      component: <Logout></Logout>,
      icon: "columns",
      showInNav: true,
    },
  ],
  cook: [
    {
      label: "Kitchen",
      route: "/cook/kitchen",
      component: <Kitchen />,
      icon: "table",
      showInNav: true,
    },
    {
      label: "Logout",
      route: "/",
      component: <Logout></Logout>,
      icon: "columns",
      showInNav: true,
    },
  ],
  waiter: [
    {
      label: "Order",
      route: "/waiter/order",
      component: <Order />,
      icon: "table",
      showInNav: true,
    },
    {
      label: "New Order",
      route: "/waiter/neworder",
      component: <NewOrder />,
      icon: "table",
      showInNav: true,
    },
    {
      label: "Logout",
      route: "/",
      component: <Logout></Logout>,
      icon: "columns",
      showInNav: true,
    },
  ],
};
