import React, { useState } from "react";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";

// Bootstrap
import { Button, Form } from "react-bootstrap";

// Stripe
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { addOrder } from "../../redux/features/customer/Order/orderSlice.js";
import { addPaymentTransaction } from "../../redux/features/waiter/PayStack/waiterPayStackAction";

import GeneralIndicator from "../model/GeneralIndicator";
const StripeCard = ({
  name,
  inputEmail,
  totalPayment,
  username,
  table,
  guest,
  email,
  branchId,
  order,
}) => {
  const dispatch = useDispatch();
  const [cardElements, setCardElements] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // Stripe
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    if (!stripe || !elements) {
      return;
    }

    try {
      setIsLoading(true);
      // Create payment method
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          email: inputEmail,
        },
      });

      if (error) {
        console.error(error);
        setIsLoading(false);
        // Handle error (e.g., show error message to the user)
        return;
      }

      // Create customer on Stripe using the payment method
      const customerResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/waiter/create-customer`,
        {
          name: name,
          email: inputEmail,
          paymentMethod: paymentMethod,
        }
      );

      const customerData = customerResponse.data;

      // Charge the customer
      const chargeResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/waiter/charge-customer`,
        {
          customerId: customerData.body.customerId,
          amount: totalPayment,
          //   currency: order?.restaurantInformation?.countries?.currencySymbol,
          currency: "usd",
        }
      );

      const chargeData = chargeResponse.data;

      const result = await stripe.confirmCardPayment(
        chargeData?.body?.client_secret,
        {
          //`Elements` instance that was used to create the Payment Element
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: name,
              email: inputEmail,
            },
          },
        }
      );
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log(result);
        let paymentdata = {
          id: result?.paymentIntent?.id,
          status: result?.paymentIntent?.status,
          amount: result?.paymentIntent?.amount / 100,
        };
        let transactionData = {
          branchId: branchId,
          orderId: order?._id,
          totalAmount: order?.totalAmount,
          type: "online",
          paymentMethod: "stripe",
          transactionId: result?.paymentIntent?.id,
        };
        dispatch(addPaymentTransaction(transactionData));
        let data = {
          status: "dining",
          orderId: order?._id,
          userId: order?.customerId,
          branchId: branchId,
          totalAmount: order?.totalAmount,
          cashReceived: order?.totalAmount,
          cashChange: 0,
          username: username,
          tableId: table,
          guest: guest,
          email: email,
        };
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <>  {isLoading && <GeneralIndicator />}
    <Form onSubmit={handleSubmit}>
      <h4>Card Info</h4>
      <CardElement
        id="card-element"
        onChange={(e) => {
          setCardElements(e);
        }}
      />
      <Button
        type="button"
        variant="warning"
        onClick={handleSubmit}
        disabled={!stripe}
        className="rounded-pill mt-3 w-25"
      >
        Pay
      </Button>
    </Form>
    </>
  );
};

export default StripeCard;
