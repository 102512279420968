import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Table,
  Pagination,
  Form,
  Col,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarcode, faUser, faEye,faPen } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import { toBlob } from "html-to-image";
import { useDispatch, useSelector } from "react-redux";

import {
  allBranches,
  updateBranches,
  reset,
} from "../../../redux/features/admin/Branches/branchesAction";

import { activeStaffList } from "../../../redux/features/admin/Staff/staffSlice";
import AddBranch from "./AddBranch";
import EditBranch from "./EditBranch";
import GeneralIndicator from "../../model/GeneralIndicator";

import ReactPaginate from "react-paginate";

const Branches = () => {
  const dispatch = useDispatch();
  const qrCodeRef = useRef(null);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalManger, setShowModalManger] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const [qrData, setQrData] = useState(null);
  const [inputCategory, setInputCategory] = useState("");
  const [managerId, setManagerId] = useState(null);
  const [branchId, setBranchId] = useState(null);

  const [showModalAddBranch, setShowModalAddBranch] = useState(false);
  const [showModalEditBranch, setShowModalEditBranch] = useState(false);

  const { selectBranches } = useSelector((state) => state.branches);

  const { branches, isError, isSuccess, message, totalPage,isLoading } = useSelector(
    (state) => state.branches
  );
  const { staff } = useSelector((state) => state.staff);

  const handleViewClick = (item) => {
    localStorage.removeItem("orderData");
    window.open(`/restaurant/${item?.branchName}/${item?._id}`);
  };
  const downloadQRCode = () => {
    const qrCodeContainer = qrCodeRef.current;

    if (qrCodeContainer) {
      toBlob(qrCodeContainer)
        .then(function (blob) {
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = "qrcode.png";
          a.click();
        })
        .catch(function (error) {
          console.error("Error creating QR code image:", error);
        });
    }
  };
  useEffect(() => {
    if (isError) {
      // toast.error(message);
    }
    if (isSuccess) {
      setShowModal(false);
      setShowModalManger(false);
      setShowModalAddBranch(false);
      setShowModalEditBranch(false);
      setData([]);
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  useEffect(() => {
    // dispatch(allBranches());
    dispatch(activeStaffList(selectBranches[0]));
  }, [selectBranches]);

  const handleQR = (item) => {
    setShowModal(true);
    setQrData(`${item?.branchName}/${item?._id}`);
  };
  const handleUser = (item) => {
    setBranchId(item?._id);
    setShowModalManger(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseModalManger = () => {
    setShowModalManger(false);
  };

  const handleUpdateBranch = () => {
    let data = {
      id: branchId,
      managerId: managerId,
    };
    dispatch(updateBranches(data));
  };
  const handlePageChange = (pageNumber) => {
    let pageNo = pageNumber.selected + 1;
    setCurrentPage(pageNumber.selected);
    dispatch(allBranches(pageNo));
  };

  const handleEdit = (item) => {
    setShowModalEditBranch(true);
    setData(item);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  return (
    <div
      style={{
        margin: "60px 20px 300px 20px",
        padding: "40px ",
        backgroundColor: "white",
      }}
    >
       {isLoading && <GeneralIndicator />}
      <h2 className="p-3 fw-bold">Branches</h2>
      <div className="bg-white">
        <div className="d-flex justify-content-between mb-3 p-4">
        <Form.Group>
            {/* <Form.Control
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
            /> */}
          </Form.Group>
          <Button variant="danger" onClick={() => setShowModalAddBranch(true)}>
            Add Branches
          </Button>
        </div>
        <Table
          striped
          style={{ borderRadius: "5px", overflow: "hidden" }}
          hover
          className="text-center"
        >
          <thead>
            <tr>
              <th>Sr#</th>
              <th>ID</th>
              <th>Name</th>
              <th>Address</th>
              <th>Manager</th>
              <th>Main</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {branches?.map((item, index) => (
              <tr key={index}>
                <td className="text-center fw-bold">{index + 1}</td>
                <td>{item?._id}</td>
                <td>{item?.branchName}</td>
                <td>{item?.address?.fullAddress}</td>
                <td>{item?.manager?.username}</td>
                <td>{item?.isMainBranch == true ? "Yes" : "No"}</td>
                <td>
                  <Button
                    variant="warning"
                    className="me-3"
                    onClick={() => handleQR(item)}
                  >
                    <FontAwesomeIcon icon={faBarcode} />
                  </Button>
                  <Button
                    variant="warning"
                    className="me-3"
                    onClick={() => handleUser(item)}
                  >
                    <FontAwesomeIcon icon={faUser} />
                  </Button>
                  <Button
                    variant="warning"
                    className="me-3"
                    onClick={() => handleViewClick(item)}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                  <Button
                    variant="warning"
                    className="me-3"
                    onClick={() => handleEdit(item)}
                  >
                   <FontAwesomeIcon icon={faPen} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ReactPaginate
          nextLabel=">"
          pageRangeDisplayed={10}
          marginPagesDisplayed={2}
          previousLabel="<"
          pageClassName="page-item"
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pageCount={totalPage}
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          forcePage={currentPage}
          renderOnZeroPageCount={null}
        />

        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold ">Qr Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row id="qr-code-container">
              <div className="text-center">
                <QRCode
                  size={256}
                  ref={qrCodeRef}
                  style={{ height: "auto", maxWidth: "100%", width: "40%" }}
                  value={`${window.location.origin}/restaurant/${qrData}`}
                  viewBox={`0 0 200 200`}
                />
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer className="mt-4">
            <Button variant="danger" onClick={handleCloseModal}>
              Close
            </Button>
            <Button onClick={downloadQRCode} variant="success">
              Download
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showModalManger} onHide={handleCloseModalManger} centered>
          <Modal.Header closeButton>
            <Modal.Title className="fw-bold ">User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group>
                <Form.Label className="fw-bold">User:</Form.Label>
                <Form.Select
                  aria-label="select"
                  required
                  onChange={(e) => setManagerId(e.target.value)}
                >
                  <option selected disabled>
                    Select User
                  </option>
                  {staff?.map((item) => (
                    <option key={item._id} value={item?._id}>
                      {item?.username}
                    </option>
                  ))}
                  ;
                </Form.Select>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer className="mt-4">
            <Button variant="danger" onClick={handleCloseModalManger}>
              Close
            </Button>
            <Button variant="warning" onClick={handleUpdateBranch}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <AddBranch onHide={() => setShowModalAddBranch(false)} show={showModalAddBranch} setShowModalAddBranch={setShowModalAddBranch} ></AddBranch>
      <EditBranch onHide={() => setShowModalEditBranch(false)} show={showModalEditBranch} setShowModalEditBranch={setShowModalEditBranch} data={data} setData={setData}></EditBranch>
    </div>
  );
};

export default Branches;
