import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

// import Colleagues from './Colleagues';
// import AssignedTables from './AssignedTables';
// import { ColleagueData, ColleagueDataNew, AssignedTablesData } from './JsonData.js';

// import ServedOrder from './ServedOrder';
// import { ServedData } from "./JsonData"

// import DateTime from './DateTime';
// import { DateTimeData } from "./JsonData"

import DashboardOrders from "./DashboardOrders";
import { DashboardData } from "../../../JsonData";
import { useDispatch, useSelector } from "react-redux";

import { dashboard } from "../../../redux/features/admin/dashboard/dashboardAction";

import LatestOrders from "./LatestOrdersTable";
import MostOrder from "./MostOrder";
import { allBranches } from "../../../redux/features/admin/Branches/branchesAction";

function Dashboard() {
  const dispatch = useDispatch();
  const { selectBranches } = useSelector((state) => state.branches);

  useEffect(() => {
    if (selectBranches.length > 0 && selectBranches[0] !== undefined) {
      dispatch(dashboard({ branchId: selectBranches[0] }));
    }
  }, [selectBranches]);

  // console.log(DashboardData)
  return (
    <div>
      <Container className="mt-5">
        <DashboardOrders DashboardTopCards={DashboardData.topCards} />
        {/* <LatestOrders LatestOrders={DashboardData.latestOrders} /> */}
        {/* <MostOrder MostOrders={DashboardData.mostOrders} /> */}
      </Container>

      {/* <Container className='mt-5'>
                    
                <Row>
                    <Col>
                    <ServedOrder title={'Served'} ServedData={ServedData} />
                    </Col>
                    <DateTime title={'Served'} DateTimeData={DateTimeData} />
                </Row>
                <Colleagues title={'Colleagues'} ColleaguesData={ColleagueData}/>
                <Colleagues title={'Available Tables'} ColleaguesData={ColleagueDataNew} />
                <AssignedTables title={'Assigned Tables'} AssignedTablesData={AssignedTablesData} />
            </Container> */}
    </div>
  );
}

export default Dashboard;
