import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const addExpenseCategory = async (token, branchId, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/admin/expenseCategory/add?branchId=${branchId}&page=${data?.pageNo}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Add Expense Category");
    return response.data;
  }
};

const getAllExpenseCategory = async (token, branchId,pageNo) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/expenseCategory?branchId=${branchId}&page=${pageNo}`,
      config
    );
    if (response.data.body) {
      return response.data;
    }
  };

  const updateExpenseCategory = async (token,branchId,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/admin/expenseCategory/${data._id}?branchId=${branchId}&page=${data?.pageNo}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Successfully Update Expense Category");
      return response.data.body;
    }
  };

  const updateExpenseCategoryStatus = async (token,branchId,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/admin/expenseCategory/status/${data._id}?branchId=${branchId}&page=${data?.pageNo}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Successfully Update ExpenseCategory Status");
      return response.data.body;
    }
  };
  const getActiveExpenseCategory = async (token,branchId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/expenseCategory/active?branchId=${branchId}`,
      config
    );
    if (response.data.body) {
      return response.data.body;
    }
  };
const expenseCategoryService = {
    addExpenseCategory,
    getAllExpenseCategory,
    updateExpenseCategory,
    updateExpenseCategoryStatus,
    getActiveExpenseCategory
};

export default expenseCategoryService;
