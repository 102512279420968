import axios from 'axios';
import {toast} from 'react-toastify';
import {setStatus,STATUSES ,setRestaurantList,setUpdateRestaurant,setupdateRestaurantStatus} from './createAction'



export const addRestaurant = (
  currentUser,
  restaurantName,
  ownerName,
  ownerEmail,
  ownerPhone,
  country,
  parcels,
  table,
  Subscription,
  Address,
  history
) => {
  return async function createAsyncThunk(dispatch) {
    dispatch(setStatus({status: STATUSES.LOADING}));
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superAdmin/restaurant/add`, {
      title: restaurantName,
     ownerName:ownerName,
     ownerEmail:ownerEmail,
     ownerPhone:ownerPhone,
     countryId:country,
     subscriptionPlansId:Subscription,
     address:Address,
     parcels:parcels,
     table:table
      },{
      headers: {
        Authorization: `Bearer ${currentUser.accessToken}`,
      }},     
      );
      console.log(res, 'response');
      dispatch(
        setStatus({
            status: STATUSES.IDLE
        }),
      );
      toast.success('Successfully Added Restaurant');
      history.push('/superAdmin/restaurant')
    } catch (error) {
      console.log(error, 'Error');
      dispatch(
        setStatus({
          status: STATUSES.ERROR,
          error: error.response?.data.message,
        }),
      );
    toast.error( error.response?.data.message);
    }
  };
};

export const getRestaurant = (
  currentUser,
  pageNo
) => {
  return async function createAsyncThunk(dispatch) {
    dispatch(setStatus({status: STATUSES.LOADING}));
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/superAdmin/restaurant/all?page=${pageNo}`,{ headers: {
        Authorization: `Bearer ${currentUser.accessToken}`,
      },});
      console.log(res?.data?.body, 'response');
      dispatch(
        setStatus({
            status: STATUSES.IDLE,     
        }),
      );
      dispatch(
        setRestaurantList({restaurantList :res?.data?.body ,totalPages:res?.data?.total_pages}),
      );
      
    } catch (error) {
      console.log(error, 'Error');
      dispatch(
        setStatus({
          status: STATUSES.ERROR,
          error: error.response?.data.message,
        }),
      );
    toast.error( error.response?.data.message);
    }
  };
};

export const updateRestaurant = (
  id,
  currentUser,
  restaurantName,
  ownerName,
  ownerEmail,
  ownerPhone,
  country,
  parcels,
  table,
  Subscription,
  Address,
  setShowModal,
  pageNo
) => {
  return async function createAsyncThunk(dispatch) {
    dispatch(setUpdateRestaurant({status: STATUSES.LOADING}));
    try {
      const res = await axios.put(`${process.env.REACT_APP_BASE_URL}/superAdmin/restaurant/${id}?page=${pageNo}`, {
      title: restaurantName,
     ownerName:ownerName,
     ownerEmail:ownerEmail,
     ownerPhone:ownerPhone,
     countryId:country,
     subscriptionPlansId:Subscription,
     address:Address,
     parcels:parcels,
     table:table
      },{
      headers: {
        Authorization: `Bearer ${currentUser.accessToken}`,
      }},     
      );
      console.log(res, 'response');
      dispatch(
        setRestaurantList({restaurantList :res?.data?.body ,totalPages:res?.data?.total_pages}),
      );
      setShowModal(false);
      toast.success('Successfully Updated Restaurant');
    } catch (error) {
      console.log(error, 'Error');
      dispatch(
        setUpdateRestaurant({
          status: STATUSES.ERROR,
          error: error.response?.data.message,
        }),
      );
    toast.error( error.response?.data.message);
    }
  };
};

export const updateRestaurantStatus = (
  id,
 status,
 currentUser,
 pageNo
) => {
  return async function createAsyncThunk(dispatch) {
    dispatch(setupdateRestaurantStatus({status: STATUSES.LOADING}));
    try {
      const res = await axios.put(`${process.env.REACT_APP_BASE_URL}/superAdmin/restaurant/status/${id}?page=${pageNo}`, {
        status:status
      },{
      headers: {
        Authorization: `Bearer ${currentUser.accessToken}`,
      }},     
      );
      console.log(res, 'response');
      dispatch(
        setRestaurantList({restaurantList :res?.data?.body ,totalPages:res?.data?.total_pages}),
      );
      toast.success('Successfully Updated Restaurant Status');
    } catch (error) {
      console.log(error, 'Error');
      dispatch(
        setupdateRestaurantStatus({
          status: STATUSES.ERROR,
          error: error.response?.data.message,
        }),
      );
    toast.error( error.response?.data.message);
    }
  };
};


export const addPublicRestaurant = (
  restaurantName,
  ownerName,
  ownerEmail,
  ownerPhone,
  country,
  parcels,
  table,
  Subscription,
  Address,
  history
) => {
  return async function createAsyncThunk(dispatch) {
    dispatch(setStatus({status: STATUSES.LOADING}));
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/superAdmin/restaurant/add`, {
      title: restaurantName,
     ownerName:ownerName,
     ownerEmail:ownerEmail,
     ownerPhone:ownerPhone,
     countryId:country,
     subscriptionPlansId:Subscription,
     address:Address,
     parcels:parcels,
     table:table
      });
      dispatch(
        setStatus({
            status: STATUSES.IDLE
        }),
      );
      toast.success('Successfully Sign Up');
      history.push('/')
    } catch (error) {
      console.log(error, 'Error');
      dispatch(
        setStatus({
          status: STATUSES.ERROR,
          error: error.response?.data.message,
        }),
      );
    toast.error( error.response?.data.message);
    }
  };
};