import React from "react";
import { Row, Col, Card } from "react-bootstrap";

import { useSelector } from "react-redux";
import CompleteOrders from "../../Images/Menu-Items/fourth.png";
import totalIncome from "../../Images/Menu-Items/totalIncome.png";
import processingOrder from "../../Images/Menu-Items/proccessingOrder.png";
import dining from "../../Images/Menu-Items/dining.png";
import TotalRevenue from "../../Images/Menu-Items/TotalRevenue.png";
import expense from "../../Images/Menu-Items/expense.png";
import { useHistory } from "react-router-dom";

function DashboardOrders(props) {
  const history = useHistory();

  const { dashboard } = useSelector((state) => state.dashboard);
  return (
    <>
      <Row className="g-3">
        <Col lg={4} sm={6}>
          <Card className="text-center">
            <Card.Body className=" ">
              <img
                src={totalIncome}
                alt="cards_images"
                className="pt-2"
                width={60}
              />
              <div className="d-grid">
                <h6 className="mx-2 pt-0">Total Income</h6>
                <h6 className="mx-2 p-0 text-secondary">
                  {dashboard?.totalAmount ? dashboard?.totalAmount : 0}
                </h6>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} sm={6}>
          <Card
            className="text-center curser"
            onClick={() => {
              history.push("/admin/expenses");
            }}
          >
            <Card.Body className=" ">
              <img
                src={expense}
                alt="cards_images"
                className="pt-2"
                width={60}
              />
              <div className="d-grid">
                <h6 className="mx-2 pt-0">Total Expenses</h6>
                <h6 className="mx-2 p-0 text-secondary">
                  {dashboard?.totalExpenseAmount
                    ? dashboard?.totalExpenseAmount
                    : 0}
                </h6>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} sm={6}>
          <Card
            className="text-center curser"
            onClick={() => {
              history.push("/admin/expenses");
            }}
          >
            <Card.Body className=" ">
              <img
                src={TotalRevenue}
                alt="cards_images"
                className="pt-2"
                width={60}
              />
              <div className="d-grid">
                <h6 className="mx-2 pt-0">Total Profit</h6>
                <h6 className="mx-2 p-0 text-secondary">
                  {dashboard?.totalRevenue ? dashboard?.totalRevenue : 0}
                </h6>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} sm={6}>
          <Card
            className="text-center curser"
            onClick={() => {
              history.push("/admin/order");
            }}
          >
            <Card.Body className=" ">
              <img
                src={dining}
                alt="cards_images"
                className="pt-3"
                width={60}
              />
              <div className="d-grid">
                <h6 className="mx-2 pt-0">Dining Order</h6>
                <h6 className="mx-2 p-0 text-secondary">
                  {dashboard?.diningOrderCount
                    ? dashboard?.diningOrderCount
                    : 0}
                </h6>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} sm={6}>
          <Card
            className="text-center curser"
            onClick={() => {
              history.push("/admin/order");
            }}
          >
            <Card.Body className=" ">
              <img
                src={processingOrder}
                alt="cards_images"
                className="pt-2"
                width={60}
              />
              <div className="d-grid">
                <h6 className="mx-2 pt-0">Processing Order</h6>
                <h6 className="mx-2 p-0 text-secondary">
                  {dashboard?.processingOrderCount
                    ? dashboard?.processingOrderCount
                    : 0}
                </h6>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} sm={6}>
          <Card
            className="text-center curser"
            onClick={() => {
              history.push("/admin/orders");
            }}
          >
            <Card.Body className=" ">
              <img
                src={CompleteOrders}
                alt="cards_images"
                className="pt-2"
                width={60}
              />
              <div className="d-grid">
                <h6 className="mx-2 pt-0">Complete Orders</h6>
                <h6 className="mx-2 p-0 text-secondary">
                  {dashboard?.totalCompleteOrder
                    ? dashboard?.totalCompleteOrder
                    : 0}
                </h6>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DashboardOrders;
