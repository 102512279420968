// import axios from 'axios';
// import {toast} from 'react-toastify';
// import {setStatus,STATUSES ,setCountries} from './createAction'



// export const getCountry = (
//   currentUser
// ) => {
//   return async function createAsyncThunk(dispatch) {
//     dispatch(setStatus({status: STATUSES.LOADING}));
//     try {
//       const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/superAdmin/country/all?isActive=${'active'}`,{ headers: {
//         Authorization: `Bearer ${currentUser.accessToken}`,
//       },});
//       console.log(res?.data?.body, 'response');
//       dispatch(
//         setStatus({
//             status: STATUSES.IDLE,     
//         }),
//       );
//       dispatch(
//         setCountries({countries :res?.data?.body}),
//       );
      
//     } catch (error) {
//       console.log(error, 'Error');
//       dispatch(
//         setStatus({
//           status: STATUSES.ERROR,
//           error: error.response?.data.message,
//         }),
//       );
//     toast.error( error.response?.data.message);
//     }
//   };
// };

// export const getPublicCountry = (
// ) => {
//   return async function createAsyncThunk(dispatch) {
//     dispatch(setStatus({status: STATUSES.LOADING}));
//     try {
//       const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/superAdmin/country/all?isActive=${'active'}`);
//       console.log(res?.data?.body, 'response');
//       dispatch(
//         setStatus({
//             status: STATUSES.IDLE,     
//         }),
//       );
//       dispatch(
//         setCountries({countries :res?.data?.body}),
//       );
      
//     } catch (error) {
//       console.log(error, 'Error');
//       dispatch(
//         setStatus({
//           status: STATUSES.ERROR,
//           error: error.response?.data.message,
//         }),
//       );
//     toast.error( error.response?.data.message);
//     }
//   };
// };



import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const getAllCountry = async (token,pageNo,search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/superAdmin/country/all?page=${pageNo}&search=${search ? search : ""}`,
    config
  );
  if (response.data.body) {
    return response.data;
  }
}

const addCountry = async (token,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${BASE_URL}/superAdmin/country/add?page=${data.pageNo}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Country Added Successfully");
      return response.data;
    }
  };

  const updateCountry = async (token, data,search) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/superAdmin/country/${data.id}?page=${data.pageNo}&search=${search ? search : ""}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Country Updated Successfully");
      return response.data.body;
    }
  };

  const updateCountryStatus = async (token, data,search) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/superAdmin/country/status/${data._id}?page=${data.pageNo}&search=${search ? search : ""}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Country Status Updated Successfully");
      return response.data.body;
    }
  };
  
  const getActiveCountry = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/superAdmin/country/all?isActive=${'active'}`,
      config
    );
    if (response.data.body) {
      return response.data;
    }
  }

  const getPublicCountry = async () => {
    const response = await axios.get(
      `${BASE_URL}/superAdmin/country/all?isActive=${'active'}`    
      );
    if (response.data.body) {
      return response.data;
    }
  }


const countryService = {
  getAllCountry,
  addCountry,
  updateCountry,
  getActiveCountry,
  getPublicCountry,
  updateCountryStatus
  };
  
  export default countryService;
  
