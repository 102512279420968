import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;


const getAllorder = async (token,branchId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/waiter/order?branchId=${branchId}`, config);
  if (response.data.body) {
    return response.data;
  }
};

const getComingOrder = async (token,branchId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${BASE_URL}/waiter/order?branchId=${branchId}`, config);
  if (response.data.body) {
    return response.data;
  }
};
const updateOrder = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/waiter/order/${data._id}?branchId=${data.branchId}`,
    data,
    config
  );
  if (response.data.body) {
    return response.data.body;
  }
};
const getSingleOrder = async (id) => {
  const response = await axios.get(
    `${BASE_URL}/waiter/order/${id}`
  );
  if (response.data.body) {
    return response.data.body;
  }
};


const waiterOrderService = {
    getAllorder,
    getComingOrder,
    updateOrder,
    getSingleOrder
};

export default waiterOrderService;
