import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const addExpense = async (token, branchId, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/admin/expenses/add?branchId=${branchId}&page=${data?.pageNo}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Add Expenses");
    return response.data;
  }
};

const getAllExpense = async (token, branchId,pageNo) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/expenses?branchId=${branchId}&page=${pageNo}`,
      config
    );
    if (response.data.body) {
      return response.data;
    }
  };

  const updateExpense = async (token,branchId,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/admin/expenses/${data._id}?branchId=${branchId}&page=${data?.pageNo}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Successfully Update Expenses");
      return response.data.body;
    }
  };

  const updateExpenseStatus = async (token,branchId,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/admin/expenses/status/${data._id}?branchId=${branchId}&page=${data?.pageNo}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Successfully Update Expenses Status");
      return response.data.body;
    }
  };
const expensesService = {
    addExpense,
    getAllExpense,
    updateExpense,
    updateExpenseStatus
};

export default expensesService;
