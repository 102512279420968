import React, { useState, useEffect } from "react";
import { Form, Modal, Button, Col, Row, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";

import { useSelector, useDispatch } from "react-redux";

import {
  allActiveCategoryByBranchId,
  allActivecategories,
} from "../../../redux/features/admin/Categories/createAction";
import { allActiveVariantOption } from "../../../redux/features/admin/VariantOption/VariantOptionSlice";

import { allActiveAllergy } from "../../../redux/features/admin/Allergy/allergySlice";

import { addMenu, reset } from "../../../redux/features/admin/Menu/menuSlice";

// Function to convert string to title case
const toTitleCase = (str) => {
  return str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};

function AddMenu(props) {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");

  const { onHide } = props;
  const dispatch = useDispatch();
  const { selectBranches } = useSelector((state) => state.branches);
  const { categories } = useSelector((state) => state.category);
  const { isError, isSuccess, message } = useSelector((state) => state.menu);
  const variants = useSelector((state) => state.variant.variant);
  const { variantOption } = useSelector((state) => state.variantOption);
  const [selectedVariants, setSelectedVariants] = useState([]);

  const [selectedAllergy, setSelectedAllergy] = useState([]);

  const [variantOptionData, setVariantsOptionData] = useState([]);
  const [variantPrice, setVariantPrice] = useState("");
  const [recentlyClickedVariant, setRecentlyClickedVariant] = useState(null);
  const [isRecentlyClicked, setIsRecentlyClicked] = useState(false);

  const { allergy } = useSelector((state) => state.allergy);

  // Filter variant options based on the selected variants
  // const filteredOptions = variantOption?.filter((option) =>
  //   selectedVariants.includes(option.variantId)
  // );

  // Handler for multiselect change
  const handleVariantSelection = (variantId, item) => {
    if (selectedVariants.includes(variantId)) {
      setSelectedVariants(selectedVariants.filter((id) => id !== variantId));
      setVariantsOptionData(
        variantOptionData.filter((option) => option.variantId !== variantId)
      );
    } else {
      setSelectedVariants([...selectedVariants, variantId]);
      const variantOptionsForVariant = variantOption.filter(
        (option) => option.variantId === variantId
      );
      const updatedOptions = variantOptionsForVariant.map((option) => ({
        ...option,
        isSelected: false,
        price: 0,
      }));
      variantOptionData.push(...updatedOptions);
    }
    setRecentlyClickedVariant(variantId);
    setIsRecentlyClicked(true);

    // Reset the recently clicked state after a short delay (e.g., 500 ms)
    setTimeout(() => {
      setIsRecentlyClicked(false);
      setRecentlyClickedVariant(null);
    }, 500);
  };

  const handleAllergySelection = (allergyId) => {
    if (selectedAllergy.includes(allergyId)) {
      setSelectedAllergy(selectedAllergy.filter((id) => id !== allergyId));
    } else {
      setSelectedAllergy([...selectedAllergy, allergyId]);
    }
    setRecentlyClickedVariant(allergyId);
    setIsRecentlyClicked(true);

    setTimeout(() => {
      setIsRecentlyClicked(false);
      setRecentlyClickedVariant(null);
    }, 500);
  };

  const handleVariantOption = (option) => {
    const foundIndex = variantOptionData.findIndex(
      (element) => element._id === option._id
    );
    if (
      foundIndex !== -1 &&
      variantOptionData[foundIndex].isSelected === false
    ) {
      variantOptionData[foundIndex].isSelected = true;
    } else {
      variantOptionData[foundIndex].isSelected = false;
    }
  };

  const handlePriceChange = (option, event) => {
    const numericValue = parseFloat(event.target.value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      const foundIndex = variantOptionData.findIndex(
        (element) => element._id === option._id
      );
      if (foundIndex !== -1) {
        variantOptionData[foundIndex].price = numericValue;
      }
    }
  };

  // useEffect(() => {
  //   if (Array.isArray(variantOption)) {
  //     const newArray = variantOption.map((obj) => ({
  //       ...obj,
  //       isSelected: false,
  //       price: 0,
  //     }));
  //     setVariantsOptionData(newArray);
  //   }
  // }, [variantOption, isSuccess]);

  useEffect(() => {
    if (isError) {
      // toast.error(message);
    }
    if (isSuccess) {
      onHide();
      setTitle("");
      setPrice("");
      setCategory("");
      setDescription("");
      setSelectedVariants([]);
      setVariantsOptionData([]);
      setSelectedAllergy([]);
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  useEffect(() => {
    dispatch(allActiveCategoryByBranchId(selectBranches[0]));
    dispatch(allActiveVariantOption(selectBranches[0]));
    dispatch(allActiveAllergy(selectBranches[0]));
  }, [selectBranches]);
  const onSubmit = (e) => {
    e.preventDefault();
    const allergyData = selectedAllergy.map((allergyId) => {
      return { allergyId: allergyId };
    });
    const data = {
      title: title,
      price: price,
      categories: category,
      description: description,
      variantOptionData: variantOptionData,
      allergyData: allergyData,
      pageNo: props.currentPage + 1,
    };
    dispatch(addMenu({ branchId: selectBranches[0], data: data }));
    props.setSearchQuery("");
  };

  const handleCloseModal = () => {
    setTitle("");
    setPrice("");
    setCategory("");
    setDescription("");
    setSelectedVariants([]);
    setSelectedAllergy([]);
    props.onHide();
  };

  return (
    <Modal
      onHide={handleCloseModal}
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Menu</Modal.Title>
      </Modal.Header>
      <Form method="post" onSubmit={onSubmit}>
        <Modal.Body>
          <Row>
            <Col lg={4} sm={6}>
              <Form.Group controlId="text" style={{ marginBottom: "30px" }}>
                <Form.Label className="fw-bold">Title:</Form.Label>
                <Form.Control
                  type="text"
                  name="text"
                  placeholder="Enter Title"
                  required
                  style={{ width: "100%", padding: "5px" }}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={4} sm={6}>
              <Form.Group controlId="price" style={{ marginBottom: "30px" }}>
                <Form.Label className="fw-bold"> Price:</Form.Label>
                <Form.Control
                  type="number"
                  name="Price"
                  required
                  placeholder="Enter Price"
                  style={{ width: "100%", padding: "5px" }}
                  value={price}
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    if (enteredValue >= 1) {
                      setPrice(enteredValue);
                    } else {
                      setPrice("");
                    }
                  }}
                />
              </Form.Group>
            </Col>
            <Col lg={4} sm={6}>
              <Form.Group controlId="category" style={{ marginBottom: "30px" }}>
                <Form.Label className="fw-bold">Category</Form.Label>
                <Form.Select
                  aria-label="select"
                  required
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option selected disabled>
                    Select Category
                  </option>
                  {categories?.map((item) => (
                    <option key={item._id} value={item?._id}>
                      {item.title}
                    </option>
                  ))}
                  ;
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={7} sm={6}>
              <Form.Group
                controlId="description"
                style={{ marginBottom: "30px" }}
              >
                <Form.Label className="fw-bold">Description:</Form.Label>
                <Form.Control
                  type="text"
                  name="Description"
                  placeholder="Enter Description"
                  style={{ width: "100%", padding: "5px" }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={5} sm={6}>
              <Form.Group controlId="allergy" style={{ marginBottom: "30px" }}>
                <Form.Label className="fw-bold">Allergy</Form.Label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-dark"
                    className="w-100 text-start"
                  >
                    {selectedAllergy.length === 0
                      ? "Select Allergy"
                      : selectedAllergy.length === 1
                      ? toTitleCase(
                          allergy.find(
                            (allergy) => allergy._id === selectedAllergy[0]
                          )?.title
                        )
                      : `${selectedAllergy.length} Allergy`}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    {allergy?.map((item) => (
                      <Dropdown.Item
                        key={item._id}
                        onClick={() => handleAllergySelection(item._id, item)}
                      >
                        <Form.Check
                          key={item._id}
                          type="checkbox"
                          id={`checkbox-${item._id}`}
                          label={toTitleCase(item.title)}
                          checked={
                            selectedAllergy.includes(item._id) ||
                            (item._id === recentlyClickedVariant &&
                              isRecentlyClicked)
                          }
                        />
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Col>
            <Col lg={5} sm={6}>
              <Form.Group controlId="variant" style={{ marginBottom: "30px" }}>
                <Form.Label className="fw-bold">Variant</Form.Label>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-dark"
                    className="w-100 text-start"
                  >
                    {selectedVariants.length === 0
                      ? "Select Variant"
                      : selectedVariants.length === 1
                      ? toTitleCase(
                          variants.find(
                            (variant) => variant._id === selectedVariants[0]
                          )?.title
                        )
                      : `${selectedVariants.length} Variants`}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{ maxHeight: "200px", overflowY: "auto" }}
                  >
                    {variants?.map((item) => (
                      <Dropdown.Item
                        key={item._id}
                        onClick={() => handleVariantSelection(item._id, item)}
                      >
                        <Form.Check
                          key={item._id}
                          type="checkbox"
                          id={`checkbox-${item._id}`}
                          label={toTitleCase(item.title)}
                          checked={
                            selectedVariants.includes(item._id) ||
                            (item._id === recentlyClickedVariant &&
                              isRecentlyClicked)
                          }
                        />
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {selectedVariants.length > 0 &&
              selectedVariants.map((selectedVariant) => {
                const variant = variants.find((v) => v._id === selectedVariant);
                const variantOptionsForVariant = variantOption.filter(
                  (option) => option.variantId === selectedVariant
                );

                return (
                  <Col
                    key={variant._id}
                    lg={4}
                    style={{ marginBottom: "30px" }}
                  >
                    <Form.Group controlId={`variantOption-${variant._id}`}>
                      <Form.Label className="fw-bold">
                        {toTitleCase(variant.title)}
                      </Form.Label>
                      {variantOptionsForVariant.map((option) => (
                        <>
                          <Form.Check
                            key={option._id}
                            type="checkbox"
                            id={`checkbox-${option._id}`}
                            label={toTitleCase(option.title)}
                            onChange={() => handleVariantOption(option)}
                          />
                          <Form.Control
                            type="number"
                            name="Price"
                            placeholder="Enter Price"
                            style={{ width: "100%", padding: "5px" }}
                            onChange={(e) => handlePriceChange(option, e)}
                          />
                        </>
                      ))}
                    </Form.Group>
                  </Col>
                );
              })}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={props.onHide}>
            Close
          </Button>
          <Button variant="warning" type="submit">
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default AddMenu;
