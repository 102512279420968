import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, Pagination, Form, Col, Row,Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";

import {
  allCountry,
  addCountry,
  updateCountry,
  updateCountryStatus,
  reset,
} from "../../../redux/features/superAdmin/Country/createAction";
import GeneralIndicator from "../../model/GeneralIndicator";

const Country = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null); 
  const [inputName, setInputName] = useState('');
  const [inputCurrency, setInputCurrency] = useState('');
  const [inputCurrencySymbol, setInputCurrencySymbol] = useState('');
  const [currentIndex, setCurrentIndex] = useState(1);

  const {countries,isError, isSuccess, message, totalPages,isLoading } = useSelector((state) => state.country);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      setShowModal(false);
      setSelectedItem(null);
      setInputName("");
      setInputCurrency("");
      setInputCurrencySymbol("");
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  const handleEdit = (item) => {
    setSelectedItem(item);
    setInputName(item?.name);
    setInputCurrency(item?.currency);
    setInputCurrencySymbol(item?.currencySymbol);
    setShowModal(true);
  };
  useEffect(() => {
    dispatch(allCountry({pageNo:currentPage,search:searchQuery}));
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    if (selectedItem) {
      // Update existing item
      const updatedItem = {
        id: selectedItem?._id,
        name: inputName,
        currency: inputCurrency,
        currencySymbol: inputCurrencySymbol,
        pageNo: currentPage + 1,
      };
      dispatch(updateCountry({ data: updatedItem,search:searchQuery }));
    } else {
      // Add new item
      const newItem = {
        name: inputName,
        currency: inputCurrency,
        currencySymbol: inputCurrencySymbol,
        pageNo: currentPage + 1,
      };
      dispatch(addCountry({ data: newItem }));
      setSearchQuery('');
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setInputName("");
    setInputCurrency("");
    setInputCurrencySymbol("");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    dispatch(
      allCountry({pageNo: currentPage,search:event.target.value })
    );
     setCurrentPage(0);
     setCurrentIndex(1);
  };
  const handleUpdateStatus = (item) => {
    const newStatus = item?.isActive == true ? false : true;
    const data = {
      isActive: newStatus,
      _id: item._id,
      pageNo: currentPage + 1,
    };
    dispatch(updateCountryStatus({data: data,search:searchQuery }));
  };

  const handlePageChange = (pageNumber) => {
    let pageNo = pageNumber?.selected + 1;
    setCurrentPage(pageNumber?.selected);
    dispatch(allCountry({ pageNo: pageNo }));
    if(pageNo){
      setCurrentIndex(pageNo);
      }
  };

  return (
    <div>
       {isLoading ? <GeneralIndicator /> : null}
      <h2 className="p-3 fw-bold">Country</h2>
      <div className="bg-white">
        <div className="d-flex justify-content-between mb-3 p-4">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Form.Group>
          <Button variant="danger" onClick={() => setShowModal(true)}>
            Add Country
          </Button>
        </div>
        <Table striped  hover className="text-center">
          <thead>
            <tr>
              <th>sr#</th>
              <th>Country Name</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {countries?.map((item,index) => {
                const adjustedIndex = (currentIndex- 1) * 10 + index + 1;
                return (
              <tr key={index}>
                <td className="text-center fw-bold">{adjustedIndex}</td>
                <td>{item?.name}</td>
                <td>
                  {" "}
                  {item?.isActive === true ? (
                    <Button
                      className="btn-sm"
                      variant="success"
                      onClick={() => handleUpdateStatus(item)}
                    >
                      {item?.isActive === true ? "Active" : "InActive"}
                    </Button>
                  ) : (
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={() => handleUpdateStatus(item)}
                    >
                      {item?.isActive === true ? "Active" : "InActive"}
                    </Button>
                  )}
                </td>
                <td>
                  <Button
                    variant="warning"
                    className="me-3"
                    onClick={() => handleEdit(item)}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                </td>
              </tr>
                 )})}
          </tbody>
        </Table>

        <ReactPaginate
          nextLabel=">"
          pageRangeDisplayed={10}
          marginPagesDisplayed={2}
          previousLabel="<"
          pageClassName="page-item"
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pageCount={totalPages}
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination mx-3 p-2"
          activeClassName="active"
          forcePage={currentPage}
          renderOnZeroPageCount={null}
        />

        <Modal show={showModal} onHide={handleCloseModal} centered>
        <Form method="post" onSubmit={handleSave}>
          <Modal.Header closeButton>
            <Modal.Title  className='fw-bold '>
              {selectedItem ? 'Edit Country' : 'Add Country'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold'>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    value={inputName}
                    placeholder="Enter Country Name"
                    onChange={(e) => setInputName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold'>Currency:</Form.Label>
                  <Form.Control
                    type="text"
                    value={inputCurrency}
                    required
                    placeholder="Enter Currency Name"
                    onChange={(e) => setInputCurrency(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold'>CurrencySymbol:</Form.Label>
                  <Form.Control
                    type="text"
                    value={inputCurrencySymbol}
                    required
                    placeholder="Enter CurrencySymbol"
                    onChange={(e) => setInputCurrencySymbol(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className='mt-4'>
            <Button variant="danger" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="warning" type="submit">
              {selectedItem ? 'Save Changes' : 'Add Country'}
            </Button>
          </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Country;
