import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, Pagination, Form, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

const User = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const [lastId, setLastId] = useState(0);
  const [inputCategory, setInputCategory] = useState('');
  const [inputAmount, setInputAmount] = useState('');
  const [inputDescription, setInputDescription] = useState('');
  const [inputDate, setInputDate] = useState('');

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('ExpensesData'));

    if (storedData) {
      setData(storedData);
      setLastId(storedData[storedData.length - 1]?.id || 0);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('ExpensesData', JSON.stringify(data));
  }, [data]);

  const handleEdit = (item) => {
    setSelectedItem(item);
    setInputCategory(item.Category);
    setInputAmount(item.Amount);
    setInputDescription(item.Description);
    setInputDate(item.Date);
    setShowModal(true);
  };

  const handleSave = () => {
    if (selectedItem) {
      // Update existing item
      const updatedItem = {
        id: selectedItem.id,
        Category: inputCategory,
        Amount: inputAmount,
        Description: inputDescription,
        Date: inputDate,
      };
      const updatedData = data.map((d) =>
        d.id === selectedItem.id ? updatedItem : d
      );
      setData(updatedData);
    } else {
      // Add new item
      const newItem = {
        id: lastId + 1,
        Category: inputCategory,
        Amount: inputAmount,
        Description: inputDescription,
        Date: inputDate,
      };
      const updatedData = [...data, newItem];
      setData(updatedData);
      setLastId(lastId + 1);
    }

    setShowModal(false);
  };

  const handleDelete = (item) => {
    const updatedData = data.filter((d) => d.id !== item.id);
    setData(updatedData);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setInputCategory('');
    setInputAmount('');
    setInputDescription('');
    setInputDate('');
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredData = data.filter((item) =>
    item.Category.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div>
      <h2 className="p-3 fw-bold">Users</h2>
      <div className="bg-white">
        <div className="d-flex justify-content-between mb-3 p-4">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Form.Group>
          <Button variant="danger" onClick={() => setShowModal(true)}>
            Add User
          </Button>
        </div>
        <Table  hover className="text-center">
          <thead>
            <tr>
              <th>sr#</th>
              <th>Name</th>
              <th>UserName</th>
              <th>Mobile</th>
              <th>Role</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.Category}</td>
                <td>{item.Amount}</td>
                <td>{item.Description}</td>
                <td>{item.Date}</td>
                <td>
                  <Button
                    variant="warning"
                    className="me-3"
                    onClick={() => handleEdit(item)}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(item)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={filteredData.length}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />

        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title  className='fw-bold '>
              {selectedItem ? 'Edit User' : 'Add User'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
              <Form.Group>
                  <Form.Label className='fw-bold'>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold'>UserName:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter UserName"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Password:</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter Password"
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Confirm Password:</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter Confirm Password"
                    value={inputDate}
                    onChange={(e) => setInputDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
              <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Number:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Number"
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
              <Form.Group>
                  <Form.Label className='fw-bold pt-4'> UserType:</Form.Label>
                  <Form.Control
                    as="select"
                  >
                    <option value="Maintenance">SuperAdmin</option>
                    <option value="Repairing">Sub SuperAdmin</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
              <Form.Group>
                  <Form.Label className='fw-bold pt-4'> Branches:</Form.Label>
                  <Form.Control
                    as="select"
                  >
                    <option value="Maintenance">Branch1</option>
                    <option value="Repairing">Branch2</option>
                  </Form.Control>
                </Form.Group>
              </Col> 
            </Row>
          </Modal.Body>
          <Modal.Footer className='mt-4'>
            <Button variant="danger" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="warning" onClick={handleSave}>
              {selectedItem ? 'Save Changes' : 'Add User'}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default User;
