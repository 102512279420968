import { createSlice } from "@reduxjs/toolkit";

export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

export const userSlice = createSlice({
  name: "user",
  initialState: {
    status: STATUSES.IDLE,
    error: "",
  },
  reducers: {
    setStatus: function (state, action) {
      state.status = action.payload.status;
      state.error = action.payload.error;
    },
    default: (state) => {
      return [...state];
    },
  },
});

export const { setStatus } = userSlice.actions;

export default userSlice.reducer;
