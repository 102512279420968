import React ,{useEffect,useContext,useState} from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { AuthContext } from '../../../AuthContext';


import {useSelector,useDispatch} from 'react-redux';
import { useHistory } from "react-router-dom";

import { activeCountry } from '../../../redux/features/superAdmin/Country/createAction';
import { getSubscription } from '../../../redux/features/superAdmin/Subscription/subscriptionDetailsSlice';
import { addRestaurant } from '../../../redux/features/superAdmin/Restaurants/RestaurantDetailsSlice';




function AddRestaurant() {

  const {countries} = useSelector((state) => state.country);
  const subscription = useSelector((state) => state.subscription.subscriptions);
  const [restaurantName, setRestaurantName] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [ownerPhone, setOwnerPhone] = useState('');
  const [country, setCountry] = useState('');
  const [parcels, setParcels] = useState('');
  const [tables, setTables] = useState('');
  const [Subscription, setSubscription] = useState('');
  const [Address, setAddress] = useState('');

  const history = useHistory();

  const {currentUser} = useContext(AuthContext);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(activeCountry(currentUser));
    dispatch(getSubscription(currentUser));
 }, []);

 
const onSubmit = (e) => {
  e.preventDefault();
  dispatch(addRestaurant(currentUser,restaurantName,ownerName,ownerEmail,ownerPhone,country,parcels,tables,Subscription,Address,history));
};

    return (
      <>
      <div className={`main-wrapper `}>
        <div className="page-wrapper">

          <div className=" container-fluid">
            <div className="mt-5">
              <div className="row">
                <div className="col-sm-12">
                  <Row className="mb-5">
                  <Col>     <span style={{fontSize:"30px",fontWeight:"500"}}>Add Restaurant</span> </Col>
            </Row>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
        <Form method='post' onSubmit={onSubmit}>
                  <div className="card-body">
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold'>Restaurant Name:</Form.Label>
                  <Form.Control
                   type="text"
                   placeholder="Enter Restaurant Name"
                   onChange={(e) => setRestaurantName(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold'>Owner Name:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Owner Name"
                    onChange={(e) => setOwnerName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Owner Email:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Owner Email"
                    onChange={(e) => setOwnerEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Owner Phone:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Owner Phone"
                    onChange={(e) => setOwnerPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
              <Form.Group>
                  <Form.Label className='fw-bold pt-4'> Country:</Form.Label>
                  <Form.Select aria-label='select'
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option selected disabled >Select Country</option>
                    {countries.map(countries =>
                         <option key={countries._id} value={countries._id}>{countries.name}</option> )};
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Parcels:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Parcels"
                    onChange={(e) => setParcels(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Tables:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Tables"
                    onChange={(e) => setTables(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
              <Form.Group>
                  <Form.Label className='fw-bold pt-4'> Subscription:</Form.Label>
                  <Form.Select
                    onChange={(e) => setSubscription(e.target.value)}
                    as="select"
                    aria-label='select'
                  >
                    <option selected disabled >Select Subscription</option>
                     {subscription.map(subscription =>
                         <option key={subscription._id} value={subscription._id}>{subscription.title}</option> )};
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                  <Form.Group controlId="category" style={{ marginBottom: '30px' }}>
                                <Form.Label className='fw-bold pt-4'>Image</Form.Label>
                                <Form.Control
                                    type="File"
                                    name="image"
                                    placeholder=' '
                                    // style={{ width: '100%', padding: '5px' }}
                                />
                            </Form.Group>
              </Col>
              <Col lg={6}>
              <Form.Group>
                  <Form.Label className='fw-bold pt-4'>Address:</Form.Label>
                  <Form.Control
                   type='text'
                   placeholder="Enter Address"
                   onChange={(e) => setAddress(e.target.value)}
                  >
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Button type="submit" variant="success"  className="btn btn-primary float-end"  >
            Add Restaurant
          </Button>
                    
                  </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
    
        </div>
      </div>
      </>
    );
}

export default AddRestaurant;