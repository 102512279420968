import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categoryService from "./categoryService";

const initialState = {
  categories: [],
  totalPage: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//get all categories
export const allcategories = createAsyncThunk(
  "admin/allcategories",
  async (pageNo, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await categoryService.getAllCategory(user.accessToken, pageNo);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all categories by branch id
export const allCategoriesByBranchId = createAsyncThunk(
  "admin/allCategoriesByBranchId",
  async ({ branchId, pageNo,search }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await categoryService.getAllCategoryByBranchId(
        user.accessToken,
        branchId,
        pageNo,
        search
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//add categories
export const addcategories = createAsyncThunk(
  "admin/addcategories",
  async ({ branchId, data }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await categoryService.addCategory(
        user.accessToken,
        branchId,
        data
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update categories
export const updateCategories = createAsyncThunk(
  "admin/updateCategories",
  async ({ branchId, data,search }, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await categoryService.updateCategory(user.accessToken, data,branchId,search);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update categories Status
export const updateCategoriesStatus = createAsyncThunk(
  "admin/updateCategoriesStatus",
  async ({ branchId,data,search}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await categoryService.updateCategoryStatus(user.accessToken,data,branchId,search);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get Categories Active Status
export const allActivecategories = createAsyncThunk(
  "admin/Activecategories",
  async (thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await categoryService.getActiveCategory(user.accessToken);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get Categories Active Status by branch id
export const allActiveCategoryByBranchId = createAsyncThunk(
  "admin/allActiveCategoryByBranchId",
  async (branchId, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await categoryService.getActiveCategoryByBranchId(
        user.accessToken,
        branchId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get Categories and Menu Active Status
export const activeCategoriesMenu = createAsyncThunk(
  "admin/activeCategoriesMenu",
  async (branchId, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await categoryService.getActiveCategoryMenu(
        user.accessToken,
        branchId
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allcategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allcategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories = action.payload?.body;
        state.totalPage = action.payload?.total_pages;
      })
      .addCase(allcategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.categories = null;
      })
      .addCase(allCategoriesByBranchId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allCategoriesByBranchId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories = action.payload?.body;
        state.totalPage = action.payload?.total_pages;
      })
      .addCase(allCategoriesByBranchId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.categories = [];
      })
      .addCase(addcategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addcategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories = action.payload?.body;
        state.totalPage = action.payload?.total_pages;
      })
      .addCase(addcategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories = action.payload;
      })
      .addCase(updateCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateCategoriesStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCategoriesStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories = action.payload;
      })
      .addCase(updateCategoriesStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(allActivecategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allActivecategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories = action.payload;
      })
      .addCase(allActivecategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.categories = [];
      })
      .addCase(allActiveCategoryByBranchId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allActiveCategoryByBranchId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories = action.payload;
      })
      .addCase(allActiveCategoryByBranchId.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.categories = [];
      })
      .addCase(activeCategoriesMenu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activeCategoriesMenu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories = action.payload;
      })
      .addCase(activeCategoriesMenu.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.categories = [];
      });
  },
});
export const { reset } = categorySlice.actions;

export default categorySlice.reducer;
