import React from 'react';
import { Navbar, Container, Card, Form, Button } from 'react-bootstrap';
import PingLogo from "./ping logo.png"
import logo from "./tittle logo.png"
import { useHistory } from "react-router-dom";


function ForgotPassword() {

    const history = useHistory();

//navigate to forgotPassword-OTP 
const navigateToLink = () => {
  history.push("/forgotPassword/otp");
};

    return <>
       <Navbar bg="light" variant="dark">
            <Container>
                <Navbar.Brand href="#">
                    <div className='d-flex'>
                        <img src={PingLogo} alt="logo" className='me-2' />
                        <img src={logo} alt="logo" />
                    </div>
                </Navbar.Brand>
            </Container>
        </Navbar>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80vh'
        }}
        >
            <Container>
                <Card style={{ width: '500px', padding: '50px', margin: '10px auto' }}>
                    <Card.Body>
                        <Card.Text className='text-center h2 fw-bold'>Forgot Password</Card.Text>
                        <Card.Text className='text-center h6 '>A 6 Digit OTP Code will be sent to your registered email</Card.Text>
                        <Form>
                                <Form.Group controlId="name" style={{ marginBottom: '30px', maxWidth: '100vh', }}>
                                    <Form.Label className='mt-4 h5'><b>Email address:</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder='Enter your email address'
                                        //   value={profile.name}
                                        //   onChange={handleInputChange}
                                        style={{ width: '100%', padding: '15px' }}
                                    />
                                </Form.Group>
                            <div className='text-center'>
                                <Button type="submit"  onClick={navigateToLink} variant="warning" className='text-center' style={{width: '100%', padding: '10px 140px' }}>
                                    Send OTP
                                </Button>
                            </div>
                        </Form>

                    </Card.Body>

                </Card>
            </Container>
        </div>
    </>
        ;
}

export default ForgotPassword;