import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Table,
  Pagination,
  Form,
  Col,
  Row,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import {
  allVariantOption,
  addVariantOption,
  updateVariantOption,
  updateVariantOptionStatus,
  reset,
} from "../../../redux/features/admin/VariantOption/VariantOptionSlice";
import { allActiveVariant } from "../../../redux/features/admin/Variant/VariantSlice";
import ReactPaginate from "react-paginate";
import GeneralIndicator from "../../model/GeneralIndicator";

const VariantOption = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]); // Set the initial value based on table data
  const [showModal, setShowModal] = useState(false); // Set the initial value based on Add modal show
  const [searchQuery, setSearchQuery] = useState(""); // Set the initial value based on Search for Table
  const [currentPage, setCurrentPage] = useState(0); // Set the initial value based on currentPage for Table
  const [itemsPerPage] = useState(10); // Set the initial value based on itemsPerPage for Table
  const [selectedItem, setSelectedItem] = useState(null); // Set the initial value based on selected modal add/edit
  const [lastId, setLastId] = useState(0); // Set the initial value based on Id previous value
  const [inputTitle, setInputTitle] = useState(""); // Set the initial value based on Id previous val
  const [inputVariant, setInputVariant] = useState(""); // Set the initial value on inputAmout field

  const { selectBranches } = useSelector((state) => state.branches);
  const { variantOption, isError, isLoading, isSuccess, message, totalPages } =
    useSelector((state) => state.variantOption);
  const { variant } = useSelector((state) => state.variant);
  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      setShowModal(false);
      setInputTitle("");
      setInputVariant("");
      setSelectedItem(null);
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);
  useEffect(() => {
    dispatch(allVariantOption({ branchId: selectBranches[0] }));
    dispatch(allActiveVariant(selectBranches[0]));
    setCurrentPage(0);
  }, [selectBranches]);
  // Handle Edit Function is used for edit the data of expensesData
  const handleEdit = (item) => {
    setSelectedItem(item);
    setInputTitle(
      item?.title?.charAt(0)?.toUpperCase() + item?.title?.slice(1)
    );
    setInputVariant(item?.variant?._id);
    setShowModal(true);
  };

  // Handle Save Function is used for Edit the data of expensesData
  const handleSave = (e) => {
    e.preventDefault();
    if (selectedItem) {
      // Update existing item
      let data = {
        _id: selectedItem._id,
        title: inputTitle,
        variantId: inputVariant,
        pageNo: currentPage + 1,
      };
      dispatch(
        updateVariantOption({ branchId: selectBranches[0], data: data })
      );
    } else {
      // Add new item
      let data = {
        title: inputTitle,
        variantId: inputVariant,
        pageNo: currentPage + 1,
      };
      dispatch(addVariantOption({ branchId: selectBranches[0], data: data }));
    }
  };

  const handleUpdateStatus = (item) => {
    const newStatus = item?.isActive == true ? false : true;
    const data = {
      isActive: newStatus,
      _id: item._id,
      pageNo: currentPage + 1,
    };
    dispatch(
      updateVariantOptionStatus({ branchId: selectBranches[0], data: data })
    );
  };

  // Handle delete Function is used for delete the data of expensesData
  const handleDelete = (item) => {
    const updatedData = data.filter((d) => d.id !== item.id);
    setData(updatedData);
  };

  // Handle Close Function is used for close the modal of add/edit expensesData
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setInputTitle("");
    setInputVariant("");
  };

  // Handle Search Function is used for search the data in table
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  // handlePageChange Function is used for Change Page the data in table

  const handlePageChange = (pageNumber) => {
    let pageNo = pageNumber?.selected + 1;
    setCurrentPage(pageNumber?.selected);
    dispatch(allVariantOption({ branchId: selectBranches[0], pageNo: pageNo }));
  };
  // logic for indexOfLastItem
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage; // logic for indexOfFirstItem
  //logic for filtered data
  const filteredData = data.filter((item) =>
    item.Category.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div>
      {isLoading && <GeneralIndicator />}
      <h2 className="p-3 fw-bold">Variant Option</h2>
      <div className="bg-white">
        <div className="d-flex justify-content-between mb-3 p-4">
          <Form.Group>
            {/* <Form.Control
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
            /> */}
          </Form.Group>
          <Button variant="danger" onClick={() => setShowModal(true)}>
            Add Variant Option
          </Button>
        </div>
        <Table
          style={{ borderRadius: "5px", overflow: "hidden" }}
          className="text-center"
        >
          <thead>
            <tr>
              <th>sr#</th>
              <th>Title</th>
              <th>Variant</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {variantOption?.map((item, index) => (
              <tr key={index}>
                <td className="text-center fw-bold">{index + 1}</td>
                <td>
                  {item?.title?.charAt(0)?.toUpperCase() +
                    item?.title?.slice(1)}
                </td>
                <td>
                  {item?.variant?.title.charAt(0)?.toUpperCase() +
                    item?.variant?.title.slice(1)}
                </td>
                <td>
                  {" "}
                  {item?.isActive === true ? (
                    <Button
                      className="btn-sm"
                      variant="success"
                      onClick={() => handleUpdateStatus(item)}
                    >
                      {item?.isActive === true ? "Active" : "InActive"}
                    </Button>
                  ) : (
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={() => handleUpdateStatus(item)}
                    >
                      {item?.isActive === true ? "Active" : "InActive"}
                    </Button>
                  )}
                </td>
                <td>
                  <Button
                    variant="warning"
                    className="me-3"
                    onClick={() => handleEdit(item)}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ReactPaginate
          nextLabel=">"
          pageRangeDisplayed={10}
          marginPagesDisplayed={2}
          previousLabel="<"
          pageClassName="page-item"
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pageCount={totalPages}
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination mx-4 p-2"
          activeClassName="active"
          forcePage={currentPage}
          renderOnZeroPageCount={null}
        />

        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={filteredData.length}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />

        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Form method="post" onSubmit={handleSave}>
            <Modal.Header closeButton>
              <Modal.Title className="fw-bold ">
                {selectedItem ? "Edit Variant Option" : "Add Variant Option"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className="fw-bold">Title:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={inputTitle}
                      placeholder="Enter Variant Option Title"
                      onChange={(e) => setInputTitle(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className="fw-bold">Variant:</Form.Label>
                    <Form.Select
                      aria-label="select"
                      value={inputVariant}
                      required
                      onChange={(e) => setInputVariant(e.target.value)}
                    >
                      <option value="" disabled hidden>
                        Select Variant
                      </option>
                      {variant?.map((item) => (
                        <option key={item._id} value={item?._id}>
                          {item?.title?.charAt(0)?.toUpperCase() +
                            item?.title?.slice(1)}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="mt-4">
              <Button variant="danger" onClick={handleCloseModal}>
                Close
              </Button>
              <Button type="submit" variant="warning">
                {selectedItem ? "Save Changes" : "Add Variant Option"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default VariantOption;
