import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// import Colleagues from './Colleagues';
// import AssignedTables from './AssignedTables';
// import { ColleagueData, ColleagueDataNew, AssignedTablesData } from './JsonData.js';

// import ServedOrder from './ServedOrder';
// import { ServedData } from "./JsonData"

// import DateTime from './DateTime';
// import { DateTimeData } from "./JsonData"

import SaleDashboard from "./SaleDashboard";
import { SaleDashboardData } from "../../../JsonData";

import SaleTodayStatus from "./SaleTodayStatus";
import Accounts from "./Accounts";

function Sale() {
    return (
        <div>
            <Container className='mt-5'>
                <SaleDashboard DashboardTopCards={SaleDashboardData.topCards} />
                <SaleTodayStatus LatestOrders={SaleDashboardData.latestOrders} />
                <Accounts />
            </Container>
        </div>

    );
}

export default Sale;