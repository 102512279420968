import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const addOrder = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/admin/order/${data._id}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Successfully Added Order");

    return response.data.body;
  }
};
const getOrderStatus = async (token, branchId, tableId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/order/status/branch?branchId=${branchId}&tableId=${
      tableId ? tableId : ""
    }`,
    config
  );
  if (response.data.body) {
    return response.data.body;
  }
};
const addOrderItem = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/admin/orderItem?branchId=${data.branchId}&id=${
      data.orderId
    }&type=${data.type}&tableId=${data.tableId ? data.tableId : ""}`,
    data,
    config
  );
  if (response.data.body) {
    return response.data.body;
  }
};

const clearOrder = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(
    `${BASE_URL}/admin/order/${data?.id}?tableId=${
      data.tableId ? data.tableId : ""
    }`,
    config
  );
  if (response.data.body) {
    return response.data.body;
  }
};

// const getAllOrder = async (token, pageNo) => {
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   const response = await axios.get(
//     `${BASE_URL}/admin/order/all?page=${pageNo}`,
//     config
//   );
//   if (response.data.body) {
//     return response.data;
//   }
// };

const getAllOrderByBranchId = async (token, branchId, pageNo) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/order/all/branch?branchId=${branchId}&page=${pageNo}`,
    config
  );
  if (response.data.body) {
    return response.data;
  }
};

const getDiningAndProcessOrder = async (token, branchId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/order?branchId=${branchId}`,
    config
  );
  if (response.data.body) {
    return response.data.body;
  }
};

const orderService = {
  addOrder,
  getOrderStatus,
  addOrderItem,
  clearOrder,
  // getAllOrder,
  getAllOrderByBranchId,
  getDiningAndProcessOrder
};

export default orderService;
