import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const addMenu = async (token, branchId, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/admin/menu/add?branchId=${branchId}&page=${data?.pageNo}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Menu Added Successfully");
    return response.data;
  }
};

const getAllMenu = async (token, pageNo) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/menu/all/?page=${pageNo}`,
    config
  );
  if (response.data.body) {
    return response.data;
  }
};

const getAllMenuByBranchId = async (token, branchId, pageNo,search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/menu/all/branch?branchId=${branchId}&page=${pageNo}&search=${search ? search : ""}`,
    config
  );
  if (response.data.body) {
    return response.data;
  }
};

const getActiveMenu = async (token, id,branchId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${BASE_URL}/admin/menu/active/?id=${id ? id : ""}&branchId=${branchId}`,
    config
  );
  if (response.data.body) {
    return response.data.body;
  }
};
const updateMenuStatus = async (token, data,branchId,search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/admin/menu/status/${data._id}?page=${data.pageNo}&branchId=${branchId}&search=${search ? search : ""}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Menu Status Updated Successfully");
    return response.data.body;
  }
};

const updateMenu = async (token, data,branchId,search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(
    `${BASE_URL}/admin/menu/${data._id}?page=${data.pageNo}&branchId=${branchId}&search=${search ? search : ""}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Menu Updated Successfully");
    return response.data.body;
  }
};

const menuService = {
  addMenu,
  getAllMenu,
  getAllMenuByBranchId,
  updateMenuStatus,
  updateMenu,
  getActiveMenu,
};

export default menuService;
