import React from "react";
import { Tab, Tabs, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping, faClock } from "@fortawesome/free-solid-svg-icons";

function BookingCards(props) {
  return (
    <Tabs defaultActiveKey="Upcoming" className="p-3" id="my-tabs">
      <Tab eventKey="Upcoming" title="Upcoming">
        {props.BookingCardsData.map((val, ind) => {
          return (
            <Card className="my-3" key={ind}>
              <Card.Body>
                <Card.Img
                  src={val.customerImg}
                  alt="Card Image"
                  style={{ width: "100px", borderRadius: "50px" }}
                />
                <div className="d-flex justify-content-between text-center mt-4 ">
                  <Card.Text className="h5 fw-bold">
                    {" "}
                    {val.customerName}
                  </Card.Text>
                  <Card.Text className="h5 fw-bold">
                    {" "}
                    <FontAwesomeIcon icon={faClock} /> {val.time}{" "}
                    <span className="ms-5">
                      <FontAwesomeIcon icon={faBagShopping} /> {val.date}
                    </span>
                  </Card.Text>
                </div>
                <div className="d-flex justify-content-between text-center">
                  <Card.Text className="p"> {val.customerMail}</Card.Text>
                  <Card.Text className="p"> {val.persons}</Card.Text>
                </div>
              </Card.Body>
            </Card>
          );
        })}
      </Tab>
      <Tab eventKey="History" title="History">
        {props.BookingCardsData.map((val, ind) => {
          return (
            <Card className="my-3" key={ind}>
              <Card.Body>
                <Card.Img
                  src={val.customerImg}
                  alt="Card Image"
                  style={{ width: "100px", borderRadius: "50px" }}
                />
                <div className="d-flex justify-content-between text-center mt-4 ">
                  <Card.Text className="h5 fw-bold">
                    {" "}
                    {val.customerName}
                  </Card.Text>
                  <Card.Text className="h5 fw-bold">
                    {" "}
                    <FontAwesomeIcon icon={faClock} /> {val.time}{" "}
                    <span className="ms-5">
                      <FontAwesomeIcon icon={faBagShopping} /> {val.date}
                    </span>
                  </Card.Text>
                </div>
                <div className="d-flex justify-content-between text-center">
                  <Card.Text className="p"> {val.customerMail}</Card.Text>
                  <Card.Text className="p"> {val.persons}</Card.Text>
                </div>
              </Card.Body>
            </Card>
          );
        })}
      </Tab>
    </Tabs>
  );
}

export default BookingCards;
