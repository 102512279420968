import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const addAllergy = async (token, branchId, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${BASE_URL}/admin/allergy/add?branchId=${branchId}&page=${data?.pageNo}`,
    data,
    config
  );
  if (response.data.body) {
    toast.success("Allergy Added Successfully");
    return response.data;
  }
};

const getAllAllergy = async (token, branchId,pageNo) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/allergy?branchId=${branchId}&page=${pageNo}`,
      config
    );
    if (response.data.body) {
      return response.data;
    }
  };

  const updateAllergy = async (token,branchId,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/admin/allergy/${data._id}?branchId=${branchId}&page=${data?.pageNo}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Allergy Updated Successfully");
      return response.data.body;
    }
  };

  const updateAllergyStatus = async (token,branchId,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/admin/allergy/status/${data._id}?branchId=${branchId}&page=${data?.pageNo}`,
      data,
      config
    );
    if (response.data.body) {
      toast.success("Allergy Status Updated Successfully");
      return response.data.body;
    }
  };
  const getActiveAllergy = async (token,branchId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get(
      `${BASE_URL}/admin/allergy/active?branchId=${branchId}`,
      config
    );
    if (response.data.body) {
      return response.data.body;
    }
  };
const allergyService = {
    addAllergy,
    getAllAllergy,
    updateAllergy,
    updateAllergyStatus,
    getActiveAllergy
};

export default allergyService;
