import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import variantOptionService from "./VariantOptionService";

const initialState = {
  variantOption: [],
  totalPages: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};
// get Variant
export const allVariantOption = createAsyncThunk(
  "admin/allVariantOption",
  async ({branchId,pageNo}, thunkAPI) => {
    try {
      const user = JSON.parse(localStorage.getItem("currentUser"));
      return await variantOptionService.getAllVariantOption(user.accessToken, branchId,pageNo);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//add variant
export const addVariantOption = createAsyncThunk(
    "admin/addVariantOption",
    async ({ branchId, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await variantOptionService.addVariantOption(user.accessToken, branchId, data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  //update variant
export const updateVariantOption = createAsyncThunk(
    "admin/updateVariantOption",
    async ({ branchId, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await variantOptionService.updateVariantOption(user.accessToken,branchId,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  //update variant Status
  export const updateVariantOptionStatus = createAsyncThunk(
    "admin/updateVariantOptionStatus",
    async ({ branchId, data }, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await variantOptionService.updateVariantOptionStatus(user.accessToken,branchId,data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const allActiveVariantOption = createAsyncThunk(
    "admin/allActiveVariantOption",
    async (branchId, thunkAPI) => {
      try {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        return await variantOptionService.getActiveVariantOption(
          user.accessToken,
          branchId
        );
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const variantOptionSlice = createSlice({
  name: "variantOption",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allVariantOption.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allVariantOption.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.variantOption = action.payload?.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(allVariantOption.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.variant = [];
      })
      .addCase(addVariantOption.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addVariantOption.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.variantOption = action.payload.body;
        state.totalPages = action.payload?.total_pages;
      })
      .addCase(addVariantOption.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateVariantOption.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVariantOption.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.variantOption = action.payload;
      })
      .addCase(updateVariantOption.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(updateVariantOptionStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVariantOptionStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.variantOption = action.payload;
      })
      .addCase(updateVariantOptionStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(allActiveVariantOption.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allActiveVariantOption.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.variantOption = action.payload;
      })
      .addCase(allActiveVariantOption.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});
export const { reset } = variantOptionSlice.actions;

export default variantOptionSlice.reducer;
