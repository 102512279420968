import {createSlice} from '@reduxjs/toolkit';

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
  });
  
  export const subscriptionSlice = createSlice({
    name: 'subscription:',
    initialState: {
      status: STATUSES.IDLE,
      error: '',
      subscriptions:[]
    },
    reducers: {
      setStatus: function (state, action) {
        state.status = action.payload.status;
        state.error = action.payload.error;
      },
      setSubscriptions: function (state, action) {
        state.subscriptions = action.payload.subscriptions;
        state.error = action.payload.error;
      },
      default: (state) => {
        return [...state];
      },
    },
  });
  
  export const {setStatus,setSubscriptions} = subscriptionSlice.actions;
  
  export default subscriptionSlice.reducer;