import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const getPaymentMethod = async (branchId) => {
  const response = await axios.get(
    `${BASE_URL}/customer/paymentMethod?branchId=${branchId}`
  );
  if (response.data.body) {
    return response.data.body;
  }
};


const paymentMethodService = {
    getPaymentMethod
};

export default paymentMethodService;