import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import otpService from "./otpService";

const initialState = {
  otp: {},
  transactionVerifyPayStack: {},
  isError: false,
  isSuccess: false,
  isSuccessVerify: false,
  isLoading: false,
  message: "",
};

export const sendOtp = createAsyncThunk(
  "customer/sendOtp",
  async (data, thunkAPI) => {
    try {
      return await otpService.sendOtp(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "customer/verifyOtp",
  async (data, thunkAPI) => {
    try {
      return await otpService.verifyOtp(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const payStack = createAsyncThunk(
  "customer/payStack",
  async (data, thunkAPI) => {
    try {
      return await otpService.payStack(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyPayStack = createAsyncThunk(
  "customer/verifyPayStack",
  async (reference, thunkAPI) => {
    try {
      return await otpService.payStackVerfiy(reference);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {
    resetPayStack: (state) => {
      state.isLoading = false;
      state.isError = false;
      (state.isSuccess = false), (state.transactionVerifyPayStack = {});
      state.isSuccessVerify = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.otp = action.payload;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.otp = null;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccessVerify = true;
        state.otp = action.payload;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.otp = null;
      })
      .addCase(payStack.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(payStack.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(payStack.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(verifyPayStack.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyPayStack.fulfilled, (state, action) => {
        state.isLoading = false;
        state.transactionVerifyPayStack = action.payload;
      })
      .addCase(verifyPayStack.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});
export const { resetPayStack } = otpSlice.actions;

export default otpSlice.reducer;
