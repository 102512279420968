import React, { useState } from "react";
import { Form, Modal, Button, Col, Row, Table, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash} from "@fortawesome/free-solid-svg-icons";
import Asun from "../../components/Images/Menu-Items/Asun.png";
import CustomerEmail from "./CustomerEmail";
import {
  addOrderItem,
  clearOrder,
} from "../../redux/features/customer/Order/orderSlice.js";
import { useDispatch } from "react-redux";
import Order from "./Order";

function Cart(props) {
  const dispatch = useDispatch();
  // const [modalEmailShow, setModalEmailShow] = React.useState(false);
  const [modalOrderShow, setModalOrderShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handleIncrement = (id) => {
    let data = {
      orderId: props?.order?.order?._id,
      orderItemId: id,
      type: "increment",
      userId: props?.order?.order?.customerId,
      branchId: props.branchId,
    };
    dispatch(addOrderItem(data));
  };

  const handleDecrement = (item) => {
    if (item?.quantity != 1) {
      let data = {
        orderId: props?.order?.order?._id,
        orderItemId: item?._id,
        type: "decrement",
        userId: props?.order?.order?.customerId,
        branchId: props.branchId,
      };
      dispatch(addOrderItem(data));
    } else {
      let data = {
        orderId: props?.order?.order?._id,
        orderItemId: item?._id,
        type: "delete",
        userId: props?.order?.order?.customerId,
        branchId: props.branchId,
      };
      dispatch(addOrderItem(data));
    }
  };

  const handleIncrementVariantOption = (option, item) => {
    let data = {
      orderId: props?.order?.order?._id,
      orderItemId: item?._id,
      type: "incrementVariantOption",
      userId: props?.order?.order?.customerId,
      branchId: props.branchId,
      variantOptionName: option?.title,
      updateItemPrice: option?.price + item?.itemPrice,
    };
    dispatch(addOrderItem(data));
  };

  const handleDecrementVariantOption = (option, item) => {
    if (option?.quantity != 1) {
      let data = {
        orderId: props?.order?.order?._id,
        orderItemId: item?._id,
        type: "decrementVariantOption",
        userId: props?.order?.order?.customerId,
        branchId: props.branchId,
        variantOptionName: option?.title,
        updateItemPrice: item?.itemPrice - option?.price,
      };
      dispatch(addOrderItem(data));
    } else {
      let data = {
        orderId: props?.order?.order?._id,
        orderItemId: item?._id,
        type: "deleteVariantOption",
        userId: props?.order?.order?.customerId,
        branchId: props.branchId,
        variantOptionName: option?.title,
        updateItemPrice: item?.itemPrice - option?.price,
      };
      dispatch(addOrderItem(data));
    }
  };
  const handleClearOrder = () => {
    let data = {
      orderId: props?.order?.order?._id,
      userId: props?.order?.order?.customerId,
      branchId: props?.branchId,
    };
    dispatch(clearOrder(data));
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Your Cart
          </Modal.Title>
        </Modal.Header>
        <Form method="post">
          <Modal.Body>
            <Button
              variant="danger"
              style={{ width: "100%" }}
              onClick={handleClearOrder}
            >
              <FontAwesomeIcon icon={faTrash} /> Empty
            </Button>
            {props?.order?.orderItemList?.map((item) => (
              <Card className="my-3">
                <Card.Body className="d-flex">
                  <Card.Img
                    src={Asun}
                    alt="dimsins"
                    className=""
                    style={{ width: "70px", height: "70px" }}
                  />
                  <div className="d-grid"  style={{ width: "280px" }}>
                    <Card.Text className="px-2 text-danger fw-bold mb-0">
                      {item?.menuCategoriesId?.title}
                    </Card.Text>
                    <Card.Text className="px-2 fw-bold mb-0">
                      {props?.currency} {item?.itemPrice * item?.quantity}
                    </Card.Text>

                    {item?.variantOption?.map((option, index) => (
                      <Card.Text className="px-2 fw-bold mb-0">
                          <div
                            key={index}
                            className="d-flex justify-content-between"
                          >
                          <span>
                            {option?.quantity}x
                            {option?.title?.charAt(0)?.toUpperCase() +
                              option?.title?.slice(1)}
                          </span>
                          <div className="text-right">
                          <Button
                            variant="link"
                            className="bg-dark btn-sm text-white text-decoration-none rounded-circle mx-1 px-2 py-0"
                            onClick={() =>
                              handleDecrementVariantOption(option, item)
                            }
                          >
                            -
                          </Button>
                          {option?.quantity}
                          <Button
                            variant="link"
                            className="bg-dark text-white btn-sm text-decoration-none rounded-circle  mx-2 px-2 py-0"
                            onClick={() =>
                              handleIncrementVariantOption(option, item)
                            }
                          >
                            +
                          </Button>
                          </div>
                          </div>
                      </Card.Text>
                    ))}
                  </div>
                  <div className="ms-auto mt-0">
                    <Card.Text>
                      <Button
                        variant="link"
                        className="bg-dark btn-sm text-white text-decoration-none rounded-circle mx-1 px-2 py-0"
                        onClick={() => handleDecrement(item)}
                      >
                        -
                      </Button>
                      {item?.quantity}
                      <Button
                        variant="link"
                        className="bg-dark text-white btn-sm text-decoration-none rounded-circle  mx-1 px-2 py-0"
                        onClick={() => handleIncrement(item?._id)}
                      >
                        +
                      </Button>
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            ))}

            <Card className="my-3">
              <Card.Header>
                <Row>
                  {" "}
                  <Col lg={6}>
                    <b>Subtotal:</b>
                  </Col>{" "}
                  <Col lg={6} className="text-danger">
                    <b>
                      {props?.currency} {props?.order?.totalPayment}
                    </b>
                  </Col>{" "}
                </Row>
              </Card.Header>
              {/* <Card.Body >
                            <Form.Check
                                type="switch"
                                id="toggle-switch"
                                label="See Promotion" 
                                checked={isChecked}
                                onChange={handleToggle}
                                />
                        {isChecked ?  <Row className='my-3'> <Col lg={6}> <Form.Control placeholder="Enter Coupon Code" /></Col>
                         <Col lg={6}> <Button variant="secondary">Apply</Button></Col>
                         </Row>:"" }        
                      
                        </Card.Body> */}
              <Card.Footer>
                <Row>
                  {" "}
                  <Col lg={6}>
                    <b>Order Total:</b>
                  </Col>{" "}
                  <Col lg={6}>
                    <b>
                      {props?.currency} {props?.order?.totalPayment}
                    </b>
                  </Col>{" "}
                </Row>
              </Card.Footer>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            {props?.order?.orderItemList?.length ? (
              <Button
                variant="warning"
                style={{ width: "100%" }}
                onClick={() => {
                  setModalOrderShow(true);
                  props.onHide();
                }}
              >
                Checkout
              </Button>
            ) : (
              ""
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      <Order
        onHide={() => setModalOrderShow(false)}
        show={modalOrderShow}
        order={props.order}
        branchId={props.branchId}
      />

      {/* <CustomerEmail  onHide={() => setModalEmailShow(false)}  show={modalEmailShow}/> */}
    </>
  );
}

export default Cart;
