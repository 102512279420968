import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Table,
  Pagination,
  Form,
  Col,
  Row,
  Badge,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  allStaff,
  addStaff,
  updateStaff,
  reset,
  updateStaffStatus,
} from "../../../redux/features/admin/Staff/staffSlice";
import ReactPaginate from "react-paginate";
import GeneralIndicator from "../../model/GeneralIndicator";

const Staff = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const [inputName, setInputName] = useState("");
  const [inputUsername, setInputUsername] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputConfirmPsw, setInputConfirmPsw] = useState("");
  const [inputNumber, setInputNumber] = useState("");
  const [inputRole, setInputRole] = useState("");
  const [lastId, setLastId] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputEmail, setInputEmail] = useState("");

  const { selectBranches } = useSelector((state) => state.branches);
  const { staff, isError, isLoading, isSuccess, message, totalPages } =
    useSelector((state) => state.staff);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    if (isSuccess) {
      setShowModal(false);
      setSelectedItem(null);
      setInputEmail("");
      setInputUsername("");
      setInputPassword("");
      setInputConfirmPsw("");
      setInputNumber("");
      setInputRole("");
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  useEffect(() => {
    if (selectBranches.length > 0 && selectBranches[0] !== undefined) {
      dispatch(allStaff({ branchId: selectBranches[0] }));
    }
  }, [selectBranches]);
  
  const handleEdit = (item) => {
    setSelectedItem(item);
    setInputUsername(item?.username);
    setInputEmail(item?.email);
    setInputNumber(item?.mobile);
    setInputRole(item?.usertype?.title);
    setShowModal(true);
  };

  const handleUpdateStatus = (item) => {
    const newStatus = item?.status == "active" ? "inactive" : "active";
    const data = { status: newStatus, _id: item._id, pageNo: currentPage + 1 };
    dispatch(
      updateStaffStatus({
        branchId: selectBranches[0],
        data: data,
        search: searchQuery,
      })
    );
  };

  const handleSave = () => {
    if (selectedItem) {
      // Update existing item
      const updatedItem = {
        id: selectedItem?._id,
        username: inputUsername,
        email: inputEmail,
        mobile: inputNumber,
        userTypeTitle: inputRole,
        pageNo: currentPage + 1,
      };
      dispatch(
        updateStaff({
          branchId: selectBranches[0],
          data: updatedItem,
          search: searchQuery,
        })
      );
    } else {
      // Add new item
      const newItem = {
        username: inputUsername,
        email: inputEmail,
        password: inputPassword,
        mobile: inputNumber,
        userTypeTitle: inputRole,
        pageNo: currentPage + 1,
      };
      dispatch(addStaff({ branchId: selectBranches[0], data: newItem }));
      setSearchQuery("");
    }
  };

  const handleDelete = (item) => {
    const updatedData = data.filter((d) => d.id !== item.id);
    setData(updatedData);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setInputEmail("");
    setInputUsername("");
    setInputPassword("");
    setInputConfirmPsw("");
    setInputNumber("");
    setInputRole("");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    dispatch(
      allStaff({
        branchId: selectBranches[0],
        pageNo: currentPage,
        search: event.target.value,
      })
    );
    setCurrentPage(0);
  };

  const handlePageChange = (pageNumber) => {
    let pageNo = pageNumber?.selected + 1;
    setCurrentPage(pageNumber?.selected);
    dispatch(allStaff({ branchId: selectBranches[0], pageNo: pageNo }));
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Do something with the selected option
    // Close the modal
    // ...
  };

  function toTitleCase(str) {
    return str?.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return (
    <div>
      {isLoading && <GeneralIndicator />}
      <h2 className="p-3 fw-bold">Staff</h2>
      <div className="bg-white">
        <div className="d-flex justify-content-between mb-3 p-4">
          <Form.Group>
            {/* <Form.Label>Search:</Form.Label> */}
            <Form.Control
              type="text"
              placeholder="Search by UserName"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Form.Group>
          <Button variant="danger" onClick={() => setShowModal(true)}>
            Add Staff
          </Button>
        </div>
        <Table
          striped
          style={{ borderRadius: "5px", overflow: "hidden" }}
          hover
          className="text-center"
        >
          <thead>
            <tr>
              <th>sr#</th>
              <th>UserName</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Role</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {staff?.map((item, index) => (
              <tr key={item?.user?.id}>
                <td className="text-center fw-bold">{index + 1}</td>
                <td>{item?.user?.username}</td>
                <td>{item?.user?.email}</td>
                {/* <td>{item.Password}</td> */}
                {/* <td>{item.ConfirmPsw}</td> */}
                <td>{item?.user?.mobile}</td>
                <td>{toTitleCase(item?.userType?.title)}</td>
                <td>
                  {" "}
                  {item?.user?.status === "active" ? (
                    <Button
                      className="btn-sm"
                      variant="success"
                      onClick={() => handleUpdateStatus(item?.user)}
                    >
                      {item?.user?.status === "active" ? "Active" : "InActive"}
                    </Button>
                  ) : (
                    <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={() => handleUpdateStatus(item?.user)}
                    >
                      {item?.user?.status === "active" ? "Active" : "InActive"}
                    </Button>
                  )}
                </td>
                <td>
                  <Button
                    variant="warning"
                    className="me-3"
                    onClick={() => handleEdit(item?.user)}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <ReactPaginate
          nextLabel=">"
          pageRangeDisplayed={10}
          marginPagesDisplayed={2}
          previousLabel="<"
          pageClassName="page-item"
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pageCount={totalPages}
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination mx-4 p-2"
          activeClassName="active"
          forcePage={currentPage}
          renderOnZeroPageCount={null}
        />

        <Modal centered show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton centered>
            <Modal.Title>
              {selectedItem ? "Edit Staff" : "Add Staff"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Form.Group>
                    <Form.Label className="fw-bold">User Name:</Form.Label>
                    <Form.Control
                      type="name"
                      placeholder="Enter Username"
                      value={inputUsername}
                      onChange={(e) => setInputUsername(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <Form.Group>
                    <Form.Label className="fw-bold">Email:</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Your Email"
                      value={inputEmail}
                      onChange={(e) => setInputEmail(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row>
              {selectedItem ? (
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-bold">User Type:</Form.Label>
                      <Form.Select
                        value={inputRole}
                        aria-label="select"
                        onChange={(e) => setInputRole(e.target.value)}
                      >
                        <option selected disabled>
                          Select UserType
                        </option>
                        <option value="manager">Manager</option>
                        <option value="cook">Cook</option>
                        <option value="waiter">Waiter</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Col>
              ) : (
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-bold">Password:</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter Password"
                        value={inputPassword}
                        onChange={(e) => setInputPassword(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </Col>
              )}
              <Col lg={6}>
                <div className="mb-3">
                  <Form.Group>
                    <Form.Label className="fw-bold">Phone Number:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter PhoneNumber"
                      value={inputNumber}
                      onChange={(e) => setInputNumber(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row>
              {selectedItem ? (
                ""
              ) : (
                <Col lg={6}>
                  <div className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-bold">User Type:</Form.Label>
                      <Form.Select
                        value={inputRole}
                        aria-label="select"
                        onChange={(e) => setInputRole(e.target.value)}
                      >
                        <option>Select UserType</option>
                        <option value="manager">Manager</option>
                        <option value="cook">Cook</option>
                        <option value="waiter">Waiter</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Col>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="warning" onClick={handleSave}>
              {selectedItem ? "Save Changes" : "Add Staff"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Staff;
