import React from "react";
import { Form, Modal, Button, Col, Row } from "react-bootstrap";

function AddCategoryModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col lg={12} sm={12}>
              <Form.Group controlId="text" style={{ marginBottom: "30px" }}>
                <Form.Label className="fw-bold">Title:</Form.Label>
                <Form.Control
                  type="text"
                  name="text"
                  placeholder="LITTLE PLATES"
                  // style={{ width: '100%', padding: '5px' }}
                />
              </Form.Group>
            </Col>
            {/* <Col lg={6} sm={6}>
              <Form.Group controlId="category" style={{ marginBottom: "30px" }}>
                <Form.Label className="fw-bold">Image</Form.Label>
                <Form.Control
                  type="File"
                  name="image"
                  placeholder=" "
                  // style={{ width: '100%', padding: '5px' }}
                />
              </Form.Group>
            </Col> */}
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={props.onHide}>
          Add{" "}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddCategoryModal;
