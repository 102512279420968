import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const getCustomerOrder = async (id, orderData) => {
  const response = await axios.get(
    `${BASE_URL}/customer/order/${id}?userId=${
      orderData?.userId ? orderData?.userId : ""
    }&orderId=${orderData?.orderId ? orderData?.orderId : ""}`
  );
  if (response.data.body) {
    return response.data.body;
  }
};
const addOrderItem = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/customer/orderItem?id=${data.orderItemId}&type=${
      data.type ? data.type : ""
    }`,
    data
  );
  if (response.data.body) {
    return response.data.body;
  }
};

const clearOrder = async (data) => {
  const response = await axios.delete(
    `${BASE_URL}/customer/order/${data.orderId}`,
    { data: data }
  );
  if (response.data.body) {
    return response.data.body;
  }
};

const addOrder = async (data) => {
  const response = await axios.put(
    `${BASE_URL}/customer/order/${data.orderId}`,
    data
  );
  if (response.data.body) {
    return response.data.body;
  }
};

const orderService = {
  getCustomerOrder,
  addOrderItem,
  clearOrder,
  addOrder,
};

export default orderService;
