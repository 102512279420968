import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";

import MenuItems from "./MenuItems";
import {
  allMenu,
  allMenuByBranchId,
} from "../../../redux/features/admin/Menu/menuSlice";
import { useSelector, useDispatch } from "react-redux";

import { MenuTableData } from "../../../JsonData";
import GeneralIndicator from "../../model/GeneralIndicator";

const Menu = () => {
  const dispatch = useDispatch();
  const { menus, isLoading } = useSelector((state) => state.menu);
  return (
    <Container>
      {isLoading && <GeneralIndicator />}
      <MenuItems MenuTableData={menus} />
    </Container>
  );
};

export default Menu;
